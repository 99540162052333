import React, { Component } from 'react';
import Page from '../containers/page';
import Isvg from 'react-inlinesvg';
import { Link, Redirect } from 'react-router-dom';

import {
    Container,
    Row,
    Col,
    UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap';

import cookieImage from '../assets/images/cookie.jpg';

var striptags = require('striptags');

class CookiesPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData,
        };
    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

    }

    render() {
        console.log(this.props)
        return (
            <div>

                <section className="privacy-policy-page">

                    <Container>
                        <Row>
                            <Col lg="12">
                                <div className="privacy-policy-content">
                                    <h2>PERSONUPPGIFTSPOLICY</h2>
                                    <p>Din integritet är viktig för oss på Venture Design och vi har därför tagit fram denna policy för att du ska få information om hur vi behandlar dina personuppgifter samt hur du kan utöva dina rättigheter beträffande personuppgifter.</p>
                                    <h4>Personuppgiftsansvarig</h4>
                                    <p>Venture Design AB, 556787-4424, Skidsvängen 25, 35249 Växjö är personuppgiftsansvarig för behandling av personuppgifter på denna webbplats. Vill du komma i kontakt med oss så går det bra att använda ovan angivna postadress eller via mail till info@venturedesign.se</p>
                                    <h4>Insamling av personuppgifter och vad de används till</h4>
                                    <p>
                                    Vi använder så kallade cookies på webbplatsen. En cookie är en liten textfil som sparas av din webbläsare och lagras på din dator. Cookien innehåller information om ditt besök och den information som samlas in används för att analysera användningen av webbplatsen i syfte att förbättra användarvänlighet samt service.
                                    <br/><br/>
                                    När du kontaktar oss via mail eller kontaktformulär på hemsidan eller sociala medier samlar vi endast in de uppgifter som behövs för att besvara din fråga eller hjälpa dig i ditt ärende. Det kan vara uppgifter om namn, mail, telefonnummer och adress för att vi skall kunna uppfylla vår del av avtalet med dig som kund. Du lämnar ditt samtycke till att vi behandlar dina personuppgifter när du använder våra tjänster på Venturedesign.se
                                    <br/><br/>
                                    Lagring av personuppgifter Dina personuppgifter behandlas och lagras för att Venture Design ska efterleva gällande lagar, exempelvis gällande bokföring, skatt och produktsäkerhet. Vi sparar dina personuppgifter så länge det krävs för ändamålet till behandlingen eller enligt gällande lagkrav.
                                    <br/><br/>
                                    Vid reklamationer eller andra kundserviceärenden lagras uppgifterna så länge ärendet pågår och de uppgifter som inte måste sparas enligt lag gallras 6 månader efter avslutat ärende.
                                    <br/><br/>
                                    Vi är noggranna med att lagringen sker på ett säkert sätt och utvärdering av hur vi behandlar personuppgifter sker kontinuerligt. Nödvändiga säkerhetsåtgärder vidtas för att minska de risker som identifieras.
                                    <br/><br/>
                                    Överföring av personuppgifter Venture Design kan komma att dela personuppgifter med andra, men enbart när det är tillåtet enligt lag och nödvändigt för fullgörande av vår del i avtalet med dig som kund. Det kan till exempel handla om informationsöverföring till myndigheter, logistikföretag eller återförsäljare.
                                    <br/><br/>
                                    Venture Design agerar som personuppgiftsbiträde åt flertalet av sina återförsäljare och har upprättat personuppgiftsbiträdesavtal sinns emellan.
                                    <br/><br/>
                                    Dina rättigheter Du har rätt att få information om vilka uppgifter vi har registrerade om dig.
                                    <br/><br/>
                                    Du har rätt att få felaktiga eller ofullständiga uppgifter rättade.
                                    <br/><br/>
                                    Du har rätt att få dina personuppgifter raderade om:

                                    <ul>
                                        <li>De inte längre är nödvändiga för det syfte de samlats in eller behandlats</li>
                                        <li>Insamlingen skedde med ditt samtycke och du återkallar samtycket</li>
                                        <li>Om personuppgifterna behandlats olagligt</li>
                                        <li>Om behandlingen grundar sig på en intresseavvägning och det inte finns berättigade skäl som väger tyngre än ditt intresse.</li>
                                    </ul>

                                    <br/><br/>
                                    Rätten att få personuppgifter raderade gäller inte om vi är skyldiga att behålla uppgifterna enligt lag.
                                    <br/><br/>
                                    Du har rätt att bära att användning av dina personuppgifter begränsas till vissa ändamål.
                                    <br/><br/>
                                    Om du vill åberopa dina rättigheter avseende personuppgiftsbehandling så kontaktar du oss på ovan angivna uppgifter.
                                    <br/><br/>
                                    Personuppgiftspolicyns giltighet och ändringar Venture Designs personuppgiftspolicy gäller för tjänster kopplade till vår hemsida och sociala medier. Det kan förkomma länkningar till andra webbplatser och när du väljer att följa dessa länkningar gäller inte längre vår policy.
                                    <br/><br/>
                                    Vi förbehåller oss rätten att göra ändringar i personuppgiftspolicyn utan att aktivt kontakta dig för att informera om dessa ändringar.
                                    <br/><br/>
                                    Denna versionen av vår personuppgiftspolicy gäller från och med maj 2018.
                                    </p>
                                    <h4> Klarna</h4>
                                <p>                                För att kunna erbjuda dig Klarnas betalmetoder kan vi i kassan komma att dela dina personuppgifter i form av kontaktuppgifter och orderinformation med Klarna, för att Klarna ska kunna bedöma om du kan välja betalmetoderna, samt för att anpassa dem för dig.<br/> Dina personuppgifter som delas behandlas enligt <a href="https://cdn.klarna.com/1.0/shared/content/legal/terms/0/sv_se/privacy">Klarnas egen dataskyddsinformation.</a>

                                </p>


                                </div>
                            </Col>
                        </Row>
                    </Container>
                    
                </section>

                <div className="about-footer">
                    </div>

            </div>
        );
    }
}

export default Page(CookiesPage);