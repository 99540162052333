import React, { Component } from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form'
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux'

import Check from './fields/check';
import Text from './fields/text';
import TextNumber from './fields/textNumber';
import Select from './fields/select';
import Textarea from './fields/textarea';
import Isvg from 'react-inlinesvg';
import {
    Container,
    Row,
    Col,
} from 'reactstrap';

const required = value => value ? undefined : "Required"

const renderTextNumberField = ({
    input,
    label,
    placeholder,
    disabled,
    meta: { touched, error },
    type
}) => (

    <TextNumber
        placeholder={placeholder}
        errorText={touched && error}
        label={label}
        disabled={disabled}
        error={touched && error}
        type={type}
        {...input}
    />
)


const renderSelectField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
    children,
    additionalAction,
    scope,
}) => (

    <Select
        placeholder={placeholder}
        label={label}
        errorText={touched && error}
        error={touched && error}
        additionalAction={additionalAction}
        scope={scope}
        {...input}
        children={children}
    />
)

const renderCheckField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },

}) => (

    <Check
        placeholder={placeholder}
        label={label}
        errorText={touched && error}
        error={touched && error}
        // multilang={multilang}
        // lang={lang}

        {...input}
    />
)

const renderTextField = ({
    input,
    label,
    placeholder,
    meta: { touched, error },
    type
}) => (

    <Text
        placeholder={placeholder}
        errorText={touched && error}
        label={label}
        error={touched && error}
        type={type}
        {...input}
    />
)

const renderTextareaField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
}) => (

    <Textarea
        placeholder={placeholder}
        label={label}
        errorText={touched && error}
        error={touched && error}
        {...input}
    />
)


class RegisterForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            seePassword: false
        }
    }


    render() {


        const { handleSubmit, pristine, reset, submitting } = this.props;

        return (
            <form onSubmit={handleSubmit} className="login-form register-form">
                <Row>
                    <Col lg="6">
                    <h3 style={{fontSize: 16, whiteSpace: 'nowrap'}}>{'FÖRETAGSINFORMATION'.translate(this.props.lang)}</h3>

                        <Row>
                        <Col lg="12">
                        <div className="field-wrap">
                            <Field
                                name="companyName"
                                component={renderTextField}
                                label={"Företagsnamn *".translate(this.props.lang)}
                                validate={[required]}
                            ></Field>
                        </div>
                    </Col>
                    <Col lg="12">
                        <div className="field-wrap">
                            <Field
                                name="invoiceAddress"
                                component={renderTextField}
                                label={"Fakturaadress *".translate(this.props.lang)}
                                validate={[required]}
                            ></Field>
                        </div>
                    </Col>
                    <Col lg="12">
                        <div className="field-wrap">
                            <Field
                                name="zipCode"
                                component={renderTextNumberField}
                                label={"Postnummer *".translate(this.props.lang)}
                                validate={[required]}
                            ></Field>
                        </div>
                    </Col>
                    <Col lg="12">
                        <div className="field-wrap">
                            <Field
                                name="place"
                                component={renderTextField}
                                label={"Ort *".translate(this.props.lang)}
                                validate={[required]}
                            ></Field>
                        </div>
                    </Col>
                    <Col lg="12">
                        <div className="field-wrap">
                            <Field
                                name="country"
                                component={renderTextField}
                                label={"Land *".translate(this.props.lang)}
                                validate={[required]}
                            ></Field>
                        </div>
                    </Col>
                    <Col lg="12">
                        <div className="field-wrap">
                            <Field
                                name="organizationNumber"
                                component={renderTextField}
                                label={"Organisationsnummer *".translate(this.props.lang)}
                                validate={[required]}
                            ></Field>
                        </div>
                    </Col>
                    <Col lg="12">
                        <div className="field-wrap">
                            <Field
                                name="vatNumber"
                                component={renderTextField}
                                label={"VAT-number *".translate(this.props.lang)}
                                validate={[required]}
                            ></Field>
                        </div>
                    </Col>
                    <Col lg="12">
                        <div className="field-wrap">
                            <Field
                                name="invoiceEmail"
                                component={renderTextField}
                                label={"E-mail (faktura) *".translate(this.props.lang)}
                                validate={[required]}
                            ></Field>
                        </div>
                    </Col>
                    <Col lg="12">
                        <div className="field-wrap">
                            <Field
                                name="orderConfirmationEmail"
                                component={renderTextField}
                                label={"E-mail (orderbekräftelse/avisering) *".translate(this.props.lang)}
                                validate={[required]}
                            ></Field>
                        </div>
                    </Col>
                    <Col lg="12">
                        <div className="field-wrap">
                            <Field
                                name="authEMail"
                                component={renderTextField}
                                label={"E-mail (inlogg / kontakt / nyhetsbrev) *".translate(this.props.lang)}
                                validate={[required]}
                            ></Field>
                        </div>
                    </Col>

                    <Col lg="12">
                        <div className="field-wrap">
                            <Field
                                name="phoneNotification"
                                component={renderTextField}
                                label={"Telefon (avisering) *".translate(this.props.lang)}
                                validate={[required]}
                            ></Field>
                        </div>
                    </Col>
                    <Col lg="12">
                        <div className="field-wrap">
                            <Field
                                name="contactPerson"
                                component={renderTextField}
                                label={"Kontaktperson *".translate(this.props.lang)}
                                validate={[required]}
                            ></Field>
                        </div>
                    </Col>
                    <Col lg="12">
                        <div className="field-wrap">
                            <Field
                                name="contactPersonPhone"
                                component={renderTextField}
                                label={"Telefon (kontaktperson) *".translate(this.props.lang)}
                                validate={[required]}
                            ></Field>
                        </div>
                    </Col>
                    <Col lg="12">
                        <div className="field-wrap">
                            <Field
                                name="website"
                                component={renderTextField}
                                label={"www adress".translate(this.props.lang)}
                            ></Field>
                        </div>
                    </Col>

                        </Row>
                    </Col>
                    <Col lg="6">
                        <Row>
                        <div className="register-form-check">
                        <h3 style={{fontSize: 16, whiteSpace: 'nowrap'}}>{'LEVERANSADRESS'.translate(this.props.lang)}</h3>
                        <Col lg="10" style={{ marginBottom: 15 }}>
                            <div className="check-wrap">
                                <Field
                                    name="sameAsInvoiceAddress"
                                    component={renderCheckField}
                                    label={"Samma som fakturaadress * ".translate(this.props.lang)}
                                    placeholder=""
                                ></Field>

                            </div>
                        </Col>
                    </div>

                    <Col lg="12">
                    <div className="become-seller-info">
                        <h6>{'OBS! Här finns det tre alternativ. Välj det som passar dig:'.translate(this.props.lang)}</h6>
                        <p><strong>{'1) Varorna skickas till din faktureringsadress.'.translate(this.props.lang)}</strong></p>
                        <p>{'I så fall, klicka på “samma som faktureringsadress”.'.translate(this.props.lang)}</p>
            <br></br>
                        <p><strong>{'2) Varorna skickas till en annan adress.'.translate(this.props.lang)}</strong></p>
                        <p>{'I så fall, fyll i formuläret nedan.'.translate(this.props.lang)}</p>
                        <br></br>


                        <p><strong>{'3) Varorna skickas direkt till din kund (dropshipping).'.translate(this.props.lang)}</strong></p>
                        <p>{'I så fall, klicka på “samma som faktureringsadress”. Du kommer att ha möjlighet att ange din kunds adress i kassan vid beställning.  '.translate(this.props.lang)}</p>
                        <br></br>

                    </div>
                    </Col>

                    { !this.props.sameAsInvoiceAddress ?
                    <>

                    <Col lg="12">
                        <div className="field-wrap">
                            <Field
                                name="deliveryCompanyName"
                                component={renderTextField}
                                label={"Företagsnamn *".translate(this.props.lang)}
                                validate={[required]}
                            ></Field>
                        </div>
                    </Col>

                    <Col lg="12">
                        <div className="field-wrap">
                            <Field
                                name="deliveryAddress"
                                component={renderTextField}
                                label={"Gatuadress *".translate(this.props.lang)}
                                validate={[required]}
                            ></Field>
                        </div>
                    </Col>
                    <Col lg="12">
                        <div className="field-wrap">
                            <Field
                                name="deliveryZipCode"
                                component={renderTextNumberField}
                                label={"Postnummer *".translate(this.props.lang)}
                                validate={[required]}
                            ></Field>
                        </div>
                    </Col>
                    <Col lg="12">
                        <div className="field-wrap">
                            <Field
                                name="deliveryPlace"
                                component={renderTextField}
                                label={"Ort *".translate(this.props.lang)}
                                validate={[required]}
                            ></Field>
                        </div>
                    </Col>
                    <Col lg="12">
                        <div className="field-wrap">
                            <Field
                                name="deliveryCountry"
                                component={renderTextField}
                                label={"Land *".translate(this.props.lang)}
                                validate={[required]}
                            ></Field>
                        </div>
                    </Col>
                    <Col lg="12">
                        <div className="field-wrap">
                            <Field
                                name="deliveryContactPersonPhone"
                                component={renderTextField}
                                label={"Telefon (kontaktperson) *".translate(this.props.lang)}
                                validate={[required]}
                            ></Field>
                        </div>
                    </Col>

                    </>
                    :
                     null
    }

                    <div className="register-form-check">
                        <p>{'Önskar använda eget transportavtal'.translate(this.props.lang)}</p>
                        <div className="register-form-check">

                            <Col lg="10" style={{ marginBottom: 15 }}>
                                <div className="check-wrap">
                                    <Field
                                        name="ownTransportAgreement"
                                        component={renderCheckField}
                                        label={"JA".translate(this.props.lang)}
                                        placeholder=""
                                    ></Field>

                                </div>
                            </Col>
                        </div>
                    </div>

                    <Col lg="12">
                        <p className="register-text">{'Din personliga data kommer endast att användas för att hantera beställningar, kundservice, för att förbättra din upplevelse av webbplatsen och eventuellt annat som står i vår integritetspolicy.'.translate(this.props.lang)}</p>
                    </Col>
                    <Col lg="12" style={{ marginBottom: 15 }}>
                        <div className="check-wrap">
                            <Field
                                name="privacyPolicy"
                                component={renderCheckField}
                                label={<>{"Jag har läst och accepterar ".translate(this.props.lang)}<Link to="/personuppgiftspolicy">{'integritetspolicyn'.translate(this.props.lang)}</Link></>}
                                placeholder=""
                                validate={[required]}
                            ></Field>

                        </div>
                    </Col>
                    <Col lg="12" style={{ marginBottom: 15 }}>
                        <div className="check-wrap">
                            <Field
                                name="newsletter"
                                component={renderCheckField}
                                label={"Registrera mig för nyhetsbrevet! *".translate(this.props.lang)}
                                placeholder=""
                            ></Field>

                        </div>
                    </Col>
                    <Col lg="12" className="btn-wrap">

                        {this.props.loading ?
                            null
                            :
                            <button className="button">{'SKICKA'.translate(this.props.lang)}</button>
                        }
                    </Col>

                        </Row>
                    </Col>

                </Row>


            </form>

        )
    }
}



RegisterForm = reduxForm({
    form: 'registerForm' // a unique identifier for this form
})(RegisterForm)

// Decorate with connect to read form values
const selector = formValueSelector('registerForm') // <-- same as form name
RegisterForm = connect(state => {
    const sameAsInvoiceAddress = selector(state, 'sameAsInvoiceAddress'
    )
    return {
        sameAsInvoiceAddress: sameAsInvoiceAddress
    }
})(RegisterForm)

export default RegisterForm
