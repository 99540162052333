import React, { Component } from 'react';
import Page from '../containers/page';
import Isvg from 'react-inlinesvg';

import {
    Container,
    Row,
    Col,
} from 'reactstrap';

import FFLoginForm from '../components/forms/ffLoginForm';
import FFRegisterForm from '../components/forms/ffRegisterForm';
import { API_ENDPOINT } from '../constants';
var striptags = require('striptags');
import image from '../assets/images/account-cover.jpg';
import imageMobile from '../assets/images/account-cover.jpg';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../helpers/linkHelper';

class AuthPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData
        };
    }


    login = (data) => {
        console.log(data);
        this.setState({
            loginLoading: true
        }, () => {
            fetch(API_ENDPOINT + '/ff-login', {
                method: 'POST',
                headers: {
'X-Brand': 'Vind',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then((res) => res.json()).then((result) => {
    
                if (!result.error) {
                    this.setState({
                        code: result.code
                    })
                } else {
                    this.setState({
                        loginError: result.error
                    })
                }
            })
    
        })

    }

    register = (data) => {
        let params = getSearchParams(this.props[0].location.search, { page: 0 });

        data.code = params.code;

        this.setState({
            registerLoading: true
        }, () => {
            console.log(data);

            fetch(API_ENDPOINT + '/ff-register', {
                method: 'POST',
                headers: {
'X-Brand': 'Vind',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then((res) => res.json()).then((result) => {
    
                if (!result.error) {
                    this.setState({
                        registerError: 'Tack för att du registrerade dig på Friends & Family. Vi har skickat ditt användarnamn och lösenord till din e-post.'
                    })
                    //this.props[0].history.push('/');
                } else {
                    this.setState({
                        registerError: result.error
                    })
                }
            })
    
        })
    }



    render() {
        let params = getSearchParams(this.props[0].location.search, { page: 0 });

        return (
            <div className="friends-and-family-page">
                                                <section className="test">
                    <img src={typeof window !== 'undefined' && window.innerWidth < 768 ? imageMobile : image} />
                    <div className="test-content">
                        <h1>{'FRIENDS & FAMILY'.translate(this.props.lang)}</h1>
                    </div>
                </section>

                <Container fluid>
                    <Row>
                        <Col lg={{ size: 6, offset: 3 }}>
                            {params.code ?

                                <div className="account-container">
                                    <h3>{'Hej och välkommen till Friends & Family - en exklusiv webshop endast för Venturefamiljen.'.translate(this.props.lang)}</h3>
                                    <h6>{'Gör så här:'.translate(this.props.lang)}</h6>
                                    <ul>
                                        <li>{'1) Fyll i och skicka formuläret (nedan).'.translate(this.props.lang)}</li>
                                        <li>{'2) Kontrollera din e-post. Där får du ditt kundnummer och login.'.translate(this.props.lang)}</li>
                                        <li>{'3) Logga in på vår hemsida ...'.translate(this.props.lang)}</li>

                                    </ul>
                                    <h6>{'och shopping till kompispriser kan börja!'.translate(this.props.lang)}</h6>

                                    <FFRegisterForm initialValues={{country: 'SE', sameAsDeliveryAddress: true}} loading={this.state.registerLoading} lang={this.props.lang} onSubmit={this.register} />
                                    {this.state.registerError ? <p>{this.state.registerError.translate(this.props.lang)}</p> : null}
                                </div>
                                :
                                <div className="account-container">
                                    <h3>{'Hej och välkommen till Friends & Family - en exklusiv webshop endast för Venturefamiljen.'.translate(this.props.lang)}</h3>

                                    <FFLoginForm loading={this.state.loginLoading} lang={this.props.lang} onSubmit={this.login} />
                                    {this.state.loginError ? <p>{this.state.loginError.translate(this.props.lang)}</p> : null}
                                </div>

                            }
                        </Col>
                        {/* <Col lg="6">
                            <div className="account-container register-form-container">
                                <h3>{'REGISTRERA'.translate(this.props.lang)}</h3>
                                <RegisterForm onSubmit={this.register} />
                                {this.state.registerError ? <p>{this.state.registerError}</p> : null}

                            </div>
                        </Col> */}
                    </Row>
                </Container>
            </div>
        );
    }
}

export default Page(AuthPage);