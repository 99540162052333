
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Isvg from 'react-inlinesvg';
import { Calendar } from 'react-date-range';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import * as locales from 'react-date-range/dist/locale';

import moment from 'moment';
/**
* Custom datepicker
* @author   Stefan Pantic
*/
class DatePicker extends Component {
    constructor(props) {
        super(props);
        this.wrapperRef = React.createRef();

        this.state = {
        };
    }

    componentDidMount() {
        let date = new Date();
        this.setState({
            date: new Date()
        })

        this.setState({
            year: date.getFullYear()
        })


        if (this.props.value) {
            this.setState({
                value: this.props.value,

            })
        }

        document.addEventListener('mousedown', this.handleClickOutside);
    }

    // componentDidUpdate(prevProps) {
    //     if (prevProps.value != this.state.value && !this.state.value) {
    //         if (this.props.value) {
    //             this.setState({
    //                 value: this.props.value
    //             })
    //         }
    //     }

    // }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }


    handleClickOutside = (event) => {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            this.setState({
                showCalendar: null
            })
        }
    }




    render() {
        // console.log(this.props.value)
        return (
            <div className={this.props.error ? 'date-picker-field required' : 'date-picker-field'} ref={this.wrapperRef} style={{ marginBottom: this.props.marginBottom ? this.props.marginBottom : 0 }}>
                {
                    this.props.label ?
                        <label >{this.props.label}</label>
                        :
                        null
                }
                <input
                    value={this.props.value}
                    disabled={this.props.disabled}
                    // onChange={(e) => {
                    //     if (e.target.value && e.target.value.split('/').length - 1 >= 2) {
                    //         let splitted = e.target.value.split('/');
                    //         if (splitted[2].length == 4) {

                    //             let date = new Date(splitted[2], splitted[1] - 1, splitted[0], 0, 0, 0, 0);
                    //             let date =
                    //                 this.props.onChange(date);
                    //         }
                    //     }

                    //     this.setState({
                    //         value: e.target.value
                    //     })
                    // }}
                    onClick={this.props.onClick}
                    style={{ height: this.props.heightInput ? this.props.heightInput : this.props.listOfMedicinesHide ? 0 : null, visibility: this.props.listOfMedicinesHide ? 'hidden' : 'visible' }}
                    type="text"
                    onFocus={() => {
                        if (!this.state.showCalendar) {
                            this.setState({
                                showCalendar: true
                            })
                        }
                    }}

                />

                {this.state.showCalendar || this.props.openCalendar ?
                    <Calendar
                        minDate={new Date()}
                        disabledDay={(date) => {
                            let day = date.getDay()
                            let month = date.getMonth()
                            let explicitDate = date.getDate()

                            if (day === 6 || day === 0 || ( month === 0 && explicitDate === 1) || ( month === 11 && explicitDate === 25)) {
                                return date
                            }
                        }}
                        date={this.props.value ? new Date(this.props.value.replace(/-/g, "/")) : this.state.date}
                        onChange={(date) => {

                            this.setState({
                                showCalendar: null,
                                // value:moment(new Date(date)).format('YYYY-MM-DD')
                            })
                            this.props.onChange(moment(new Date(date)).format('YYYY-MM-DD'))
                            if (this.props.openCalendar)
                                this.props.closeCalendar()
                        }}
                    />
                    :
                    null
                }
            </div>

        );
    }
}

export default DatePicker;