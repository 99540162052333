import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import moment from 'moment';
import Isvg from 'react-inlinesvg';

import {
    Container,
    Row,
    Col,

} from 'reactstrap';
import { API_ENDPOINT } from '../constants';

class Menu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            parent: null
        };
    }


    componentDidMount() {
        if (this.props.parent && this.props.parent._id) {
            this.setState({
                parent: this.props.parent
            })
        }
    }


    render() {


        let isMobile = typeof window != 'undefined' && window.innerWidth < 768;



        return (
            <div className={`menu-v2 ${this.state.parent ? 'menu-sub-v2' : ''}`}>
                <Container fluid>
                    <button className='close' onClick={() => this.props.handleMenu(false)}>&times;</button>
                    <Row>
                        <Col lg="12">
                            <button className='prev' onClick={() => {
                                if (this.state.isSubItem) {
                                    this.setState({
                                        isSubItem: null,
                                        parent: this.state.oldParent,
                                        oldParent: null
                                    })
                                } else {
                                    this.setState({
                                        isSubItem: null,
                                        parent: null,
                                        oldParent: null
                                    })
                                }
                            }}></button>

                            <h2 onClick={() => {
                                if (this.state.isSubItem) {
                                    this.setState({
                                        isSubItem: null,
                                        parent: this.state.oldParent,
                                        oldParent: null
                                    })
                                } else {
                                    if (this.state.parent) {
                                        this.setState({
                                            isSubItem: null,
                                            parent: null,
                                            oldParent: null
                                        })
                                    } else {
                                        this.props.handleMenu(false);
                                    }
                                }

                            }}>{'MENY'.translate(this.props.lang)}</h2>
                        </Col>
                    </Row>
                    {!this.state.parent ?

                        <Row>
                            <Col lg="3">
                                <h3>{'PRODUKTER'.translate(this.props.lang)}</h3>
                                <ul>
                                    {
                                        this.props.rootCategories ?
                                        this.props.rootCategories.filter((item) => !item.brandCategory).map((item, idx) => {
                                            
                                                    return (
                                                        <li key={idx}>
                                                            <Link to={Object.translate(item, 'breadcrumb', this.props.lang)} onClick={() => this.props.handleMenu(false)}> {/*<div className='icon'><Isvg src={API_ENDPOINT + item.icon} /></div> */}{Object.translate(item, 'name', this.props.lang)}</Link>
                                                        </li>
                                                    )

                                            })
                                            :
                                            null
                                    }
                                </ul>
                            </Col>
                            <Col lg="3">
                                <h3></h3>
                                <ul className='links'>
                                    {
                                        isMobile ?
                                            <>
                                                <li><Link to={'/faq'} onClick={() => this.props.handleMenu(false)}>{'FAQ'.translate(this.props.lang)}</Link></li>
                                                <li><Link to={'/kontakt'} onClick={() => this.props.handleMenu(false)}>{'KONTAKT'.translate(this.props.lang)}</Link></li>
                                                <li><Link to={'/account'} onClick={() => this.props.handleMenu(false)}>{this.props.uData ? this.props.uData.username : 'Logga in'.translate(this.props.lang)}</Link></li>

                                            </>
                                            :
                                            null
                                    }
                                </ul>
                            </Col>

                        </Row>
                        :
                        <Row>
                            <Col lg="12">
                                <h3>{Object.translate(this.state.parent, 'name', this.props.lang)}</h3>
                                <Link to={Object.translate(this.state.parent, 'breadcrumb', this.props.lang)} onClick={() => this.props.handleMenu(false)}> <h6>{'ALLA'.translate(this.props.lang)} {Object.translate(this.state.parent, 'name', this.props.lang)}</h6> </Link>
                            </Col>



                        </Row>


                    }
                </Container>

            </div>
        );
    }
}


export default Menu;
