import React, { Component } from 'react';
import Page from '../containers/page';
import Isvg from 'react-inlinesvg';

import {
    Container,
    Row,
    Col,
} from 'reactstrap';

import ForgotPasswordForm from '../components/forms/forgotPasswordForm';
import { API_ENDPOINT } from '../constants';
import image from '../assets/images/account-cover.jpg';
import imageMobile from '../assets/images/account-cover.jpg';
import AccountNav from '../components/accountNav';

var striptags = require('striptags');

class ForgotPasswordPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData
        };
    }


    forgotPassword = (data) => {
        console.log(data);

        fetch(API_ENDPOINT + '/forgot-password', {
            method: 'POST',
            headers: {
'X-Brand': 'Vind',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then((res) => res.json()).then((result) => {
            this.setState({
                done: true
            })
        })
    }




    render() {

        return (
            <div className="account-page">
                                                <section className="test">
                    <img src={typeof window !== 'undefined' && window.innerWidth < 768 ? imageMobile : image} />
                    <div className="test-content">
                        <h1>{'LOGGA IN'.translate(this.props.lang)}</h1>
                    </div>
                </section>


                <Container >
                    <Row>
                    <Col lg="4">
                            <AccountNav {...this.props} />
                        </Col>

                        <Col lg="8">
                            <div className="account-container">
                                {!this.state.done ?
                                    <>
                                        <h3>{'ÅTERSTÄLL LÖSENORD'.translate(this.props.lang)}</h3>
                                        <h6>{'Om du har tappat bort ditt lösenord skickar vi ett nytt till dig. Skriv in den e-postadress du använde när du registrerade ditt konto hos oss.'.translate(this.props.lang)}</h6>
                                        <ForgotPasswordForm lang={this.props.lang}  onSubmit={this.forgotPassword} />
                                        {this.state.loginError ? <p className="form-error">{this.state.loginError}</p> : null}
                                    </>
                                    :
                                    <p>{'Ett e-postmeddelande för återställning av lösenord har skickats till den e-postadress vi har registrerad för ditt konto. Det kan dock ta några minuter innan det visas i din inkorg. Vänta i minst 10 minuter innan du försöker återställa igen.'.translate(this.props.lang)}</p>
                                }
                            </div>
                        </Col>
                        {/* <Col lg="6">
                            <div className="account-container register-form-container">
                                <h3>{'REGISTRERA'.translate(this.props.lang)}</h3>
                                <RegisterForm onSubmit={this.register} />
                                {this.state.registerError ? <p>{this.state.registerError}</p> : null}

                            </div>
                        </Col> */}
                    </Row>
                </Container>
            </div>
        );
    }
}

export default Page(ForgotPasswordPage);