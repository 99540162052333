
import React, { Component, createRef } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { API_ENDPOINT } from '../constants';
import Isvg from 'react-inlinesvg';

import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
} from 'reactstrap';

import searchDark from '../assets/svg/search-dark.svg';
import logo from '../assets/svg/logo.svg';
import cartIcon from '../assets/svg/cart.svg';
import wishlistIcon from '../assets/svg/whishlist-icon.svg';
import accountIcon from '../assets/svg/account-icon.svg';
import hamburgerIcon from '../assets/svg/hamburger-dark.svg';

import test from '../assets/images/sm1.jpg';
import { timing } from 'react-ga';
import noImage from '../assets/images/no-image.png';
import { parseJSON } from 'date-fns';
import Menu from './menu';
import Cart from './cart';
import Wishlist from './wishlist';

const langsDict = {
    'en': 'English',
    'sv': 'Swedish'
}

const parseJSON1 = (response) => {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}

export class Nav extends Component {
    constructor(props) {
        super(props);
        this.handleClickOutside = this.handleClickOutside.bind(this);

        this.state = {
            desktopMenu: false,
            langDrop: false,
            subCat: false,
            searchBox: false,
            searchBoxDark: false,
            searchResult: [],
        };
        this.textInput = createRef();


    }

    handleMenu = (value) => {
        this.setState({
            menu: value
        })
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);

    }
    componentDidUpdate(prevProps) {
        this.textInput.current.focus();


        if (prevProps[0].location.pathname != this.props[0].location.pathname && this.state.desktopMenu) {
            this.setState({
                desktopMenu: false
            })
        }

        if (prevProps.menuCategory != this.props.menuCategory && this.props.menuCategory) {
            window.scrollTo(0, 0)
            this.setState({
                menu: this.props.menuCategory
            }, () => {
                this.props.handleMenuCategory(null)
            })
        }
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({
                langDrop: false
            })
        }

        if (this.wrapperRef2 && !this.wrapperRef2.contains(event.target)) {
            this.setState({
                desktopMenu: false
            })
        }

        if (this.wrapperRef3 && !this.wrapperRef3.contains(event.target)) {
            this.setState({
                desktopMenu: false
            })
        }

        if (this.wrapperRef4 && !this.wrapperRef4.contains(event.target)) {
            this.setState({
                subCat: false
            })
        }
    }

    searchFunction = () => {
        let lang = this.props.lang;
        let search = this.state.search

        fetch(API_ENDPOINT + "/products/search", {
            method: "POST",
            headers: {
'X-Brand': 'Vind',
                "content-type": "application/json",
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            },
            body: JSON.stringify({ lang, search }),
        }).then(parseJSON1).then(({ result, status }) => {
            this.setState({
                searchResult: result
            })
        }).catch(() => null);
    }

    render() {

        return (


            <div>
                <nav className={'nav-static'}>

                    <div className="nav-body nav-body-dark">
                        <Container fluid>

                        <div className="menu-icon">
                                        <Isvg src={hamburgerIcon} onClick={() => {
                                            this.setState({
                                                menu: true
                                            })
                                        }} />
                                    </div>


                            <div className="langs">
                                <button className={this.props.lang == 'sv' ? 'active' : ''} onClick={() => this.props.setLang('sv')}>SWE</button>
                                /
                                <button className={this.props.lang == 'en' ? 'active' : ''} onClick={() => this.props.setLang('en')}>ENG</button>

                            </div>

                            <div className="logo">
                                <Link to="/">
                                    <Isvg src={logo} />
                                </Link>
                            </div>
                            <div className="search" style={{ position: 'relative' }}>
                                    <div className='cart' style={{cursor: 'pointer'}} onClick={() => this.setState({showCart: true})}>
                                        <Isvg src={cartIcon} className="dark-icon" />  <div className="counter second-color">{this.props.cartInfo && this.props.cartInfo.items ? this.props.cartInfo.items.reduce((acc, item) => {
  return acc + item.quantity;
}, 0)
 : 0}</div> 
                                       
                                    </div>


                                    <div className='cart' style={{cursor: 'pointer'}} onClick={() => this.setState({showWishlist: true})}>
                                       <Isvg src={wishlistIcon} className="dark-icon" /> <div className="counter">{this.props.wishlist.length}</div> 
                                        
                                    </div>

                                <div className='cart hide-on-mobile'>
                                    <Link className='cart' to="/account"><Isvg src={accountIcon} className="dark-icon" /></Link>
                                </div>


                                <div className=''>
                                <Isvg src={searchDark} onClick={() => {
                                    this.setState({
                                        searchBoxDark: !this.state.searchBoxDark
                                    });
                                }} />

                                <div className={this.state.searchBoxDark ? `search-field opened` : 'search-field'}>
                                    <input id="search-input" type="text" ref={this.textInput} className={this.state.searchBoxDark ? 'search-box' : 'search-box'} placeholder="Sök efter produkter…" value={this.state.search} onChange={(e) => { this.setState({ search: e.target.value }, this.searchFunction) }} onKeyUp={(e) => { if (e.key == 'Enter') { this.setState({ search: '' }); this.props[0].history.push(`/search/${this.state.search}`) } }} />
                                    <div className="search-articles">
                                        <Container>
                                            {this.state.search === "" ? null :
                                                this.state.searchResult && this.state.searchResult.map((data) => {
                                                    return (
                                                        <Link to={`/products/${Object.translate(data, 'alias', this.props.lang)}`}>
                                                            <Row className="search-article-row">
                                                                <Col md="2" xs="4" className="search-article-image">
                                                                    <img src={API_ENDPOINT.replace('testapi', 'api') + data.images[0].file} />
                                                                </Col>
                                                                <Col xs="8" md="10" className="search-article-name">
                                                                    {data.productFamily} {Object.translate(data, 'name', this.props.lang)} ({data.sku})
                                                                </Col>
                                                            </Row>
                                                        </Link>
                                                    )
                                                })
                                            }
                                        </Container>

                                    </div>
                                </div>
</div>
                            </div>
                        </Container>
                    </div>

                    <div className={'nav-list'}>
                        <ul className="menu" ref={(node) => { this.wrapperRef4 = node; }}>
                        <li>
                                                    <Link to={'/nyheter'}>{'NYHETER'.translate(this.props.lang)}</Link>
                                                </li>
                            {
                                this.props.rootCategories.filter((item) => !item.brandCategory).map((item, idx) => {
                                    if (Object.translate(item, 'breadcrumb', this.props.lang) != '/josefin-lustig')
                                        if (idx < 2) {
                                            return (
                                                <li key={idx}>
                                                    <Link to={Object.translate(item, 'breadcrumb', this.props.lang)}>{Object.translate(item, 'name', this.props.lang)}</Link>
                                                </li>

                                            )
                                        } else {
                                            return (
                                                <li>
                                                    <Link to={Object.translate(item, 'breadcrumb', this.props.lang)}>{Object.translate(item, 'name', this.props.lang)}</Link>
                                                </li>

                                            )

                                        }
                                })
                            }

                        </ul>
                    </div>


                </nav>
                    { this.state.showCart ? 
                <Cart {...this.props} close={() => this.setState({showCart: false})}></Cart>
                :
                null}

{ this.state.showWishlist ? 
                <Wishlist {...this.props} close={() => this.setState({showWishlist: false})}></Wishlist>
                :
                null}
                    {this.state.menu ?
                        <Menu {...this.props} handleMenu={this.handleMenu} parent={this.state.menu}></Menu>
                        :
                        null
                    }

            </div>
        )
    }
}

export default Nav;