import React, { Component } from 'react';
import Page from '../containers/page';
import Isvg from 'react-inlinesvg';
import { Link, Redirect } from 'react-router-dom';

import {
    Container,
    Row,
    Col,
    UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap';

import user from '../assets/images/user.png';
import AccountNav from '../components/accountNav';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../helpers/linkHelper';
import moment from 'moment';
import { API_ENDPOINT } from '../constants';

var striptags = require('striptags');

class AllOrderPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData,
            items: [],
            customerInvoiceList: []
        };
    }

    get = () => {

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data
                })
            })
        }

        let params = getSearchParams(this.props[0].location.search, {});


        if (this.props.loadDataWithQuery) {
            for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
                this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, params, this.props[0].location.pathname).then((data) => {

                    this.setState({
                        ...data,
                        loading: null
                    }, () => {
                        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                    })

                })
            }


        }


        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

    }

    download = (id) => {
        fetch(API_ENDPOINT + `/account/invoices/${id}`, {
            method: 'GET',
            headers: {
'X-Brand': 'Vind',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`

            },

        }).then(response => response.blob())
            .then(blob => {
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = url;
                a.download = `invoice_${id}.pdf`;
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                a.remove();  //afterwards we remove the element again         
            });

    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.get();
    }
    render() {
        return (
            <div className="profile-page">

                <Container fluid>
                    <Row>
                        <Col lg="3">
                            <AccountNav {...this.props} />
                        </Col>
                        <Col lg="9">
                            <div>
                                <table className="order-table factures-table">
                                    <thead>
                                        <tr>
                                            <th>
                                                <span>{'TYP'.translate(this.props.lang)}</span>
                                            </th>
                                            <th>
                                                <span>{'ORDERNUMMER'.translate(this.props.lang)}</span>
                                            </th>
                                            <th>
                                                <span>{'FAKTURA'.translate(this.props.lang)}</span>
                                            </th>
                                            <th>
                                                <span>{'ORDERDATUM'.translate(this.props.lang)}</span>
                                            </th>
                                            <th>
                                                <span>{'FÖRFALLODATUM'.translate(this.props.lang)}</span>
                                            </th>
                                            <th>
                                                <span>{'OCR'.translate(this.props.lang)}</span>
                                            </th>
                                            <th>
                                                <span>{'STATUS'.translate(this.props.lang)}</span>
                                            </th>
                                            <th>
                                                <span>{'ÅTGÄRDER'.translate(this.props.lang)}</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {

                                            this.state.customerInvoiceList.map((item, idx) => {
                                                return (
                                                    <tr>
                                                        <td>
                                                            {item.invoiceType}
                                                        </td>
                                                        <td>
                                                            <a>{item.orderNumber}</a>
                                                        </td>
                                                        <td>
                                                            <a>{item.invoiceNumber}</a>
                                                        </td>
                                                        <td>
                                                            {moment.unix(new Date(item.orderDate).getTime() / 1000).format('YYYY-MM-DD')}
                                                        </td>
                                                        <td>
                                                            {moment.unix(new Date(item.originalDueDate).getTime() / 1000).format('YYYY-MM-DD')}
                                                        </td>
                                                        <td>
                                                            {item.ocrNumber}
                                                        </td>
                                                        <td>
                                                            {item.invoiceStatus}
                                                        </td>
                                                        <td>
                                                            {item.invoiceType == 'OrderDebit' ?
                                                                <a onClick={() => this.download(item.invoiceNumber)}>{'VISA FAKTURA'.translate(this.props.lang)}</a>
                                                                :

                                                                null
                                                            }
                                                        </td>
                                                    </tr>

                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </Col>
                    </Row>
                </Container>

            </div>
        );
    }
}

export default Page(AllOrderPage);