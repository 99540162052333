import React, { Component } from 'react';
import Page from '../containers/page';
import Isvg from 'react-inlinesvg';

import {
    Container,
    Row,
    Col,
} from 'reactstrap';

import { API_ENDPOINT } from '../constants';
import ResetPasswordForm from '../components/forms/resetPasswordForm';

var striptags = require('striptags');

class UnsubscribePage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData
        };
    }

    componentDidMount(){
        this.unsubscribe();
    }

    unsubscribe = (data) => {
        console.log(data);

        fetch(API_ENDPOINT + '/unsubscribe', {
            method: 'POST',
            headers: {
'X-Brand': 'Vind',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                uid: this.props[0].match.params.uid,
            })
        }).then((res) => res.json()).then((result) => {
            this.setState({
                msg: 'You have successfully unsubscribed'
            })
        })
    }




    render() {

        return (
            <div className="account-page">
                <Container fluid>
                    <Row>
                        <Col lg={{offset: 3, size: 6}} style={{marginTop: 50}}>
                            <div className="account-container">
                                {
                                    this.state.msg ? 
                                    <p style={{textAlign: 'center'}}>{this.state.msg.translate(this.props.lang)}</p>
                                    :
                                    null
                                }
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default Page(UnsubscribePage);