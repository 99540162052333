import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form'
// import { Link, Redirect } from 'react-router-dom';

import Check from './fields/check';
import Text from './fields/text';
import Textarea from './fields/textarea';
import Isvg from 'react-inlinesvg';

import {
    Container,
    Row,
    Col,
} from 'reactstrap';

const required = value => value ? undefined : "Required"

const renderCheckField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },

}) => (

    <Check
        placeholder={placeholder}
        label={label}
        errorText={touched && error}
        error={touched && error}
        // multilang={multilang}
        // lang={lang}

        {...input}
    />
)

const renderTextField = ({
    input,
    label,
    placeholder,
    meta: { touched, error },
    type
}) => (

    <Text
        placeholder={placeholder}
        errorText={touched && error}
        label={label}
        error={touched && error}
        type={type}
        {...input}
    />
)

const renderTextareaField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
}) => (

    <Textarea
        placeholder={placeholder}
        label={label}
        errorText={touched && error}
        error={touched && error}
        {...input}
    />
)


class form extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            seePassword: false
        }
    }



    render() {

        const { handleSubmit, pristine, reset, submitting } = this.props;

        return (
            <form onSubmit={handleSubmit} className="login-form">
                <Row>

                    <Col lg="12">
                        <div className="field-wrap">
                            <Field
                                name="username"
                                component={renderTextField}
                                label={"Användarnamn *".translate(this.props.lang)}
                                validate={[required]}
                            ></Field>
                        </div>
                    </Col>
                    <Col lg="12">

                        <div className="field-wrap">
                            <div className="password-input">
                                <Field
                                    name="password"
                                    component={renderTextField}
                                    label={"Lösenord *".translate(this.props.lang)}
                                    validate={[required]}
                                   type={"password"}
                                ></Field>
                            </div>
                        </div>
                    </Col>

                    <Col lg="12" className="btn-wrap">

                        {this.props.loading ?
                            null
                            :
                            <button className="button">{'LOGGA IN'.translate(this.props.lang)}</button>
                        }
                    </Col>


                </Row>


            </form>

        )
    }
}

export default reduxForm({
    form: 'loginForm'  
})(form)