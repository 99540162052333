import React, { Component } from 'react';
import Page from '../containers/page';
import Isvg from 'react-inlinesvg';

import {
    Container,
    Row,
    Col,
} from 'reactstrap';

import ContactForm from '../components/forms/contactForm';
import t1 from '../assets/images/t1.jpg';

var striptags = require('striptags');
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../helpers/linkHelper';
import { API_ENDPOINT } from '../constants';

class ContactPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData,
            items: []
        };
    }

    get = () => {

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data
                })
            })
        }

        let params = getSearchParams(this.props[0].location.search, {});


        if (this.props.loadDataWithQuery) {
            for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
                this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, params, this.props[0].location.pathname).then((data) => {

                    this.setState({
                        ...data,
                        loading: null
                    }, () => {
                        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                    })

                })
            }


        }


        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.get();
    }

    contact = (data) => {
        this.setState({
            loading: true
        })
        console.log(data);

        fetch(API_ENDPOINT + '/contact', {
            method: 'POST',
            headers: {
'X-Brand': 'Vind',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then((res) => res.json()).then((result) => {

            this.setState({
                msg: 'You have successfully contacted Venture design, expect a response soon'
            })
        })
    }



    render() {

        return (
            <div className="contact-page">
                <h1>{'KONTAKT'.translate(this.props.lang)}</h1>

                <div className="main-contact">
                    <Container>
                        <Row>
                            <Col lg="4">
                                <h3>{'VÄXEL & TELEFONTIDER'.translate(this.props.lang)}</h3>
                                <p>{'+46 470 55 40 00'.translate(this.props.lang)}</p>
                                <p>{'Mån-torsdag 09:30-15:00'.translate(this.props.lang)}</p>
                                <p>{'Fredagar 10:00-14:00'.translate(this.props.lang)}</p>
                                <p>{'Lunchstängt 12:00-13:00'.translate(this.props.lang)}</p>
                            </Col>
                            <Col lg="4">
                                <h3>{'SUPPORT'.translate(this.props.lang)}</h3>
                                <p>{'FAKTURAFRÅGOR: ekonomi@venturedesign.se'.translate(this.props.lang)}</p>
                                <p>{'REKLAMATION: claim@venturedesign.se'.translate(this.props.lang)}</p>
                                <p>{'ORDER: order@venturedesign.se'.translate(this.props.lang)}</p>
                                <p>{'ÖVRIG INFO: info@venturedesign.se'.translate(this.props.lang)}</p>

                            </Col>
                            <Col lg="4">
                                <h3>{'Huvudkontor'.translate(this.props.lang)}</h3>
                                <p>Venture Design</p>
                                <p>Storgatan 68</p>
                                <p>35246 Växjö</p>
                                <p>Sweden</p>
                            </Col>

                        </Row>
                    </Container>
                </div>

            </div>
        );
    }
}

export default Page(ContactPage);