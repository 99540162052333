import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import moment from 'moment';
import Isvg from 'react-inlinesvg';

import noImage from '../assets/images/no-image.png';
import wishlistIcon from '../assets/svg/wishlist.svg';
import wishlistSolidIcon from '../assets/svg/wishlist-solid.svg';

import dimensionsIcon from '../assets/svg/dimensions.svg';

import { API_ENDPOINT } from '../constants';
const colorsDict = {
    'green': '#556A65',
    'black': '#373134',
    'white': '#FFFFFF',
    'light grey': '#D9D7D9',
    'grey': '#A9A2A8',
    'dark grey': '#77727A',
    'whitewash': '#D4BAA5',
    'brown': '#916848',
    'dark brown': '#503D36',
    'nature': '#E6C8A5',
    'walnut': '#654841',
    'mocca': '#825B40',
    'greige': '#CBC0B7',
    'beige': '#E0D6CF',
    'offwhite': '#E6D7CE'
}

class Article extends Component {
    constructor(props) {
        super(props);
        this.registerSocketIOEvents = this.registerSocketIOEvents.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);

        this.state = {
        };

        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

    }


    componentDidMount() {
        if (this.props.socketIOClient) {
            this.registerSocketIOEvents();
        }
        document.addEventListener('mousedown', this.handleClickOutside);
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentDidUpdate(prevProps) {
        if (this.props.socketIOClient) {
            this.registerSocketIOEvents();
        }


    }

    registerSocketIOEvents() {
        if (this.state._registeredEvents)
            return;

        this.setState({
            _registeredEvents: true
        });
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
        window.removeEventListener('resize', this.updateWindowDimensions);

        if (!this.props.socketIOClient) return;
    }

    handleClickOutside(event) {
        /*
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({
            })
        }
        */
    }

    render() {

        //Price after discount = Original Price - (Original price × Discount %)
        //  (Original price × Discount %) =  Original Price - Price after discount
        //  Discount % =  (Original Price - Price after discount) / Original Price

        return (
            <div>
                <article className="article" ref={(node) => this.node = node}>
                    <Link to={`/products/${Object.translate(this.props, 'alias', this.props.lang)}`}>
                    {
                        this.props.tsCreated >= new Date().getTime() / 1000 - 90*24*60*60 ?
                        <div className='new-article' >{'NYHET!'.translate(this.props.lang)}</div>
                        :
                        null
                    }

                    
                    { this.props.pathname !== '/account/wishlist' ?
                        <button className='wishlist-icon' onClick={(e) => {
                            e.preventDefault();
                            this.props.addToWishlist(this.props._id);
                        }} style={this.node ? {top: 10} : {}}>{this.props.wishlistDict[this.props._id] ?  <Isvg src={wishlistSolidIcon} /> : <Isvg src={wishlistIcon} /> }<div className="wishlist-tooltip">{'Add to my favorites'.translate(this.props.lang)}</div></button>
                        :
                        <button className='wishlist-remove' onClick={(e) => {
                            e.preventDefault();
                            this.props.addToWishlist(this.props._id, true);
                        }} style={this.node ? {top: 10} : {}}>&times;</button>

                    }




                        {
                            this.state.preview ?
                                <div className="article-header">
                                        <img key={this.props._id} style={this.node ? {height: this.node.offsetWidth / (3/2)} : {}} src={this.props.thumbnailExposed ?
                                            API_ENDPOINT.replace('testapi', 'api') + this.props.thumbnailExposed.thumbnail : this.props.thumbnailMood ?
                                                API_ENDPOINT.replace('testapi', 'api') + this.props.thumbnailMood.thumbnail : noImage} />
                                        <img key={this.props._id}  style={this.node ? {height: this.node.offsetWidth / (3/2)} : {}} src={this.state.preview} />

                                </div>

                                :
                                this.props.thumbnailMood || this.props.thumbnailExposed ?
                                    <div className="article-header">
                                        <img key={this.props._id}  style={this.node ? {height: this.node.offsetWidth / (3/2)} : {}} src={this.props.thumbnailExposed ?
                                            API_ENDPOINT.replace('testapi', 'api') + this.props.thumbnailExposed.thumbnail : this.props.thumbnailMood ?
                                                API_ENDPOINT.replace('testapi', 'api') + this.props.thumbnailMood.thumbnail : noImage} />
                                        <img key={this.props._id}  style={this.node ? {height: this.node.offsetWidth / (3/2)} : {}} src={this.props.thumbnailMood ?
                                            API_ENDPOINT.replace('testapi', 'api') + this.props.thumbnailMood.thumbnail : this.props.thumbnailExposed ?
                                                API_ENDPOINT.replace('testapi', 'api') + this.props.thumbnailExposed.thumbnail : noImage} />

                                    </div>

                                    :

                                    <div className="article-header">
                                        <img style={this.node ? {height: this.node.offsetWidth / (3/2)} : {}} src={noImage} />
                                        <img style={this.node ? {height: this.node.offsetWidth / (3/2)} : {}} src={noImage} />

                                    </div>
                        }
                    </Link>

                    <div className="article-body">
                        <Link to={`/products/${Object.translate(this.props, 'alias', this.props.lang)}`}>

                           <div className="horizontal">
                             <h4>{this.props.productFamily}  <span>{Object.translate(this.props, 'name', this.props.lang)}</span></h4>
                             <div className="type">

                             {
                                this.props.variations && this.props.variations.length ?
                                    this.props.variations.map((item, idx) => {
                                        if (item.vind)
                                            item = {...item, ...item.vind}
                                        if (idx < 5)

                                            return (
                                                <Link to={`/products/${Object.translate(item, 'alias', this.props.lang)}`}>

                                                    <div className="type-container" onMouseEnter={() => { if(item.thumbnailMood || item.thumbnailExposed) this.setState({ preview: API_ENDPOINT.replace('testapi', 'api') + (item.thumbnailMood ? item.thumbnailMood.thumbnail : item.item.thumbnailExposed) })} } onMouseLeave={() => this.setState({ preview: null })}>
                                                        <div className="color-preview" style={{border: item?.attributes?.main_colour_1 == 'White' ? '1px solid #D9D7D9' : '0 solid transparent', backgroundColor: colorsDict[item?.attributes?.main_colour_1 ? item.attributes.main_colour_1.toLowerCase() : 'black' ] ? colorsDict[item?.attributes?.main_colour_1 ? item.attributes.main_colour_1.toLowerCase() : 'black' ] : item?.attributes?.main_colour_1 }}></div>
                                                    </div>
                                                </Link>
                                            )
                                    })
                                    :

                                    null
                            }
                            </div>

                            </div>
                                <p className='price'>  
                                { this.props.price ? <>
                                 {this.props.uData && !this.props.uData.creditCardPayment ?  this.props.price.value : this.props.price.value * (1 + this.props.price.vat / 100)} {this.props.price.currency}  
                                {this.props.price.oldPrice && ((this.props.uData && !this.props.uData.creditCardPayment ? this.props.price.oldPrice : this.props.price.oldPrice * (1 + this.props.price.vat / 100))  !=   (this.props.uData && !this.props.uData.creditCardPayment ?  this.props.price.value : this.props.price.value * (1 + this.props.price.vat / 100))) ? 
                                <><span style={{textDecoration: 'line-through', color: '#4D4B4C', fontWeight: 300}}>
                                    {this.props.uData && !this.props.uData.creditCardPayment ? this.props.price.oldPrice : this.props.price.oldPrice * (1 + this.props.price.vat / 100)} {this.props.price.currency}
                                    </span>
                                    <span style={{marginLeft: 'auto'}}>
                                        -
                                        {
                                          (((
                                            (this.props.uData && !this.props.uData.creditCardPayment ? this.props.price.oldPrice : this.props.price.oldPrice * (1 + this.props.price.vat / 100)) 
                                            -
                                            (this.props.uData && !this.props.uData.creditCardPayment ?  this.props.price.value : this.props.price.value * (1 + this.props.price.vat / 100))
                                              
                                               
                                                )
                                                 / (this.props.uData && !this.props.uData.creditCardPayment ? this.props.price.oldPrice : this.props.price.oldPrice * (1 + this.props.price.vat / 100)) 
                                                 
                                                 ) *100).toFixed()
                                        }
                                        %

                                    </span>
                                    </>
                                    
                                     : null } </> : null }</p>
                                
                        </Link>

                    </div>
                </article>
            </div>
        );
    }
}


export default Article;
