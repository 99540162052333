import React, { Component } from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form'
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux'

import Check from './fields/check';
import Text from './fields/text';
import TextNumber from './fields/textNumber';

import Select from './fields/select';
import Textarea from './fields/textarea';
import Isvg from 'react-inlinesvg';
import {
    Container,
    Row,
    Col,
} from 'reactstrap';

const required = value => value ? undefined : "Required"

const renderSelectField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
    children,
    additionalAction,
    scope,
    disabled
}) => (

    <Select
        placeholder={placeholder}
        label={label}
        errorText={touched && error}
        error={touched && error}
        additionalAction={additionalAction}
        scope={scope}
        {...input}
        children={children}
        disabled={disabled}
    />
)

const renderCheckField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },

}) => (

    <Check
        placeholder={placeholder}
        label={label}
        errorText={touched && error}
        error={touched && error}
        // multilang={multilang}
        // lang={lang}

        {...input}
    />
)

const renderTextField = ({
    input,
    label,
    placeholder,
    meta: { touched, error },
    type
}) => (

    <Text
        placeholder={placeholder}
        errorText={touched && error}
        label={label}
        error={touched && error}
        type={type}
        {...input}
    />
)

const renderTextNumberField = ({
    input,
    label,
    placeholder,
    disabled,
    meta: { touched, error },
    type
}) => (

    <TextNumber
        placeholder={placeholder}
        errorText={touched && error}
        label={label}
        disabled={disabled}
        error={touched && error}
        type={type}
        {...input}
    />
)


const renderTextareaField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
}) => (

    <Textarea
        placeholder={placeholder}
        label={label}
        errorText={touched && error}
        error={touched && error}
        {...input}
    />
)


class RegisterForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            seePassword: false
        }
    }


    render() {


        const { handleSubmit, pristine, reset, submitting } = this.props;

        return (
            <form onSubmit={handleSubmit} className="login-form register-form">
                <Row>
                    <Col lg="12">
                        <div className="field-wrap">
                            <Field
                                name="firstName"
                                component={renderTextField}
                                label={"Förnamn *".translate(this.props.lang)}
                                validate={[required]}
                            ></Field>
                        </div>
                    </Col>
                    <Col lg="12">
                        <div className="field-wrap">
                            <Field
                                name="lastName"
                                component={renderTextField}
                                label={"Efternamn *".translate(this.props.lang)}
                                validate={[required]}
                            ></Field>
                        </div>
                    </Col>
                    <Col lg="12">
                        <div className="field-wrap">
                            <Field
                                name="socialSecurityNumber"
                                component={renderTextField}
                                label={"Personnummer *".translate(this.props.lang)}
                                validate={[required]}
                            ></Field>
                        </div>
                    </Col>
                    <Col lg="12">
                        <div className="field-wrap">

                            <Field
                                name="deliveryAddress"
                                component={renderTextField}
                                label={"Gatuadress *".translate(this.props.lang)}
                                validate={[required]}
                            ></Field>
                        </div>
                    </Col>

                    <Col lg="12">
                        <div className="field-wrap">
                            <Field
                                name="zipCode"
                                component={renderTextNumberField}
                                label={"Postnummer *".translate(this.props.lang)}
                                validate={[required]}
                            ></Field>
                        </div>
                    </Col>
                    <Col lg="12">
                        <div className="field-wrap">
                            <Field
                                name="place"
                                component={renderTextField}
                                label={"Ort *".translate(this.props.lang)}
                                validate={[required]}
                            ></Field>
                        </div>
                    </Col>
                    <Col lg="12">
                        <div className="field-wrap">
                            <Field
                                name="country"
                                disabled
                                component={renderSelectField}
                                label={"Land *"}
                                validate={[required]}
                            >
                                <option value="">Select country / region…</option>
                                <option value="AF">Afghanistan</option>
                                <option value="AX">Åland</option>
                                <option value="AL">Albania</option>
                                <option value="DZ">Algeria</option>
                                <option value="VI">US Virgin Islands</option>
                                <option value="AS">American Samoa</option>
                                <option value="AD">Andorra</option>
                                <option value="AO">Angola</option>
                                <option value="AI">Anguilla</option>
                                <option value="AQ">Antarctica</option>
                                <option value="AG">Antigua and Barbuda</option>
                                <option value="AR">Argentina</option>
                                <option value="AM">Armenia</option>
                                <option value="AW">Aruba</option>
                                <option value="AU">Australia</option>
                                <option value="AZ">Azerbaijan</option>
                                <option value="BS">Bahamas</option>
                                <option value="BH">Bahrain</option>
                                <option value="BD">Bangladesh</option>
                                <option value="BB">Barbados</option>
                                <option value="PW">Belau</option>
                                <option value="BE">Belgium</option>
                                <option value="BZ">Belize</option>
                                <option value="BJ">Benin</option>
                                <option value="BM">Bermuda</option>
                                <option value="BT">Bhutan</option>
                                <option value="BO">Bolivia</option>
                                <option value="BQ">Bonaire, Saint Eustatius and Saba</option>
                                <option value="BA">Bosnien Hercegovina</option>
                                <option value="BW">Botswana</option>
                                <option value="BV">Bouvet Island</option>
                                <option value="BR">Brasilien</option>
                                <option value="IO">British Indian Oceanterritoriet</option>
                                <option value="VG">Brittiska Jungfruöarna</option>
                                <option value="BN">Brunei</option>
                                <option value="BG">Bulgarien</option>
                                <option value="BF">Burkina Faso</option>
                                <option value="BI">Burundi</option>
                                <option value="CV">Cap Verde</option>
                                <option value="KY">Caymanöarna</option>
                                <option value="CF">Centralafrikanska republiken</option>
                                <option value="CL">Chile</option>
                                <option value="CO">Colombia</option>
                                <option value="CK">Cooköarna</option>
                                <option value="CR">Costa Rica</option>
                                <option value="CW">Curaçao</option>
                                <option value="CY">Cypern</option>
                                <option value="DK">Danmark</option>
                                <option value="DJ">Djibouti</option>
                                <option value="DM">Dominica</option>
                                <option value="DO">Dominikanska Republiken</option>
                                <option value="EC">Ecuador</option>
                                <option value="EG">Egypten</option>
                                <option value="GQ">Ekvatorialguinea</option>
                                <option value="SV">El Salvador</option>
                                <option value="CI">Elfenbenskusten</option>
                                <option value="ER">Eritrea</option>
                                <option value="EE">Estland</option>
                                <option value="ET">Etiopien</option>
                                <option value="FK">Falklandsöarna</option>
                                <option value="FO">Faroeöarna</option>
                                <option value="FJ">Fiji</option>
                                <option value="PH">Filippinerna</option>
                                <option value="FI">Finland</option>
                                <option value="AE">Förenade Arabemiraten</option>
                                <option value="UM">Förenta staternas (USA) mindre öar i Oceanien och Västindien</option>
                                <option value="FR">Frankrike</option>
                                <option value="GF">Franska Guiana</option>
                                <option value="PF">Franska polynesien</option>
                                <option value="TF">Franska sydterritorierna</option>
                                <option value="GA">Gabon</option>
                                <option value="GM">Gambia</option>
                                <option value="GE">Georgien</option>
                                <option value="GH">Ghana</option>
                                <option value="GI">Gibraltar</option>
                                <option value="GR">Grekland</option>
                                <option value="GD">Grenada</option>
                                <option value="GL">Grönland</option>
                                <option value="GP">Guadeloupe</option>
                                <option value="GU">Guam</option>
                                <option value="GT">Guatemala</option>
                                <option value="GG">Guernsey</option>
                                <option value="GN">Guinea</option>
                                <option value="GW">Guinea-Bissau</option>
                                <option value="GY">Guyana</option>
                                <option value="HT">Haiti</option>
                                <option value="HM">Heard Island och McDonald Islands</option>
                                <option value="HN">Honduras</option>
                                <option value="HK">Hong Kong</option>
                                <option value="IN">Indien</option>
                                <option value="ID">Indonesien</option>
                                <option value="IQ">Irak</option>
                                <option value="IR">Iran</option>
                                <option value="IE">Irland</option>
                                <option value="IS">Island</option>
                                <option value="IM">Isle of Man</option>
                                <option value="IL">Israel</option>
                                <option value="IT">Italien</option>
                                <option value="JM">Jamaica</option>
                                <option value="JP">Japan</option>
                                <option value="JE">Jersey</option>
                                <option value="JO">Jordanien</option>
                                <option value="CX">Julön</option>
                                <option value="KH">Kambodja</option>
                                <option value="CM">Kamerun</option>
                                <option value="CA">Kanada</option>
                                <option value="KZ">Kazakstan</option>
                                <option value="KE">Kenya</option>
                                <option value="CN">Kina</option>
                                <option value="KG">Kirgizistan</option>
                                <option value="KI">Kiribati</option>
                                <option value="CC">Kokosöarna (Keeling)</option>
                                <option value="KM">Komorerna</option>
                                <option value="CG">Kongo (Brazzaville)</option>
                                <option value="CD">Kongo (Kinshasa)</option>
                                <option value="HR">Kroatien</option>
                                <option value="CU">Kuba</option>
                                <option value="KW">Kuwait</option>
                                <option value="LA">Laos</option>
                                <option value="LS">Lesotho</option>
                                <option value="LV">Lettland</option>
                                <option value="LB">Libanon</option>
                                <option value="LR">Liberia</option>
                                <option value="LY">Libyen</option>
                                <option value="LI">Liechtenstein</option>
                                <option value="LT">Litauen</option>
                                <option value="LU">Luxemburg</option>
                                <option value="MO">Macao</option>
                                <option value="MG">Madagaskar</option>
                                <option value="MW">Malawi</option>
                                <option value="MY">Malaysia</option>
                                <option value="MV">Maldiverna</option>
                                <option value="ML">Mali</option>
                                <option value="MT">Malta</option>
                                <option value="MA">Marocco</option>
                                <option value="MH">Marshall Islands</option>
                                <option value="MQ">Martinique</option>
                                <option value="MR">Mauritanien</option>
                                <option value="MU">Mauritius</option>
                                <option value="YT">Mayotte</option>
                                <option value="MX">Mexico</option>
                                <option value="FM">Mikronesien</option>
                                <option value="MD">Moldavien</option>
                                <option value="MC">Monaco</option>
                                <option value="MN">Mongolien</option>
                                <option value="ME">Montenegro</option>
                                <option value="MS">Montserrat</option>
                                <option value="MZ">Mozambique</option>
                                <option value="MM">Myanmar</option>
                                <option value="NA">Namibia</option>
                                <option value="NR">Nauru</option>
                                <option value="NL">Nederländerna</option>
                                <option value="NP">Nepal</option>
                                <option value="NI">Nicaragua</option>
                                <option value="NE">Niger</option>
                                <option value="NG">Nigeria</option>
                                <option value="NU">Niue</option>
                                <option value="KP">Nordkorea</option>
                                <option value="MK">Nordmakedonien</option>
                                <option value="NF">Norfolk Island</option>
                                <option value="NO">Norge</option>
                                <option value="MP">Norra Marianaöarna</option>
                                <option value="NC">Nya Caledonien</option>
                                <option value="NZ">Nya Zeeland</option>
                                <option value="OM">Oman</option>
                                <option value="AT">Österrike</option>
                                <option value="PK">Pakistan</option>
                                <option value="PS">Palestinska territoriet</option>
                                <option value="PA">Panama</option>
                                <option value="PG">Papua New Guinea</option>
                                <option value="PY">Paraguay</option>
                                <option value="PE">Peru</option>
                                <option value="PN">Pitcairn</option>
                                <option value="PL">Polen</option>
                                <option value="PT">Portugal</option>
                                <option value="PR">Puerto Rico</option>
                                <option value="QA">Qatar</option>
                                <option value="RE">Reunion</option>
                                <option value="RO">Rumänien</option>
                                <option value="RW">Rwanda</option>
                                <option value="RU">Ryssland</option>
                                <option value="BL">Saint Barthélemy</option>
                                <option value="KN">Saint Kitts och Nevis</option>
                                <option value="SX">Saint Martin (Dutch part)</option>
                                <option value="MF">Saint Martin (Franska delen)</option>
                                <option value="PM">Saint Pierre och Miquelon</option>
                                <option value="VC">Saint Vincent och Grenadinerna</option>
                                <option value="WS">Samoa</option>
                                <option value="SM">San Marino</option>
                                <option value="SH">Sankt Helena</option>
                                <option value="LC">Sankta Lucia</option>
                                <option value="ST">São Tomé and Príncipe</option>
                                <option value="SA">Saudi Arabien</option>
                                <option value="CH">Schweiz</option>
                                <option value="SN">Senegal</option>
                                <option value="RS">Serbia</option>
                                <option value="SC">Seychelles</option>
                                <option value="SL">Sierra Leone</option>
                                <option value="SG">Singapore</option>
                                <option value="SK">Slovakia</option>
                                <option value="SI">Slovenia</option>
                                <option value="SB">Solomon Islands</option>
                                <option value="SO">Somalia</option>
                                <option value="SS">South Sudan</option>
                                <option value="ES">Spain</option>
                                <option value="LK">Sri Lanka</option>
                                <option value="GB">United Kingdom</option>
                                <option value="SD">Sudan</option>
                                <option value="SR">Suriname</option>
                                <option value="SJ">Svalbard and Jan Mayen</option>
                                <option value="SE" selected="selected">Sweden</option>
                                <option value="SZ">Swaziland</option>
                                <option value="ZA">South Africa</option>
                                <option value="GS">South Georgia and the South Sandwich Islands</option>
                                <option value="KR">South Korea</option>
                                <option value="SY">Syria</option>
                                <option value="TJ">Tachikistan</option>
                                <option value="TW">Taiwan</option>
                                <option value="TZ">Tanzania</option>
                                <option value="TD">Chad</option>
                                <option value="TH">Thailand</option>
                                <option value="TL">Timor-Leste</option>
                                <option value="CZ">Czech Republic</option>
                                <option value="TG">Togo</option>
                                <option value="TK">Tokelau</option>
                                <option value="TO">Tonga</option>
                                <option value="TT">Trinidad and Tobago</option>
                                <option value="TN">Tunisia</option>
                                <option value="TR">Turkey</option>
                                <option value="TM">Turkmenistan</option>
                                <option value="TC">Turks and Caicos Islands</option>
                                <option value="TV">Tuvalu</option>
                                <option value="DE">Germany</option>
                                <option value="US">USA (US)</option>
                                <option value="UG">Uganda</option>
                                <option value="UA">Ukraine</option>
                                <option value="HU">Hungary</option>
                                <option value="UY">Uruguay</option>
                                <option value="UZ">Uzbekistan</option>
                                <option value="VU">Vanuatu</option>
                                <option value="EH">Western Sahara</option>
                                <option value="VA">Vatican City State</option>
                                <option value="VE">Venezuela</option>
                                <option value="VN">Vietnam</option>
                                <option value="BY">Belarus</option>
                                <option value="WF">Wallis and Futuna</option>
                                <option value="YE">Yemen</option>
                                <option value="ZM">Zambia</option>
                                <option value="ZW">Zimbabwe</option>                            </Field>
                        </div>
                    </Col>

                    {/* <Col lg="12">
                        <div className="field-wrap shipping-address">
                            <div className="check-wrap">
                                <Field
                                    name="sameAsDeliveryAddress"
                                    component={renderCheckField}
                                    label={"Samma som leveransadress".translate(this.props.lang)}
                                    placeholder=""
                                ></Field>

                            </div>

                            <Field
                                name="invoiceAddress"
                                component={renderTextField}
                                label={"Fakturaadress *".translate(this.props.lang)}
                                validate={!this.props.sameAsDeliveryAddress ? [required] : []}
                            ></Field>
                        </div>
                    </Col> */}

                    <Col lg="12">
                        <div className="field-wrap">
                            <Field
                                name="phoneNotification"
                                component={renderTextField}
                                label={"Telefon *".translate(this.props.lang)}
                                validate={[required]}
                            ></Field>
                        </div>
                    </Col>


                    <Col lg="12">
                        <div className="field-wrap">
                            <Field
                                name="invoiceEmail"
                                component={renderTextField}
                                label={"E-postadress *".translate(this.props.lang)}
                                validate={[required]}
                            ></Field>
                        </div>
                    </Col>
                    {/* <Col lg="12">
                        <div className="field-wrap">
                            <Field
                                name="description"
                                component={renderTextField}
                                label={"Viket företag jobbar du på? *".translate(this.props.lang)}
                                validate={[required]}
                            ></Field>
                        </div>
                    </Col> */}


                    <Col lg="12">
                        <p className="register-text">{'Din personliga data kommer endast att användas för att hantera beställningar, kundservice, för att förbättra din upplevelse av webbplatsen och eventuellt annat som står i vår integritetspolicy.'.translate(this.props.lang)}</p>
                    </Col>
                    <Col lg="12" style={{ marginBottom: 15 }}>
                        <div className="check-wrap">
                            <Field
                                name="privacyPolicy"
                                component={renderCheckField}
                                label={<>{"Jag har läst och accepterar ".translate(this.props.lang)}<Link to="/personuppgiftspolicy">{'integritetspolicyn'.translate(this.props.lang)}</Link></>}
                                placeholder=""
                                validate={[required]}
                            ></Field>

                        </div>
                    </Col>
                    <Col lg="12" style={{ marginBottom: 15 }}>
                        <div className="check-wrap">
                            <Field
                                name="newsletter"
                                component={renderCheckField}
                                label={"Registrera mig för nyhetsbrevet! *".translate(this.props.lang)}
                                placeholder=""
                            ></Field>

                        </div>
                    </Col>
                    <Col lg="12" className="btn-wrap">

                        {this.props.loading ?
                            null
                            :
                            <button className="button">{'SKICKA'.translate(this.props.lang)}</button>
                        }
                    </Col>

                </Row>


            </form>

        )
    }
}



RegisterForm = reduxForm({
    form: 'registerForm' // a unique identifier for this form
})(RegisterForm)

// Decorate with connect to read form values
const selector = formValueSelector('registerForm') // <-- same as form name
RegisterForm = connect(state => {
    const sameAsDeliveryAddress = selector(state, 'sameAsDeliveryAddress'
    )
    return {
        sameAsDeliveryAddress: sameAsDeliveryAddress
    }
})(RegisterForm)

export default RegisterForm
