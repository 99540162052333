import React, { Component } from 'react';
import Page from '../containers/page';
import {Link} from 'react-router-dom'
import {
    Container,
    Row,
    Col,
    Accordion
} from 'reactstrap';



//la are loged answers, ua are unloged answers

const questionsAnswers = {
    orderLeverans: [
        {
            id: 1,
            question: "Vad har ni för leveranstider?",
            la: "Vi har en generell ledtid på 48h från det att vårt lager har mottagit er order. Beräknat skeppningsdatum framgår alltid på orderbekräftelsen som skickas till er efter placerad order och kan komma att uppdateras om någon förändring i ledet sker. Om så är fallet så kommer en ny orderbekräftelse med ett nytt beräknat skeppningsdatum skickas till er angivna mail.",
            ua: "Vi har en generell ledtid på 48h från det att vårt lager har mottagit din order. Beräknat skeppningsdatum framgår alltid på orderbekräftelsen som skickas till din återförsäljare och kan komma att uppdateras om någon förändring i ledet sker. Om så är fallet så kommer en ny orderbekräftelse med ett nytt beräknat skeppningsdatum skickas till din återförsäljare."
        }, {
            id: 2,
            question: "Vad kostar frakten?",
            la: "Frakten bokas manuellt med det frakbolag som har den billigaste lösningen för just din order. Några fasta priser kan vi inte ge i dagsläget. Frakten tillkommer på er faktura.",
            ua: "Fraktkostnad för din order debiteras av återförsäljaren du har inhandlat din möbel från. Ta kontakt med dem för vidare hjälp."
        },
        {
            id: 3,
            question: "Vart ser jag status på min order?",
            la: 'Du kan följa dina orders status inne på "Mina sidor".',
            ua: ""
        },
        {
            id: 4,
            question: "Har du produkter i din varukorg med olika lagerstatus?",
            la: "Har du inte aktivt valt att delleverera i kassan så kommer din order att skickas när samtliga produkter finns i lager. Om du aktivt har kryssat i rutan för delleverans i kassan så kommer försändelsen delas upp i olika sändningar utefter när de olika artiklar finns i lager. Observera att om du har produkter i din varukorg med olika lagerstatus, blir du debiterad en fraktavgift per leverans.",
            ua: ""
        },
        {
            id: 5,
            question: "Hur vet jag vad återförsäljaren har i lager?",
            la: "",
            ua: "För att få reda på återförsäljares lagersaldo så behöver du ta kontakt med vald återförsäljare, då vi inte har insyn i våra olika återförsäljares lagerstatus."
        },
        {
            id: 6,
            question: "Hur gör jag om jag ångra mitt köp och vill retunera?",
            la: "Det förekommer ingen ångerrätt mellan näringsidkare. Väljer ni ändå att retunera så behöver information skickas till info@venturedesign.se om vad som kommer i retur. Ni som avsändare står för returfrakt och kredit för produkten/erna ställs enbart ut om godset kommer tillbaka i obruten förpackning, där inga yttre skador förekommer på emballaget.",
            ua: "Ångrar du ditt köp ska du kontakta din återförsäljar innan ångerfristen löpt ut."
        }
    ],
    produktSkotsel: [
        {
            id: 1,
            question: "Hur hittar jag måtten på era produkter?",
            la: "Måttbilder finns på var enskilds artikel produktsida under underkategorin MÅTTBILDER, till höger om produktbilderna. Skulle det mot förmodan saknas på någon, vänligen maila info@venturedesign.se",
            ua: "Måttbilder finns på var enskilds artikel produktsida under underkategorin MÅTTBILDER till höger om produktbilderna. Skulle det mot förmodan saknas på någon, vänligen kontakta din återförsäljare eller maila info@venturedesign.se"
        },
        {
            id: 2,
            question: "Hur ser jag om artikeln kommer in i lager igen?",
            la: "På artikelns produktsida framgår det om artikeln finns i lager, om den väntas in och så fall vilket datum, eller om den är slut för säsongen.",
            ua: "Vänligen ta kontakt med din återförsäljare så hjälper de dig. "
        },
        {
            id: 3,
            question: "Ingår de dynor som visas på produktbilderna?",
            la: "Vad som ingår i varje möbel eller möbelset framgår under artikelns produktsida.",
            ua: "Vad som ingår i varje möbel eller möbelset framgår under artikelns produktsida."
        },
        {
            id: 4,
            question: "Har era olika dynor avtagbar klädsel?",
            la: "De flesta dynor har avtagbar klädsel. Information om detta hittar man under artikelns produktsida. Har alltid för vana att läsa igenom produktens skötselråd.",
            ua: "De flesta dynor har avtagbar klädsel. Information om detta hittar man under artikelns produktsida. Har alltid för vana att läsa igenom produktens skötselråd."
        },
        {
            id: 5,
            question: "Hur tillverkas era möbler?",
            la: "För att veta mer om oss och hur vi jobbar, kika här: https://new.venturedesign.se/om-oss",
            ua: "För att veta mer om oss och hur vi jobbar, kika här: https://new.venturedesign.se/om-oss"
        },
        {
            id: 6,
            question: "Hur vet jag om en viss artikel kommer tillbaka nästa säsong?",
            la: 'Är man inloggad så ser man status inne på artikelns produktsida. Står det "Utgått ur sortimentet" så kommer möbeln inte in igen. Står det "Slut för säsongen" så kan man räkna med att den inkommer nästa säsong om inget oförutsett sker.',
            ua: "Vänligen ta kontakt med din återförsäljare så hjälper de dig."
        },
    ],
    reklamation: [
        {
            id: 1,
            question: "Hur gör jag en reklamation?",
            la: "Under Beställningar så väljer ni den order det avser som artiklen ska reklameras mot. Välj den korrekta artikeln och fyll i de uppgifter som efterfrågas. Se även vår instruktionsfilm här: www......  För vidare frågor eller hjälp, kontakta claim@venturedesign.se",
            ua: "Om du mot förmodan behöver reklamera din produkt så gör du det via din återförtsäljare utan dröjsmål. Vid en reklamation behöver återförsäljaren uppgifter om din protukt som är defekt. Ange vid kontakt med dem: Odernummer, artikelnummer, batchnummer, beskrivning av defekten samt en översiktsbild av produkten och närbild/er på defekten."
        },
        {
            id: 2,
            question: "Om jag har fått fel vara?",
            la: "Skicka ditt ärende med uppgifter om order, artikel och bild på emballge där artikelnummer framgår på den artikel ni har mottagit felaktigt till claim@venturedesign.se",
            ua: "Om du mot förmodan har mottagit en felaktig produkt så bör du omgående kontakta din återförtsäljare, de hjälper dig vidare i ärendet."
        },
        {
            id: 3,
            question: "När kan jag förvänta mig att min ersättning -/reservdelsorder kommer?",
            la: "En reklamationsorder har som standard samma ledtid som en ordinarie order (48h), om något annat gäller så framkommer denna informtionen i det pågående rekamationsärendet.",
            ua: "För att höra status gällande ditt reklamationsärende så tar du kontakt med den återförsäljare du har köpt och reklamerat din möbel till. En reklamationsorder har som standard samma ledtid som en ordinarie order (48h), om något annat gäller för just din ersättningsorder så har din återförsäljare informtion om det."
        }
    ],
    support: [
        {
            id: 1,
            question: "Hur länge har växeln öppet?",
            la: "Samtliga kontaktauppgifter finner du på https://new.venturedesign.se/kontakt",
            ua: "Samtliga kontaktauppgifter finner du på https://new.venturedesign.se/kontakt"
        },
        {
            id: 2,
            question: "Hur lång svarstid har ni?",
            la: "Vi har en svarttid på 24h på vardagar, med vissa undantag under vår mest hektiska period under sommarmånaderna.",
            ua: "Vi har en svarttid på 24h på vardagar, med vissa undantag under vår mest hektiska period under sommarmånaderna."
        },
        {
            id: 3,
            question: "Vart hittar jag närmsta återförsäljare?",
            la: "",
            ua: "Vänligen se https://new.venturedesign.se/hitta-aterforsaljare"
        },
        {
            id: 4,
            question: "Har ni en utställning eller ett showroom?",
            la: "",
            ua: "Vi har ingen utställing eller show room för allmänheten i dagsläget.Ta istället kontakt med din närmsta återförsäljare och hör om de har den möbel du är nyfiken på. https://new.venturedesign.se/hitta-aterforsaljare"
        },
        {
            id: 5,
            question: "Vart kan jag provsitta era möbler?",
            la: "",
            ua: "Kontakta någon av våra återförsäljare som finns nära dig och hör om de har den möbel du vill testa. https://new.venturedesign.se/hitta-aterforsaljare"
        }
    ]
}

var striptags = require('striptags');

class FQpage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData,
            questionClass: "orderLeverans",
            questionId: 0,
        };
    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

    }

    toggleAcc = (id) => {
        if (this.state.questionId === id) {
            this.setState({ questionId: 0 })
        } else {
            this.setState({ questionId: id })
        }
    }

    selfClosing = (id) => {
        if (this.state.questionId === id) {
            this.setState({ questionId: 0 })
        } else {
            this.setState({ questionId: id })
        }
    }

    renderQuestions = () => {

        if (this.props.uData) {
            switch (this.state.questionClass) {
                case "orderLeverans":
                    return questionsAnswers.orderLeverans.map((data) => {
                        if (data.la) {
                            return (<>
                                <div className="faq-question-wrapper">
                                    <h3 onClick={(e) => this.toggleAcc(data.id)} className="faq-question">{data.question.translate(this.props.lang)}</h3>
                                    <h3 onClick={() => this.selfClosing(data.id)}>{this.state.questionClass === "orderLeverans" && this.state.questionId === data.id ? "-" : "+"}</h3>
                                </div>

                                <p className={this.state.questionClass === "orderLeverans" && this.state.questionId === data.id ? "faq-answer-show" : "faq-answer"}>{data.la.translate(this.props.lang)}</p>
                            </>)
                        } else return null
                    })
                case "produktSkotsel":
                    return questionsAnswers.produktSkotsel.map((data) => {
                        if (data.la) {
                            return (<>
                                <div className="faq-question-wrapper">
                                    <h3 onClick={(e) => this.toggleAcc(data.id)} className="faq-question">{data.question.translate(this.props.lang)}</h3>
                                    <h3 onClick={() => this.selfClosing(data.id)}>{this.state.questionClass === "produktSkotsel" && this.state.questionId === data.id ? "-" : "+"}</h3>
                                </div>

                                <p className={this.state.questionClass === "produktSkotsel" && this.state.questionId === data.id ? "faq-answer-show" : "faq-answer"}>{data.la.translate(this.props.lang)}</p>
                            </>)
                        } else return null
                    })
                case "reklamation":
                    return questionsAnswers.reklamation.map((data) => {
                        if (data.la) {
                            return (<>
                                <div className="faq-question-wrapper">
                                    <h3 onClick={(e) => this.toggleAcc(data.id)} className="faq-question">{data.question.translate(this.props.lang)}</h3>
                                    <h3 onClick={() => this.selfClosing(data.id)}>{this.state.questionClass === "reklamation" && this.state.questionId === data.id ? "-" : "+"}</h3>
                                </div>

                                <p className={this.state.questionClass === "reklamation" && this.state.questionId === data.id ? "faq-answer-show" : "faq-answer"}>{data.la.translate(this.props.lang)}</p>
                            </>)
                        } else return null
                    })
                case "support":
                    return questionsAnswers.support.map((data) => {
                        if (data.la) {
                            return (<>
                                <div className="faq-question-wrapper">
                                    <h3 onClick={(e) => this.toggleAcc(data.id)} className="faq-question">{data.question.translate(this.props.lang)}</h3>
                                    <h3 onClick={() => this.selfClosing(data.id)}>{this.state.questionClass === "support" && this.state.questionId === data.id ? "-" : "+"}</h3>
                                </div>

                                <p className={this.state.questionClass === "support" && this.state.questionId === data.id ? "faq-answer-show" : "faq-answer"}>{data.la.translate(this.props.lang)}</p>
                            </>)
                        } else return null
                    })
            }
        } else {
            switch (this.state.questionClass) {
                case "orderLeverans":
                    return questionsAnswers.orderLeverans.map((data) => {
                        if (data.ua) {
                            return (<>
                                <div className="faq-question-wrapper">
                                    <h3 onClick={(e) => this.toggleAcc(data.id)} className="faq-question">{data.question.translate(this.props.lang)}</h3>
                                    <h3 onClick={() => this.selfClosing(data.id)}>{this.state.questionClass === "orderLeverans" && this.state.questionId === data.id ? "-" : "+"}</h3>
                                </div>

                                <p className={this.state.questionClass === "orderLeverans" && this.state.questionId === data.id ? "faq-answer-show" : "faq-answer"}>{data.ua.translate(this.props.lang)}</p>
                            </>)
                        } else return null
                    })
                case "produktSkotsel":
                    return questionsAnswers.produktSkotsel.map((data) => {
                        if (data.ua) {
                            return (<>
                                <div className="faq-question-wrapper">
                                    <h3 onClick={(e) => this.toggleAcc(data.id)} className="faq-question">{data.question.translate(this.props.lang)}</h3>
                                    <h3 onClick={() => this.selfClosing(data.id)}>{this.state.questionClass === "produktSkotsel" && this.state.questionId === data.id ? "-" : "+"}</h3>
                                </div>

                                <p className={this.state.questionClass === "produktSkotsel" && this.state.questionId === data.id ? "faq-answer-show" : "faq-answer"}>{data.ua.translate(this.props.lang)}</p>
                            </>)
                        } else return null
                    })
                case "reklamation":
                    return questionsAnswers.reklamation.map((data) => {
                        if (data.ua) {
                            return (<>
                                <div className="faq-question-wrapper">
                                    <h3 onClick={(e) => this.toggleAcc(data.id)} className="faq-question">{data.question.translate(this.props.lang)}</h3>
                                    <h3 onClick={() => this.selfClosing(data.id)}>{this.state.questionClass === "reklamation" && this.state.questionId === data.id ? "-" : "+"}</h3>
                                </div>

                                <p className={this.state.questionClass === "reklamation" && this.state.questionId === data.id ? "faq-answer-show" : "faq-answer"}>{data.ua.translate(this.props.lang)}</p>
                            </>)
                        } else return null
                    })
                case "support":
                    return questionsAnswers.support.map((data) => {
                        if (data.ua) {
                            return (<>
                                <div className="faq-question-wrapper">
                                    <h3 onClick={(e) => this.toggleAcc(data.id)} className="faq-question">{data.question.translate(this.props.lang)}</h3>
                                    <h3 onClick={() => this.selfClosing(data.id)}>{this.state.questionClass === "support" && this.state.questionId === data.id ? "-" : "+"}</h3>
                                </div>

                                <p className={this.state.questionClass === "support" && this.state.questionId === data.id ? "faq-answer-show" : "faq-answer"}>{data.ua.translate(this.props.lang)}</p>
                            </>)
                        } else return null
                    })
            }
        }
    }

    render() {
        return (
            <div className="faq-page">

                <Row>
                    <Col lg="12" className="faq-heading">
                        <h2>FAQ</h2>
                    </Col>
                </Row>
                <Row className="faq-row-wrapper">
                    <Container>
                        <Row >
                            <Col lg="12" className="faq-text">
                                <p>{`Här kan du hitta svar på de vanligaste frågorna. Om du inte hittar den fråga eller det svar du efterfrågar så är du välkommen att kontakta oss via vårt`.translate(this.props.lang)}<Link className="link-style" to="/kontakt">{'  kontaktformulär.'.translate(this.props.lang)}</Link></p>
                            </Col>
                        </Row>
                    </Container>
                </Row>
                <Container>
                    <Row>
                        <Col className="faq-classes">
                            <h3 onClick={() => this.setState({ questionClass: "orderLeverans", questionId: 0 })}>{'Order & Leverans'.translate(this.props.lang)}</h3>
                            <h3 onClick={() => this.setState({ questionClass: "produktSkotsel", questionId: 0 })}>{'Produkt & Skötsel'.translate(this.props.lang)}</h3>
                            <h3 onClick={() => this.setState({ questionClass: "reklamation", questionId: 0 })}>{'Reklamation'.translate(this.props.lang)}</h3>
                            <h3 onClick={() => this.setState({ questionClass: "support", questionId: 0 })}>{'Support'.translate(this.props.lang)}</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="faq-qa">
                            {this.renderQuestions()}
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default Page(FQpage);