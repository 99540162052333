import React, { Component } from 'react';
import Isvg from 'react-inlinesvg';
import { Link } from 'react-router-dom';

import {
    Container,
    Row,
    Col,
} from 'reactstrap';


import xIcon from '../assets/svg/x.svg';
import deleteIcon from '../assets/svg/delete.svg';
import boxIcon from '../assets/svg/box.svg';
import arrowRightIcon from '../assets/svg/arrow-right-v2.svg';

import tagIcon from '../assets/svg/tag.svg';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../helpers/linkHelper';
import noImage from '../assets/images/no-image.png';
import { API_ENDPOINT } from '../constants';
import cards from '../assets/images/cards.png';
function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}

var striptags = require('striptags');
function detectMob() {
    const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i
    ];

    return toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
    });
}

class Cart extends Component {
    constructor(props) {
        super(props);

        this.state = {
            items: [],
            servicePoints: []
        };
    }

    get = () => {
        let authToken = null;

        if (typeof window !== 'undefined') {
            authToken = localStorage.getItem('authToken') ? `Bearer ${localStorage.getItem('authToken')}` : localStorage.getItem('guestToken') ?  `Guest ${localStorage.getItem('guestToken')}` : null;
        }

        fetch(API_ENDPOINT + '/cart/discount/code', {
            method: 'GET',
            headers: {
'X-Brand': 'Vind',
                'Content-Type': 'application/json',
                'Authorization': authToken

            },
        }).then(parseJSON).then(({ result, status }) => {
            console.log(result)
            if (status >= 200 && status < 300) {
                this.setState( {
                    usedDiscountCode: result.code,
                    discountValue: result.discount
                });
            }else{
                if (result.expired){
                    this.setState( {
                        discountCodeExpired: true
                    });
                }
            }

        }).catch(() => {

        })

    }

    applyDiscountCode = () => {
        if (!this.state.discountCode) {
            return;
        }

        if (!this.state.discountCode.trim()) {
            return;
        }


        fetch(API_ENDPOINT + '/cart/discount/apply', {
            method: 'POST',
            headers: {
'X-Brand': 'Vind',
                'Authorization': localStorage.getItem('authToken') ? `Bearer ${localStorage.getItem('authToken')}` : localStorage.getItem('guestToken') ? `Guest ${localStorage.getItem('guestToken')}` : null,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                discountCode: this.state.discountCode
            })
        }).then(res => res.json()).then((result) => {
            if (result.error) {
                this.setState({ discountError: result.error, discountCode: '' });

            } else {

                this.setState({
                    discountError: ''
                })
                this.props.fetchCartInfo();
                if (!this.props.uData) {
                    this.get();
                } else {
                    this.props.verifyUser(this.get);
                }
            }
        })
    }


    componentDidMount() {
        this.get();

        window.addEventListener('message', this.messageHandler);
    }


    messageHandler = (e) => {
        console.log(e.data);

        if (e && e.data && e.data.indexOf && e.data.indexOf('transaction') !== -1) {
            this.props[0].history.push(`/checkout?transaction=${e.data.replace('transaction=', '')}`)
        }
    }


    searchServicePoints = () => {
        fetch(API_ENDPOINT + '/cart/service-points', {
            method: 'POST',
            headers: {
'X-Brand': 'Vind',
                'Authorization': localStorage.getItem('authToken') ? `Bearer ${localStorage.getItem('authToken')}` : localStorage.getItem('guestToken') ? `Guest ${localStorage.getItem('guestToken')}` : null,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                postalCode: this.state.postalCode
            })
        }).then(res => res.json()).then((result) => {
            if (result.error) {
                this.setState({
                    servicePoints: result.items,
                    servicePointsError: result.error
                })

                return;
            }

            if (result.items) {
                this.setState({
                    servicePoints: result.items,
                    servicePointsError: ''
                })
            }
        })

    }


    initPayment = () => {
        this.setState({
            payment: true
        }, () => {
            fetch(API_ENDPOINT + '/cart/init-payment', {
                method: 'POST',
                headers: {
'X-Brand': 'Vind',
                    'Authorization': localStorage.getItem('authToken') ? `Bearer ${localStorage.getItem('authToken')}` : localStorage.getItem('guestToken') ? `Guest ${localStorage.getItem('guestToken')}` : null,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    billingData: this.state.billingData,
                    shippingData: this.state.shippingData,
                    deliveryDate: this.state.deliveryDate,
                    orderReference: this.state.orderReference,
                    deliverToDifferentAddress: this.state.deliverToDifferentAddress,
                    partialDelivery: this.state.partialDelivery,
                    freightMarks: this.state.freightMarks,
                    servicePoint: this.state.servicePoint,
                    registerForNewsletter: this.state.registerForNewsletter
                })
            }).then(res => res.json()).then((result) => {
                console.log(result)

                //if (detectMob()){
                window.location.href = API_ENDPOINT + '/cart/payment-gateway/' + result.order_id;

                /*}else{
                    this.iframe.src = `data:text/html;charset=utf-8,` + escape(result.html_snippet)

                }*/


            })
        })

    }


    render() {
        if (!this.props.cartInfo)
            return null;

        return (
            <div className="cart-modal-wrapper">
                <div className="overlay" onClick={() => this.props.close()}></div>
                <div className="cart-modal">
                    <div className="head">
                        <h2>{'KUNDKORG'.translate(this.props.lang)}</h2>
                        <button onClick={() => this.props.close()}><Isvg src={xIcon} /></button>
                        
                    </div>
                    <div className="head-spacer">
                    <hr></hr>
                    </div>
                    <div className="scroll">
                    <div className="table">
                        {
                            this.props.cartInfo?.items.length ?
                                this.props.cartInfo.items.map((item, idx) => {
                                    if (item.vind) {
                                        item = {...item, ...item.vind};
                                    }
                                    return (
                                        <div>
                                            <Link to={`/products/${Object.translate(item, 'alias', this.props.lang)}`}>
                                            <img src={item.thumbnailMood ?
                                                API_ENDPOINT + item.thumbnailMood.thumbnail : item.thumbnailExposed ?
                                                    API_ENDPOINT + item.thumbnailExposed.thumbnail : noImage} />
                                           </Link>
                                            <div>
                                                <div className="horizontal">
                                                <Link to={`/products/${Object.translate(item, 'alias', this.props.lang)}`}>

                                                    <div>
                                                        <h3>{item.productFamily}</h3>
                                                        <h6>{Object.translate(item, 'name', this.props.lang)}</h6>
                                                    </div>
</Link>
                                                    <p className="price" style={{textAlign: 'right'}}>
                                                        {
                                                            item.price.originalValue != item.price.value ?
                                                            <span style={{fontSize: 12, textDecoration: 'line-through', color: '#cacaca'}}>
                                                                 {parseFloat(this.props.uData && !this.props.uData.creditCardPayment ? item.price.originalValue * item.quantity : item.price.originalValue * item.quantity * (1 + item.price.vat / 100)).toFixed(0)}&nbsp;<span>{item.price.currency}</span>
                                                            <br/>
                                                            </span>
                                                            :
                                                            null
                                                        }
                                                        {parseFloat(this.props.uData && !this.props.uData.creditCardPayment ? item.price.value * item.quantity : item.price.value * item.quantity * (1 + item.price.vat / 100)).toFixed(0)}&nbsp;<span>{item.price.currency}</span>
                                                    </p>
                                                </div>
                                                <p className="prop">{'Färg:'.translate(this.props.lang)} {Object.get(item, 'attributes.main_colour_1') ? typeof Object.get(item, 'attributes.main_colour_1') == 'string' ? Object.get(item, 'attributes.main_colour_1') : Object.get(item, 'attributes.main_colour_1').join(', ') : 'N/A'}</p>
                                                <p className="prop">{'Art.Nr:'.translate(this.props.lang)} {item.sku}</p>
                                                <div className="horizontal" style={{ marginTop: 'auto' }}>
                                                    <div className="quantity ">
                                                        <button className="dec" onClick={() => {
                                                            if (item.quantity - (item.quantityStep ? item.quantityStep : 1) > 0) {
                                                                this.props.addToCart(item._id, item.quantity - (item.quantityStep ? item.quantityStep : 1), true, this.get, false, item)

                                                            }
                                                        }}>-</button>
                                                        <input disabled step={item.quantityStep ? item.quantityStep : 1} min="0" value={item.quantity} onChange={(e) => {
                                                            if (parseInt(e.target.value) > 0 && (!item.quantityStep || item.quantityStep == 1 || e.target.value % item.quantityStep == 0))
                                                                this.props.addToCart(item._id, parseInt(e.target.value), true, this.get, false, item)
                                                        }} />
                                                        <button className="inc" onClick={() => {
                                                            this.props.addToCart(item._id, item.quantity + (item.quantityStep ? item.quantityStep : 1), true, this.get, false, item)


                                                        }}>+</button>
                                                    </div>
                                                    <button className="remove" onClick={() => {
                                                        this.props.addToCart(item._id, 0, true, this.get, false, item)
                                                    }}><Isvg src={deleteIcon} /></button>

                                                </div>

                                            </div>
                                        </div>
                                    )
                                })
                                :
                                null
                        }
                    </div>

                    <div className="discount-wrapper">
                        <h6>{'Rabatter'.translate(this.props.lang)} </h6>
                            <div className="coupon">
                                <div>
                                    <input type="text" className="input-text" disabled={this.state.usedDiscountCode} value={this.state.usedDiscountCode ? this.state.usedDiscountCode : this.state.discountCode} onChange={(e) => this.setState({ discountCode: e.target.value })} placeholder={"Rabattkod".translate(this.props.lang)} />
                                    {!(this.state.usedDiscountCode) ?
                                        <button className={this.state.discountCode ? 'active' : ''} onClick={this.applyDiscountCode}>{'Använd rabattkod'.translate(this.props.lang)}</button>
                                        :
                                        null
                                    }
                                </div>
                                {this.state.discountError ? <p style={{ fontSize: 12, color: 'red' }}>{this.state.discountError.translate(this.props.lang)}</p> : null}
                                {
                                    this.state.discountCodeExpired ? <p style={{ fontSize: 12, color: 'red' }}>{'Din kod har upphört att gälla. Kontakta personen du fick koden frän.'.translate(this.props.lang)}</p> : null
                                }
                            </div>
                            


                        {/* <p>{'Logga in för att ta del av dina personliga erbjudanden!'.translate(this.props.lang)}</p>
                        <button>
                            {'Logga in'.translate(this.props.lang)}
                        </button> */}
                    </div>

                    <div className="sum-table">

                    {this.state.discountValue ?
                    <>

<div>
                            <span>{'Delsumma:'.translate(this.props.lang)}</span>
                            <span>{parseFloat(this.props.cartInfo.totalWithoutDiscount).toFixed(0)} {this.props.cartInfo.currency}</span>
                        </div>

<div>
    <span>{'Rabatt:'.translate(this.props.lang)} (-{this.state.discountValue}%)</span>
    <span>-{parseFloat(this.props.cartInfo.totalWithoutDiscount  - (this.props.cartInfo.total - this.props.cartInfo.shippingCost)).toFixed(0)} {this.props.cartInfo.currency}</span>
</div>

</>
:
null

}


                        <div>
                            <span>{'Delsumma:'.translate(this.props.lang)}</span>
                            <span>{ parseFloat(this.props.cartInfo.total - this.props.cartInfo.shippingCost).toFixed(0)} {this.props.cartInfo.currency}</span>
                        </div>

                        {
                            this.props.cartInfo.shippingCost ?
                                <div>
                                    <span>{'Frakt:'.translate(this.props.lang)}</span>
                                    <span>{this.props.cartInfo.shippingCost} {this.props.cartInfo.currency}</span>
                                </div>
                                : null

                        }
                        

                        <hr />
                        <div className="total">
                            <span>{'Summa:'.translate(this.props.lang)}</span>
                            <span>{parseFloat(this.props.cartInfo.total).toFixed(0)} {this.props.cartInfo.currency}</span>
                        </div>
                        {!this.props.uData || (this.props.uData && this.props.uData.creditCardPayment) ?

                            <div>
                                <span>{'Varav moms:'.translate(this.props.lang)}</span>
                                <span>{parseFloat(this.props.cartInfo.total * 0.2).toFixed(0)} {this.props.cartInfo.currency}</span>
                            </div>

                            :
                            null
                        }

<p style={{ fontSize: 12, marginBottom: 20 }}>
                                                {'Ordervärde under 2000:-  / fraktavgift 49:-'.translate(this.props.lang)}
                                                <br />
                                                {'Ordervärde över 2000:- / fraktkostnad 349 :-'.translate(this.props.lang)}
                                            </p>

  {
                                    (!this.props.uData || (this.props.uData && this.props.uData.creditCardPayment)) && this.props.cartInfo.servicePointRequired ?
                                        <div className="service-points">
                                            <h3>{'Hitta närmaste service point'.translate(this.props.lang)}</h3>
                                            <div className="service-points-search">
                                                <label>{'Postnummer'.translate(this.props.lang)}</label>
                                                <div>

                                                    <input type="text" placeholder={'Ange postnummer'.translate(this.props.lang)} value={this.state.postalCode} onKeyDown={(e) => {
                                                        if (e.key === 'Enter') {
                                                            e.preventDefault();
                                                            this.searchServicePoints();
                                                        }

                                                    }} onChange={(e) => this.setState({ postalCode: e.target.value })}></input>
                                                    <button className="button" onClick={this.searchServicePoints}>{'Search'.translate(this.props.lang)}</button>
                                                </div>
                                                {this.state.servicePointsError ? <p className="error">{this.state.servicePointsError.translate(this.props.lang)}</p> : null}
                                            </div>
                                            <div className="service-points-items">
                                                {
                                                    this.state.servicePoints.map((item, idx) => {
                                                        return (
                                                            <div className={this.state.servicePoint && this.state.servicePoint.id == item.id ? 'active' : ''} onClick={() => {
                                                                this.setState({
                                                                    servicePoint: item
                                                                })
                                                            }}>
                                                                <h6>{item.name}</h6>
                                                                <p><span>{item.street}</span>, {item.postalCode} {item.cityName}</p>
                                                            </div>

                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                        :
                                        null
                                }


                        {this.props.cartInfo.items.length && ((this.props.uData && !this.props.uData.creditCardPayment && this.props.uData.username != 'Inlogg hemsida' && this.props.uData.username != 'Login homepage' && this.props.uData.username != 'H&M Home Store')) ?
                            <Link to="/checkout/"><button>{'Fortsätt till kassan'.translate(this.props.lang)}</button></Link>
                            :
                            this.props.cartInfo.items.length && (!this.props.uData || (this.props.uData && this.props.uData.creditCardPayment)) && (!this.props.cartInfo.servicePointRequired || (this.props.cartInfo.servicePointRequired && this.state.servicePoint)) ?

                                <button onClick={this.initPayment}>{'Fortsätt till kassan'.translate(this.props.lang)}</button>
                                :

                                null}

                        <p>{'30 dagars ångerrätt. Läs om'.translate(this.props.lang)} <Link to="/page/returnera-varor">{'returer och återbetalning.'.translate(this.props.lang)}</Link></p>


                    </div>
                    <div className="accepted-methods">
                        <p>{'Betalsätt'.translate(this.props.lang)}</p>
                        <img src={cards} />
                    </div>

                        <Link to="/page/allmanna-villkor">
                    <button className="shipping-info-button">
                        <Isvg src={boxIcon} />
                        {'Leverans- och returalternativ'.translate(this.props.lang)}
                        <Isvg src={arrowRightIcon} />
                    </button>
                    </Link>

                    </div>
                </div>
            </div>
        )


        return (
            <div className="cart-page">
                <Container fluid>
                    <Row>

                        <Col lg="7">
                            <table className="cart-table">
                                <thead>
                                    <tr style={{ borderBottom: '3px solid #ececec' }}>
                                        <th className="product-name" colspan="3">{'Produkt'.translate(this.props.lang)}</th>
                                        <th className="product-price">{'Pris'.translate(this.props.lang)}</th>
                                        <th className="product-quantity">{'Antal'.translate(this.props.lang)}</th>
                                        <th className="product-subtotal">{'Delsumma'.translate(this.props.lang)}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.items.map((item, idx) => {
                                            return (
                                                <tr className="cart-item" key={idx}>
                                                    <td className="product-remove">
                                                        <button className="remove" onClick={() => {
                                                            this.props.addToCart(item._id, 0, true, this.get, false, item)
                                                        }}>×</button>
                                                    </td>
                                                    <td className="product-thumbnail">
                                                        <Link to={`/products/${Object.translate(item, 'alias', this.props.lang)}`} >
                                                            <img src={item.thumbnailExposed ?
                                                                API_ENDPOINT + item.thumbnailExposed.thumbnail : item.thumbnailMood ?
                                                                    API_ENDPOINT + item.thumbnailMood.thumbnail : noImage} className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail" alt="" /></Link>
                                                    </td>
                                                    <td className="product-name">
                                                        <Link to={`/products/${Object.translate(item, 'alias', this.props.lang)}`}>{item.productFamily} {Object.translate(item, 'name', this.props.lang)}</Link> - {item.price ? item.price.salesUnit : ''}
                                                        <div className="show-for-small mobile-product-price">
                                                            <span className="mobile-product-price__qty">{item.quantity} x </span>
                                                            <span className="amount"><bdi>{this.props.uData && !this.props.uData.creditCardPayment ? item.price.value : item.price.value * (1 + item.price.vat / 100)}&nbsp;<span>{item.price.currency}</span></bdi></span>
                                                        </div>
                                                    </td>
                                                    <td className="product-price">
                                                        <span className="amount"><bdi>{this.props.uData && !this.props.uData.creditCardPayment ? item.price.value : item.price.value * (1 + item.price.vat / 100)}&nbsp;<span >{item.price.currency}</span></bdi></span>
                                                    </td>
                                                    <td className="product-quantity">
                                                        <div className="quantity buttons_added">
                                                            <button className="minus button is-form" onClick={() => {
                                                                if (item.quantity - (item.quantityStep ? item.quantityStep : 1) > 0) {
                                                                    this.props.addToCart(item._id, item.quantity - (item.quantityStep ? item.quantityStep : 1), true, this.get, false, item)

                                                                }
                                                            }}>-</button>
                                                            <input disabled type="number" className="input-text qty text" step={item.quantityStep ? item.quantityStep : 1} min="0" value={item.quantity} inputmode="numeric" onChange={(e) => {
                                                                if (parseInt(e.target.value) > 0 && (!item.quantityStep || item.quantityStep == 1 || e.target.value % item.quantityStep == 0))
                                                                    this.props.addToCart(item._id, parseInt(e.target.value), true, this.get, false, item)
                                                            }} />
                                                            <button className="plus button is-form" onClick={() => {
                                                                this.props.addToCart(item._id, item.quantity + (item.quantityStep ? item.quantityStep : 1), true, this.get, false, item)


                                                            }}>+</button>
                                                        </div>
                                                    </td>
                                                    <td className="product-subtotal">
                                                        <span className="amount"><bdi>{parseFloat(this.props.uData && !this.props.uData.creditCardPayment ? item.price.value * item.quantity : item.price.value * item.quantity * (1 + item.price.vat / 100)).toFixed(2)}&nbsp;<span>{item.price.currency}</span></bdi></span>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                    <tr>
                                        <td colspan="6" className="actions clear">
                                            <div className="continue-shopping pull-left text-left">
                                                <a className="button-continue-shopping button primary is-outline" href="https://venturedesign.se/butik/">

                                                </a>
                                            </div>
                                            <button type="submit" className="button primary mt-0 pull-left small" name="update_cart" disabled="" aria-disabled="true" onClick={() => this.props[0].history.push('/')}>
                                                <span><Isvg src={arrowRightIcon} className="button-arrow-right" /></span>
                                                {'FORTSÄTT HANDLA'.translate(this.props.lang)}
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Col>
                        <Col lg="5">
                            <div className="cart-total">
                                <table cellSpacing="0">
                                    <thead>
                                        <tr>
                                            <th className="product-name" colspan="2" style={{ borderWidth: 3 }}>{'Varukorg totalbelopp'.translate(this.props.lang)}</th>
                                        </tr>
                                    </thead>
                                </table>
                                <table cellSpacing="0">
                                    <tbody>
                                        <tr className="cart-subtotal">
                                            <th>{'Delsumma'.translate(this.props.lang)}</th>
                                            <td data-title="Delsumma"><span className="woocommerce-Price-amount amount"><bdi>{this.props.uData && !this.props.uData.creditCardPayment ? parseFloat(this.state.subtotal).toFixed(2) : parseFloat(this.state.total - this.state.shippingCost).toFixed(2)}&nbsp;<span className="woocommerce-Price-currencySymbol">{this.state.currency}</span></bdi></span></td>
                                        </tr>


                                        {this.state.discountValue ?
                                            <tr className="cart-subtotal">
                                                <th>{'Rabatt'.translate(this.props.lang)}</th>
                                                <td data-title="Rabatt"><span className="woocommerce-Price-amount amount"><bdi>-{this.state.discountValue}%</bdi></span></td>
                                            </tr>

                                            :
                                            null
                                        }

                                        {this.props.uData && !this.props.uData.creditCardPayment ?

                                            <tr class="woocommerce-shipping-totals shipping ">
                                                <td class="shipping__inner" colspan="2">
                                                    <table class="shipping__table ">
                                                        <tbody>

                                                            <tr className="big-row">
                                                                <th style={{ border: 'none' }}>{'Frakt'.translate(this.props.lang)}</th>
                                                                <td style={{ border: 'none' }}>
                                                                    <ul id="shipping_method" class="shipping__list woocommerce-shipping-methods">
                                                                        <li class="shipping__list_item">
                                                                            <label class="shipping__list_label" for="shipping_method_0_flat_rate1">{'Beräknas på fakturan'.translate(this.props.lang)}</label>
                                                                        </li>
                                                                    </ul>
                                                                    <p class="woocommerce-shipping-destination">{'Frakt till'.translate(this.props.lang)} <strong>{this.props.uData && this.props.uData.shippingData ? `${this.props.uData.shippingData.address1} ${this.props.uData.shippingData.address2}, ${this.props.uData.shippingData.postalCode ? this.props.uData.shippingData.postalCode : ''} ${this.props.uData.shippingData.city}` : null}</strong>.</p>

                                                                    <form class="woocommerce-shipping-calculator" action="https://venturedesign.se/cart/" method="post">
                                                                        <a href="#" class="shipping-calculator-button">{'Ändra adress'.translate(this.props.lang)}</a>
                                                                        <section class="shipping-calculator-form" style={{ display: 'none' }}>

                                                                            <p class="form-row form-row-wide" id="calc_shipping_country_field">
                                                                                <select name="calc_shipping_country" id="calc_shipping_country" class="country_to_state country_select" rel="calc_shipping_state">
                                                                                    <option value="default">{'Välj land/region…'.translate(this.props.lang)}</option>
                                                                                </select>
                                                                            </p>

                                                                            <p class="form-row address-field form-row-wide" id="calc_shipping_state_field" style={{ display: 'none' }}>
                                                                            </p>

                                                                            <p class="form-row validate-required address-field form-row-wide" id="calc_shipping_city_field">
                                                                                <input type="text" class="input-text" value="Knäred" placeholder="Ort" name="calc_shipping_city" id="calc_shipping_city" data-placeholder="Ort" />
                                                                            </p>

                                                                            <p class="form-row validate-required address-field form-row-wide" id="calc_shipping_postcode_field">
                                                                                <input type="text" class="input-text" value="31251" placeholder="Postnummer" name="calc_shipping_postcode" id="calc_shipping_postcode" data-placeholder="Postnummer" />
                                                                            </p>

                                                                            <p>
                                                                                <button type="submit" name="calc_shipping" value="1" class="button">{'Uppdatera'.translate(this.props.lang)}</button>
                                                                            </p>
                                                                        </section>
                                                                    </form>

                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                            :
                                            this.state.shippingCost ?
                                                <tr className="tax-total">
                                                    <th>{'Frakt'.translate(this.props.lang)}</th>
                                                    <td data-title="Frakt"><span className="woocommerce-Price-amount amount"><bdi>{this.state.shippingCost}&nbsp;<span className="woocommerce-Price-currencySymbol">{this.state.currency}</span></bdi></span></td>
                                                </tr>

                                                :
                                                null
                                        }



                                        {this.props.uData && !this.props.uData.creditCardPayment ?
                                            <tr className="tax-total">
                                                <th>{'Moms'.translate(this.props.lang)}</th>
                                                <td data-title="Moms"><span className="woocommerce-Price-amount amount"><bdi>{this.state.vat}&nbsp;<span className="woocommerce-Price-currencySymbol">{this.state.currency}</span></bdi></span></td>
                                            </tr>
                                            :
                                            null
                                        }
                                        <tr className="order-total" style={{ borderBottom: '3px solid #ececec' }}>
                                            <th>{'Summa'.translate(this.props.lang)}</th>
                                            <td data-title="Summa"><strong><span className="woocommerce-Price-amount amount"><bdi>{parseFloat(this.state.total).toFixed(2)}&nbsp;<span className="woocommerce-Price-currencySymbol">{this.state.currency}</span></bdi></span></strong> </td>
                                        </tr>
                                        {!this.props.uData || (this.props.uData && this.props.uData.creditCardPayment) ?
                                            <tr className="tax-total" style={{ borderBottom: '3px solid #ececec' }}>
                                                <th>{'Varav moms'.translate(this.props.lang)}</th>
                                                <td data-title="Moms"><span className="woocommerce-Price-amount amount"><bdi>{parseFloat(this.state.total * 0.2).toFixed(2)}&nbsp;<span className="woocommerce-Price-currencySymbol">{this.state.currency}</span></bdi></span> </td>
                                            </tr>
                                            :
                                            null}

                                    </tbody>
                                </table>
                                {
                                    !this.props.uData || (this.props.uData && this.props.uData.creditCardPayment) ?
                                        <div className="cart-info">

                                            <p style={{ fontSize: 12, marginBottom: 20 }}>
                                                {'Ordervärde under 2000:-  / fraktavgift 49:-'.translate(this.props.lang)}
                                                <br />
                                                {'Ordervärde över 2000:- / fraktkostnad 349 :-'.translate(this.props.lang)}
                                            </p>
                                            {/* {
                                                !this.props.uData ?
                                                    <Check label={'Registrera mig för nyhetsbrevet!'.translate(this.props.lang)} value={this.state.registerForNewsletter} onChange={(val) => this.setState({ registerForNewsletter: val })}></Check>
                                                    :
                                                    null
                                            } */}
                                        </div>
                                        :
                                        null
                                }

                                {
                                    (!this.props.uData || (this.props.uData && this.props.uData.creditCardPayment)) && this.state.servicePointRequired ?
                                        <div className="service-points">
                                            <h3>{'Hitta närmaste service point'.translate(this.props.lang)}</h3>
                                            <div className="service-points-search">
                                                <label>{'Postnummer'.translate(this.props.lang)}</label>
                                                <div>

                                                    <input type="text" placeholder={'Ange postnummer'.translate(this.props.lang)} value={this.state.postalCode} onKeyDown={(e) => {
                                                        if (e.key === 'Enter') {
                                                            e.preventDefault();
                                                            this.searchServicePoints();
                                                        }

                                                    }} onChange={(e) => this.setState({ postalCode: e.target.value })}></input>
                                                    <button className="button" onClick={this.searchServicePoints}>{'Search'.translate(this.props.lang)}</button>
                                                </div>
                                                {this.state.servicePointsError ? <p className="error">{this.state.servicePointsError.translate(this.props.lang)}</p> : null}
                                            </div>
                                            <div className="service-points-items">
                                                {
                                                    this.state.servicePoints.map((item, idx) => {
                                                        return (
                                                            <div className={this.state.servicePoint && this.state.servicePoint.id == item.id ? 'active' : ''} onClick={() => {
                                                                this.setState({
                                                                    servicePoint: item
                                                                })
                                                            }}>
                                                                <h6>{item.name}</h6>
                                                                <p><span>{item.street}</span>, {item.postalCode} {item.cityName}</p>
                                                            </div>

                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                        :
                                        null
                                }

                                {this.state.items.length && ((this.props.uData && !this.props.uData.creditCardPayment && this.props.uData.username != 'Inlogg hemsida' && this.props.uData.username != 'Login homepage' && this.props.uData.username != 'H&M Home Store')) ?
                                    <div className="proceed-to-checkout">
                                        <Link to="/checkout/" className="checkout-button button alt wc-forward">{'Fortsätt till kassan'.translate(this.props.lang)}</Link></div>
                                    :
                                    this.state.items.length && (!this.props.uData || (this.props.uData && this.props.uData.creditCardPayment)) && (!this.state.servicePointRequired || (this.state.servicePointRequired && this.state.servicePoint)) ?
                                        <div className="proceed-to-checkout">

                                            <button onClick={this.initPayment} className="checkout-button button alt wc-forward">{'Fortsätt till kassan'.translate(this.props.lang)}</button></div>
                                        :

                                        null}
                            </div>
                            {true || (this.props.uData && !this.props.uData.creditCardPayment) ?
                                <div className="checkout-coupon" >
                                    <div className="coupon">
                                        <h3 className="widget-title"><Isvg src={tagIcon} className="tag-icon" /> {'Rabattkod'.translate(this.props.lang)}</h3>
                                        <input type="text" className="input-text" disabled={this.state.usedDiscountCode} value={this.state.usedDiscountCode ? this.state.usedDiscountCode : this.state.discountCode} onChange={(e) => this.setState({ discountCode: e.target.value })} placeholder="Rabattkod" />
                                        {!(this.state.usedDiscountCode) ?
                                            <button className={`is-form expand ${this.state.discountCode ? 'active' : ''}`} onClick={this.applyDiscountCode}>{'Använd rabattkod'.translate(this.props.lang)}</button>
                                            :
                                            null
                                        }
                                        {this.state.discountError ? <p style={{ fontSize: 12, color: 'red' }}>{this.state.discountError.translate(this.props.lang)}</p> : null}
                                        {
                                            this.state.discountCodeExpired ? <p style={{ fontSize: 12, color: 'red' }}>{'Din kod har upphört att gälla. Kontakta personen du fick koden frän.'.translate(this.props.lang)}</p> : null
                                        }
                                    </div>
                                </div>
                                :
                                null
                            }
                        </Col>


                    </Row>
                </Container>
                {this.state.payment ?
                    <div className="klarna-wrapper">
                        <div className="overlay" onClick={() => this.setState({ payment: null })}></div>
                        <div className='iframe'>
                            <button onClick={() => this.setState({ payment: null })}>&times;</button>
                            <iframe ref={(node) => this.iframe = node} ></iframe>
                        </div>
                    </div>
                    :
                    null
                }

            </div>
        );
    }
}

export default Cart;