import React, { Component } from 'react';
import Page from '../containers/page';
import Isvg from 'react-inlinesvg';
import { Link, Redirect } from 'react-router-dom';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

import {
    Container,
    Row,
    Col,
    UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap';

import Article from '../components/article';
import homeBackgroundImage from '../assets/images/home.jpg';
import filter from '../assets/images/filter.png';
import plusIcon from '../assets/svg/plus.svg';
import closeIcon from '../assets/svg/close.svg';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../helpers/linkHelper';
import ReactPaginate from 'react-paginate';
import { API_ENDPOINT } from '../constants';


var striptags = require('striptags');

class Addload extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData
        };
    }
    get = () => {

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data
                })
            })
        }

        let params = getSearchParams(this.props[0].location.search, {});


        if (this.props.loadDataWithQuery) {
            for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
                this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, params, this.props[0].location.pathname).then((data) => {

                    this.setState({
                        ...data,
                        loading: null
                    }, () => {
                        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                    })

                })
            }


        }


        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
       
     

    }
    htmlDecode(input){
        var e = document.createElement('div');
        e.innerHTML = input;
        return e;
      }
    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }
        this.get();
    }

    render() {

      
        const path= this.state.items && this.state.items.images
        const html = Object.translate(this.state.items,'descriptionlong',this.props.lang)
            console.log(this.state)
   
        console.log(this.props.lang)
        
        return (
            <div>

                <section className="category-seller-page job2-page">

                    <section className="test big-height">
                        <div className="test-content">
                            <div className="special-text">{Object.translate(this.state.items,'specialtext',this.props.lang)}</div>
                            <div className="header-text">{Object.translate(this.state.items,'headertext',this.props.lang)}</div>
                     <div className="minor-text">{Object.translate(this.state.items,'descriptiontext',this.props.lang)}</div> 
                            <a href="mailto:jobb@venturedesign.se" ><button className="button">{Object.translate(this.state.items,'buttontext',this.props.lang)}</button></a>
                        </div>
                        <img src={API_ENDPOINT.replace('testapi', 'api') + path }/>
                       
                    </section>


                    <div className="about-content-row-test">
                        <Container>
                            <Row>
                            {
                            //
                            ReactHtmlParser (html)
                             } 
                            </Row>

                        </Container>
                    </div>


                    <div className="about-footer">
                    </div>

                </section>

            </div>
        );
    }
}

export default Page(Addload);