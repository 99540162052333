import React, { Component } from 'react';
import Page from '../containers/page';
import Isvg from 'react-inlinesvg';
import { Link } from 'react-router-dom';
import {
    Container,
    Row,
    Col,
    Carousel,
    CarouselItem,
    CarouselControl
} from 'reactstrap';
import xIcon from '../assets/svg/x.svg';
import logo from '../assets/svg/logo-light.svg';

import News from '../components/news';
import homeBackgroundImage from '../assets/images/home.jpg';
import f1 from '../assets/images/f1.jpg';
import f2 from '../assets/images/f2.jpg';
import f3 from '../assets/images/f3.jpg';
import f4 from '../assets/images/f4-wide.jpg';
import n1 from '../assets/images/n1.jpg';
import s1 from '../assets/images/s1.jpg';
import s2 from '../assets/images/s2.jpg';
import s3 from '../assets/images/s3.jpg';
import s4 from '../assets/images/s4.jpg';
import s5 from '../assets/images/s5.jpg';
import bannerTest from '../assets/images/banner-example.jpg';
import instagram from '../assets/svg/instagram.svg';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../helpers/linkHelper';
import Article from '../components/article';
import { API_ENDPOINT } from '../constants';

var striptags = require('striptags');

class HomePage extends Component {
    constructor(props) {
        super(props);
        this.handleClickOutside = this.handleClickOutside.bind(this);

        this.state = {
            ...props.initialData,
            products: [],
            sections: [],
            brand: [],
            categories: [],
            activeIndex: 0,
            slides: [],
            instagramPhotos: [],
            instagramIdx: null
        };
    }

    onExiting = () => {
        this.animating = true;
    }

    onExited = () => {
        this.animating = false;
    }

    next = () => {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === this.state.slides.filter((item) => (!item.onlyLoggedUsers || (item.onlyLoggedUsers && this.props.uData))).filter((item) => (!item.hiddenForB2C || (item.hiddenForB2C && ((this.props.uData && !this.props.uData.creditCardPayment))))).length - 1 ? 0 : this.state.activeIndex + 1;
        this.setState({ activeIndex: nextIndex });
    }

    previous = () => {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === 0 ? this.state.slides.filter((item) => (!item.onlyLoggedUsers || (item.onlyLoggedUsers && this.props.uData))).filter((item) => (!item.hiddenForB2C || (item.hiddenForB2C && ((this.props.uData && !this.props.uData.creditCardPayment))))).length - 1 : this.state.activeIndex - 1;
        this.setState({ activeIndex: nextIndex });
    }

    goToIndex = (newIndex) => {
        if (this.animating) return;
        this.setState({ activeIndex: newIndex });
    }



    get = () => {

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data
                })
            })
        }

        let params = getSearchParams(this.props[0].location.search, {});


        if (this.props.loadDataWithQuery) {
            for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
                this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, params, this.props[0].location.pathname).then((data) => {

                    this.setState({
                        ...data,
                        loading: null
                    }, () => {
                        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                    })

                })
            }


        }


        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.get();
    }

    componentDidUpdate(prevProps) {
        if (prevProps[0].location.pathname != this.props[0].location.pathname) {
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = (event) => {
        if (this.searchResultsWrap && this.searchResultsWrap.current && !this.searchResultsWrap.current.contains(event.target)) {
            this.setState({
                showSuggestions: false
            })
        }
    }

    render() {

        let imageSize = typeof window != 'undefined' && window.innerWidth < 768 ? 'xs' : 'lg'

        return (
            <div>

                <section className='home-slider' key={'home-slider'}>

                    <Carousel
                        activeIndex={this.state.activeIndex}
                        next={this.next}
                        previous={this.previous}
                        interval={5000}
                        ride={'carousel'}
                    >
                        {
                            this.state.slides.filter((item) => (!item.onlyLoggedUsers || (item.onlyLoggedUsers && this.props.uData))).filter((item) => (!item.hiddenForB2C || (item.hiddenForB2C && ((this.props.uData && !this.props.uData.creditCardPayment))))).map((item, idx) => {
                                return (
                                    <CarouselItem
                                        key={idx}
                                        onExiting={this.onExiting}
                                        onExited={this.onExited}
                                    >
                                        <div className="slide">
                                            <div className="layer-15"></div>
                                            {/* <img src={API_ENDPOINT + (item.mobileImage && imageSize == 'xs' ? item.mobileImage : item.image)} /> */}
                                            {
                                                imageSize == 'xs' && item.mobileImage && item.mobileImage.indexOf('.mp4') !== -1 ?
                                                    <video loop muted autoPlay playsInline >
                                                        <source src={API_ENDPOINT.replace('testapi', 'api') + item.mobileImage}></source>
                                                    </video>
                                                    :
                                                    item.mobileImage && imageSize == 'xs' ?
                                                        <img src={API_ENDPOINT.replace('testapi', 'api') + (item.mobileImage)} />
                                                        :
                                                        item.image && imageSize != 'xs' && item.image.indexOf('.mp4') !== -1 ?
                                                            <video loop muted autoPlay playsInline >
                                                                <source src={API_ENDPOINT + item.image}></source>
                                                            </video>

                                                            :
                                                            <img src={API_ENDPOINT.replace('testapi', 'api') + (item.image)} />

                                            }
                                            <div className="slide-content">
                                                <h6>{Object.translate(item, 'subtitle', this.props.lang)}</h6>
                                                <h1 dangerouslySetInnerHTML={{ __html: Object.translate(item, 'title', this.props.lang) }}></h1>
                                                {Object.translate(item, 'button.text', this.props.lang) ? <a target={"_blank"} href={Object.translate(item, 'button.link', this.props.lang) + (this.props.lang == 'en' ? '?lang=en' : '')} className="button-clasic">
                                                    <div className="button-inner">
                                                        {Object.translate(item, 'button.text', this.props.lang)}
                                                    </div>
                                                    <div className="button-outer">
                                                        {Object.translate(item, 'button.text', this.props.lang)}
                                                    </div>
                                                </a>
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>

                                    </CarouselItem>

                                )
                            })
                        }
                        <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
                        <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
                    </Carousel>
                </section>
                <div className="featured-box-3">
                    <Container fluid>
                        <Row>
                            <Col lg={{ size: 12, }}>
                                <Row>
                                    {
                                        this.props.rootCategories.filter((item) => item?.vind?.featuredOnHomePage).map((item, idx) => {
                                            if (idx < 3)
                                                return (
                                                    <Col lg="4">
                                                        <Link to={Object.translate(item, 'breadcrumb', this.props.lang)}>
                                                            <div className="box">
                                                                <img src={API_ENDPOINT.replace('testapi', 'api') + Object.get(item, `vind.homeImage.${imageSize}[0]`)} />
                                                                <img src={API_ENDPOINT.replace('testapi', 'api') + Object.get(item, `vind.homeImage.${imageSize}[1]`)} />

                                                                <div>
                                                                    <h3>{Object.translate(item, 'name', this.props.lang)}</h3>
                                                                    <h6>{'INSPIRERAS HÄR'.translate(this.props.lang)}</h6>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </Col>

                                                )
                                        })
                                    }

                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <div className="news">
                    <Container fluid>
                        <Row>
                            <Col lg="12">
                                <div className="news-content">
                                    <h2>{'NYHETER'.translate(this.props.lang)}</h2>
                                </div>
                            </Col>
                        </Row>

                        <Row className="justify-center">
                            <Col lg="12">
                                <Row>
                                    {
                                        this.state.products.map((item, idx) => {
                                            // if (typeof window !== 'undefined' && String(localStorage.getItem('guestToken')).indexOf('retrack-') === -1 ) { 
                                            //     if (!this.props.uData && item.price && this.props[0].location.pathname.indexOf('/sale') == -1){
                                            //         item.price = null;
                                            //     }
                                            // }
                                            if (idx >= 4) { return null }

                                            return (
                                                <Col lg="3" key={idx}>
                                                    <Article wishlistDict={this.props.wishlistDict} addToWishlist={this.props.addToWishlist} pathname={this.props[0].location.pathname} uData={this.props.uData} lang={this.props.lang} {...item} {...item.vind} />
                                                </Col>

                                            )
                                        })
                                    }


                                </Row>
                            </Col>

                        </Row>
                        {/* <div className="center-button">
                            <Link to='/vind-collection' >
                                <button className="button-corner">
                                    {'VISA ALLA'.translate(this.props.lang)}
                                </button>
                            </Link>
                            </div>
 */}
                    </Container>
                </div>


                <div className="featured-box-3">
                    <Container fluid>
                        <Row>
                            <Col lg={{ size: 12, }}>
                                <Row>
                                    {
                                        this.props.rootCategories.filter((item) => item?.vind?.featuredOnHomePage).map((item, idx) => {
                                            if (idx >= 3 && idx < 6)
                                                return (
                                                    <Col lg="4">
                                                        <Link to={Object.translate(item, 'breadcrumb', this.props.lang)}>
                                                            <div className="box">
                                                                <img src={API_ENDPOINT.replace('testapi', 'api') + Object.get(item, `vind.homeImage.${imageSize}[0]`)} />
                                                                <img src={API_ENDPOINT.replace('testapi', 'api') + Object.get(item, `vind.homeImage.${imageSize}[1]`)} />

                                                                <div>
                                                                    <h3>{Object.translate(item, 'name', this.props.lang)}</h3>
                                                                    <h6>{'INSPIRERAS HÄR'.translate(this.props.lang)}</h6>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </Col>

                                                )
                                        })
                                    }

                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>


                <div className="banner">
                    <Container fluid>
                        <Row>
                            <Col lg="12">
                                <div className="banner-content">
                                    <img src={API_ENDPOINT.replace('testapi', 'api') + Object.get(this.state, 'vind.section[0].image')} className="bg-image" />
                                    <div>
                                        <h3 dangerouslySetInnerHTML={{ __html: Object.translate(this.state, 'vind.section[0].title', this.props.lang) }}></h3>
                                        <h5>{Object.translate(this.state, 'vind.section[0].subtitle', this.props.lang)}</h5>
                                        <a target="_blank" href={Object.translate(this.state, 'vind.section[0].button.link', this.props.lang)}>
                                            <button className="button-corner">
                                                {Object.translate(this.state, 'vind.section[0].button.text', this.props.lang)}
                                            </button>
                                        </a>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>



                <div className="social-media">
                    <Container fluid>
                        <Row>
                            <Col lg="12">
                                <div className="social-media-head">
                                    <a href="https://www.instagram.com/vindcollections/" target="_blank">@vindcollections</a>
                                    <h5>{'Följ oss på instagram'.translate(this.props.lang)}  <span><Isvg src={instagram} className="social-icon" /></span></h5>

                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={{ size: 10, offset: 1 }}>
                                <Row className="social-media-row" style={{ paddingTop: 50 }}>
                                    {
                                        this.state.instagramPhotos.map((item, idx) => {
                                            if (idx < 4)
                                                return (
                                                    <Col lg="3" xs="12">
                                                        <button onClick={() => this.setState({ instagramIdx: idx })}>
                                                            {item.media_url && item.media_url.indexOf('.mp4') !== -1 ?
                                                                <video autoPlay loop muted playsInline>
                                                                    <source src={item.media_url} />
                                                                </video>

                                                                :
                                                                <img src={item.media_url} />
                                                            }
                                                        </button>
                                                    </Col>

                                                )
                                        })
                                    }
                                </Row>

                            </Col>
                        </Row>

                    </Container>
                </div>

                {
                    this.state.instagramIdx !== null ?
                        <div className="instagram-wrapper">
                            <div className="instagram-overlay" onClick={() => this.setState({ instagramIdx: null })}></div>
                            <div className="instagram-modal">

                                <div className='instagram-media'>
                                    <a href={this.state.instagramPhotos[this.state.instagramIdx].permalink} target="_blank">
                                        {this.state.instagramPhotos[this.state.instagramIdx].media_url && this.state.instagramPhotos[this.state.instagramIdx].media_url.indexOf('.mp4') !== -1 ?
                                            <video autoPlay loop muted playsInline>
                                                <source src={this.state.instagramPhotos[this.state.instagramIdx].media_url} />
                                            </video>



                                            :
                                            <img src={this.state.instagramPhotos[this.state.instagramIdx].media_url} />

                                        }
                                    </a>
                                </div>

                                <div>
                                    <div className="instagram-header">

                                        <a href="https://www.instagram.com/vindcollections"> <div className="logo"><Isvg src={logo} /></div></a>
                                        <div className="username">
                                            <a href="https://www.instagram.com/vindcollections">@vindcollections</a>
                                            <br />
                                            <a href="https://www.instagram.com/vindcollections">VIND COLLECTIONS</a>
                                        </div>

                                        <button onClick={() => this.setState({ instagramIdx: null })}><Isvg src={xIcon} /></button>
                                    </div>
                                    <div className="instagram-content">
                                        <div className="controls">
                                            <button className="prev" onClick={() => {this.setState({instagramIdx: this.state.instagramIdx-1 < 0 ? this.state.instagramPhotos.length -1 :this.state.instagramIdx-1 })}}></button>
                                            <button className="next" onClick={() => {this.setState({instagramIdx: this.state.instagramIdx+1 >=  this.state.instagramPhotos.length ? 0 :this.state.instagramIdx+1 })}}></button>
                                        </div>
                                        <p dangerouslySetInnerHTML={{ __html: '<a href="https://www.instagram.com/vindcollections">vindcollections</a>' + this.state.instagramPhotos[this.state.instagramIdx].caption.replace(/\n/g, '<br/>') }}></p>
                                    </div>
                                    <div className="instagram-footer">
                                        <a href="https://www.instagram.com/vindcollections">  <h5>{'Följ oss på instagram'.translate(this.props.lang)}  <span><Isvg src={instagram} className="social-icon" /></span></h5></a>

                                    </div>
                                </div>
                            </div>

                        </div>
                        :

                        null
                }

            </div>
        );
    }
}

export default Page(HomePage);