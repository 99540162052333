import React, { Component } from 'react';
import Page from '../containers/page';
import Isvg from 'react-inlinesvg';
import { Link, Redirect } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';

import {
    Container,
    Row,
    Col,
    UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap';
import Gototext from '../components/forms/fields/number';
import Gotobutton from '../components/forms/fields/button';
import Article from '../components/article';
import homeBackgroundImage from '../assets/images/home.jpg';
import filter from '../assets/images/filter.png';
import plusIcon from '../assets/svg/plus.svg';
import closeIcon from '../assets/svg/close.svg';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../helpers/linkHelper';
import ReactPaginate from 'react-paginate';
import { API_ENDPOINT } from '../constants';
import sale from '../assets/images/sale.jpg';
import logoLight from '../assets/svg/logo-light.svg';
import logoDark from '../assets/svg/logo-dark.svg';
import ArticleDetail from '../components/articleDetail';
var striptags = require('striptags');

class ArticlePage extends Component {
    constructor(props) {
        super(props);
        let page = getSearchParams(this.props[0].location.search, {});
        !page.page ? page.page = 1 : page.page = page.page;
        this.state = {
            ...props.initialData,
            products: [],
            filters: {},
            categoryChain: [],
            pageSize: 24,
            showFilters: false,
            gotovalue: page.page,
            gotoindex: parseInt(page.page),
            login_check: null,
            _page: 1,
            pages: [],
            loadingProducts: true,
            _loading: true,
            categoryIndex: 0
        };
        this.handlechage = this.handlechage.bind(this);



    }
    handlechage(event) {
        if (event.target.value <= (this.state.total / 24 + 1)) {
            const numberpage = event.target.value - 1;
            this.setState({ gotovalue: numberpage, gotoindex: event.target.value });
        }
    }


    get = () => {
        if (this.props[0].location.pathname.indexOf('/products') === 0 && !this.state.articleBreadcrumb) {
            return;
        }


        this.setState({
            _loading: true
        }, () => {
            for (let i = 0; i < this.props.loadData.length; i++) {
                this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                    this.setState({
                        ...data
                    })
                })
            }

            let params = getSearchParams(this.props[0].location.search, {});


            if (this.props.loadDataWithQuery) {
                for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
                    this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, params, this.props[0].location.pathname.indexOf('/products') === 0 ? this.state.articleBreadcrumb : this.props[0].location.pathname).then((data) => {

                        let stateObj = {
                            ...data,

                        }

                        let isProductAPI = false;
                        if (data && data.products) {
                            console.log('----', data)
                            isProductAPI = true;
                            let page = data.__page;
                            stateObj.pages = this.state.pages;
                            stateObj.pages[page] = data.products;
                            stateObj._loading = null;
                        }

                        this.setState(stateObj, () => {
                            this.props.setCacheState({ ...this.state });
                            this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                            if (isProductAPI) {
                                setTimeout(() => {
                                    this.setState({
                                        loadingProducts: null
                                    })
                                }, 2000)
                            }
                        })

                    })
                }


            }


            this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        })


    }

    componentDidMount() {
        if (typeof window !== 'undefined') {
            window.scrollTo(0, 0);
            let params = {};
            let s = false;
            params = getSearchParams(this.props[0].location.search, { page: 0 });

            if (params.page && params.page != 0 && !this.props.cacheState) {

                this.props[0].history.push(this.props[0].location.pathname + generateSearchLink(this.props[0].location.search, { page: 0 }, 'page', 0, false));
            }


            if (params) {
                if (params.page) {
                    this.setState({
                        _page: Number(params.page) + 1
                    })
                }

                let check = Object.keys(params).filter(item => item.indexOf('attrs') !== -1).length;
                this.setState({
                    showFilters: check
                })
            }

        }

        this.get();

        if (this.props[0].history.action == 'POP' && this.props.cacheState) {
            this.setState({
                ...this.props.cacheState
            })
        }

    }


    componentDidUpdate(prevProps, prevState) {


        let params = {};
        params = getSearchParams(this.props[0].location.search, { page: 0 });

        if (!prevState.category && this.state.category && this.state.category.attributes && this.state.category.attributes.length) {
            this.state.category.attributes.map((item, idx) => {
                let filters = this.state.filters;
                params["attrs." + item.key] ? filters[item.key] = true : filters[item.key] = false;
            });

        }


        if (prevProps[0].location.pathname != this.props[0].location.pathname && (this.props[0].location.pathname.indexOf('/products') === -1 && prevProps[0].location.pathname.indexOf('/products') === -1)) {
            this.setState({
                // category: null,
                showFilters: false,
                products: [],
                total: 0,
                categoryIndex: 0
            }, () => {
                this.setState({ showFilters: false })
                this.get();
            })
        }

        if (prevProps[0].location.search != this.props[0].location.search) {
            let params = getSearchParams(this.props[0].location.search, { page: 0 });
            let prevParams = getSearchParams(prevProps[0].location.search, { page: 0 });

            if (params.page == 0) {
                this.setState({
                    products: [],
                    pages: [],
                    total: 0
                }, () => {
                    this.get();
                })

            } else {
                this.get();
            }
        }

    }

    render() {


        console.log(this.state.pages);
        let imageSize = typeof window != 'undefined' && window.innerWidth < 768 ? 'xs' : 'lg'
        let params = getSearchParams(this.props[0].location.search, { page: 0 });

        let categoryImage = null;
        if (this.state.categoryChain) {
            for (let i = this.state.categoryChain.length; i >= 0; i--) {
                if (this.state.categoryChain[i] && this.state.categoryChain[i].vind && this.state.categoryChain[i].vind.image && this.state.categoryChain[i].vind.image[imageSize]) {
                    categoryImage = this.state.categoryChain[i].vind.image[imageSize];
                    break;

                }
            }
        }

        let maxWidth = 0;
        let width = typeof window !== 'undefined' ? window.innerWidth - 40 - 252 - 64 : 0;

        if (this.categoriesScroll) {
            let sum = 0;

            for (let i = 0; i < this.categoriesScroll.children.length; i++) {
                if (sum + this.categoriesScroll.children[i].getBoundingClientRect().width >= width) {
                    maxWidth = sum;
                    break;
                }
                sum += this.categoriesScroll.children[i].getBoundingClientRect().width;

            }
        }
        if (maxWidth > 1200) {
            maxWidth = 1200;
        }

        return (
            <div>
                {this.props[0].location.pathname.indexOf('/search') == -1 && this.state.categoryChain && this.state.categoryChain.length ?

                    <section className="test">
                        <img src={API_ENDPOINT.replace('testapi', 'api') + (this.state.categoryChain.length ? categoryImage : null)} />
                        {this.props[0].location.pathname.indexOf('campaign') == -1 && this.props[0].location.pathname.indexOf('/sale') == -1 ? <div className="test-content">
                            <h1>{this.state.categoryChain.length ? Object.translate(this.state.categoryChain[this.state.categoryChain.length - 1], 'name', this.props.lang) : null}</h1>
                            <h6 dangerouslySetInnerHTML={{ __html: this.state.categoryChain.length ? striptags(Object.translate(this.state.categoryChain[this.state.categoryChain.length - 1], 'vind.description', this.props.lang)) : null }}></h6>

                        </div>
                            :
                            null
                        }
                    </section>
                    :
                    null
                }
        { this.props[0].location.pathname.indexOf('/search') === -1 && this.props[0].location.pathname.indexOf('/nyheter') === -1  && (this.state.categoryChain && this.state.categoryChain[this.state.categoryChain.length - 1] && ['6336dc6ddbeb3b172ef0d8ce', '6336dc6ddbeb3b172ef0d8cc', '6336dc6ddbeb3b172ef0d8eb', '6336dc6ddbeb3b172ef0d8c5'].indexOf(this.state.categoryChain[this.state.categoryChain.length - 1]._id) == -1) ?
                <div className={`filters ${this.state.categoryChain.length == 1 && (this.state.categoryChain && this.state.categoryChain[this.state.categoryChain.length - 1] && this.state.categoryChain[this.state.categoryChain.length - 1].parent == null) ? 'filters-1' : ''}`}>
                    <Container fluid>
                        <div className={`filters-content`}>
                            <div className="filters-left">
                                <div className={`subcategories`}>
                                    {
                                        this.state.category && this.state.category.children && (this.state.category.children.length > 1 || (this.state.category && this.state.category.children.length == 1 && !this.state.category.siblings) ) ?
                                            this.state.category.children.map((item, idx) => {
                                                return (
                                                    <Link key={idx} to={Object.translate(item, 'breadcrumb', this.props.lang)}><button className='category-button'>{Object.translate(item, 'name', this.props.lang)}</button>
                                                        {item.vind && item.vind.categoryImage && item.vind.categoryImage.lg ?
                                                            <img src={API_ENDPOINT + item.vind.categoryImage.lg} />
                                                            :
                                                            null
                                                        }
                                                    </Link>
                                                )
                                            })
                                            :
                                            null
                                    }
                                    {
                                        this.state.category && this.state.category.children.length <= 1 && this.state.category.siblings ?
                                            this.state.category.siblings.map((item, idx) => {
                                                return (
                                                    <Link key={idx} to={Object.translate(item, 'breadcrumb', this.props.lang)}><button className='category-button'>{Object.translate(item, 'name', this.props.lang)}</button>
                                                        {item.vind && item.vind.categoryImage && item.vind.categoryImage.lg ?
                                                            <img src={API_ENDPOINT + item.vind.categoryImage.lg} />
                                                            :
                                                            null
                                                        }
                                                    </Link>

                                                )
                                            })
                                            :
                                            null
                                    }


                                </div>

                            </div>
                        </div>
                    </Container>
                </div>
                :
                null
                                }

                <section className="articles">
                    <Container fluid>
                        <Row>
                            <Col lg="12">
                                { this.state.categoryChain.length && Object.translate(this.state.categoryChain[this.state.categoryChain.length - 1], 'vindTitle', this.props.lang) ?
                                <h2 className="category-title">{this.state.categoryChain.length ? Object.translate(this.state.categoryChain[this.state.categoryChain.length - 1], 'vindTitle', this.props.lang) : null}</h2>

                                :

                                <h2 className="category-title">{'ALLA'.translate(this.props.lang)} {this.state.categoryChain.length ? Object.translate(this.state.categoryChain[this.state.categoryChain.length - 1], 'name', this.props.lang) : null}</h2>
                            }
                            </Col>
                            <Col lg={12}>
                                <Row>
                                    {
                                        this.state.pages.map((page, pidx) => {
                                            if (page && page.length && pidx <= params.page) {
                                                return page.map((item, idx) => {
                                                    // if (typeof window !== 'undefined' && String(localStorage.getItem('guestToken')).indexOf('retrack-') === -1) {
                                                    //     if (!this.props.uData && item.price && this.props[0].location.pathname.indexOf('/sale') == -1) {
                                                    //         item.price = null;
                                                    //     }
                                                    // }
                                                    return (
                                                        <Col lg="3" key={`${pidx}${idx}${item.sku}`}>
                                                            <Article wishlistDict={this.props.wishlistDict} addToWishlist={this.props.addToWishlist} pathname={this.props[0].location.pathname} uData={this.props.uData} lang={this.props.lang} {...item} {...item.vind} />
                                                        </Col>

                                                    )

                                                })
                                            }
                                        })
                                    }
                                    <Col lg="12">

                                        {!this.state.loadingProducts && this.state.total > 0 && (Number(params.page) || 0) + 1 < Math.ceil(this.state.total / this.state.pageSize) ?
                                            <Waypoint onEnter={() => {

                                                this.setState({
                                                    loadingProducts: true
                                                }, () => {
                                                    let currPage = params.page || 0;
                                                    currPage++;
                                                    if (currPage > this.state.total / this.state.pageSize) {
                                                        currPage = Math.ceil(this.state.total / this.state.pageSize);
                                                    }
                                                    this.props[0].history.push(this.props[0].location.pathname + generateSearchLink(this.props[0].location.search, { page: 0 }, 'page', currPage, false));

                                                })
                                            }} />
                                            :
                                            null
                                        }
                                        <div className="spinner-wrapper">
                                            {
                                                this.state.loadingProducts ?

                                                    <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                                                    :
                                                    null}
                                        </div>

                                    </Col>
                                    <button className="scroll-top" onClick={() => window.scrollTo(0, 0)}>
                                        {'TOPP'.translate(this.props.lang)}
                                    </button>


                                    {
                                        this.state.total == 0 && this.props[0].location.pathname.indexOf('search') == -1 && Object.keys(params).filter(item => item.indexOf('attrs') !== -1).length == 0 && !this.state._loading ?
                                            <Col lg="12">
                                                <h3 className='coming-soon-title'>{'COMING SOON'.translate(this.props.lang)}</h3>
                                            </Col>
                                            :
                                            null
                                    }
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </section>

                {
                    this.props[0].location.pathname.indexOf('/sale') !== -1 && !this.state.saleModalClosed ?
                        <div className="sale-modal">
                            <div className="sale-modal-overlay" onClick={() => this.setState({ saleModalClosed: true })}>

                            </div>
                            <div className="sale-modal-content">
                                <div className="sale-modal-header">
                                    <Isvg src={logoDark} />
                                    <button onClick={() => this.setState({ saleModalClosed: true })}>&times;</button>
                                </div>
                                <img src={sale} />
                                <h3>{'VÄLKOMMEN TILL VENTURE DESIGNS SALE!'.translate(this.props.lang)}</h3>
                                <p>{'Tack för att du besöker vår sale-sida, nu börjar en brilliant shoppingupplevelse där du kan fynda utvalda produkter till fantastiska priser.'.translate(this.props.lang)}</p>
                                <p><strong>{'Produkterna du finner på den här sidan är utvalda produkter. Produkter utan priser på övriga sidor är ej tillgängliga för köp via vår hemsida utan hänvisas till köp hos någon av våra återförsäljare.'.translate(this.props.lang)}</strong></p>
                                <div className="buttons">
                                    {this.props.lang != 'en' ? <button onClick={() => this.setState({ saleModalClosed: true })}>{'FORTSÄTT HANDLA'.translate(this.props.lang)}</button> : null}
                                    <Link to='/hitta-aterforsaljare'><button className='btn1' onClick={() => this.setState({ saleModalClosed: true })}>{'HITTA VÅRA BUTIKER HÄR'.translate(this.props.lang)}</button> </Link>

                                </div>
                            </div>

                        </div>
                        :

                        null
                }
                {this.props[0].location.pathname.indexOf('/products') !== -1 ?
                    <ArticleDetail {...this.props} get={this.get} setBreadcrumb={
                        (breadcrumb) => {
                            console.log('breadcrumb', breadcrumb)
                            if (this.state.category){
                                return;
                            }
                            
                            this.setState({
                                    articleBreadcrumb: breadcrumb
                                }, () => 
                                this.get()
                            )
                        }
                    }></ArticleDetail>
                    :
                    null
                }
            </div>
        );
    }
}

export default Page(ArticlePage);