import React, { Component } from 'react';
import Isvg from 'react-inlinesvg';
import { Link } from 'react-router-dom';

import {
    Container,
    Row,
    Col,
} from 'reactstrap';


import xIcon from '../assets/svg/x.svg';
import deleteIcon from '../assets/svg/delete.svg';
import boxIcon from '../assets/svg/box.svg';
import arrowRightIcon from '../assets/svg/arrow-right-v2.svg';

import tagIcon from '../assets/svg/tag.svg';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../helpers/linkHelper';
import noImage from '../assets/images/no-image.png';
import { API_ENDPOINT } from '../constants';
import cards from '../assets/images/cards.png';
import cartIcon from '../assets/svg/cart.svg';

var striptags = require('striptags');
function detectMob() {
    const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i
    ];

    return toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
    });
}

class Cart extends Component {
    constructor(props) {
        super(props);

        this.state = {
            items: [],
            servicePoints: []
        };
    }

    get = () => {

    }

    applyDiscountCode = () => {
        if (!this.state.discountCode) {
            return;
        }

        if (!this.state.discountCode.trim()) {
            return;
        }


        fetch(API_ENDPOINT + '/cart/discount/apply', {
            method: 'POST',
            headers: {
'X-Brand': 'Vind',
                'Authorization': localStorage.getItem('authToken') ? `Bearer ${localStorage.getItem('authToken')}` : localStorage.getItem('guestToken') ? `Guest ${localStorage.getItem('guestToken')}` : null,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                discountCode: this.state.discountCode
            })
        }).then(res => res.json()).then((result) => {
            if (result.error) {
                this.setState({ discountError: result.error, discountCode: '' });

            } else {
                this.setState({
                    discountError: ''
                })
                if (!this.props.uData) {
                    this.get();
                } else {
                    this.props.verifyUser(this.get);
                }
            }
        })
    }


    componentDidMount() {
        this.get();

        window.addEventListener('message', this.messageHandler);
    }


    messageHandler = (e) => {
        console.log(e.data);

        if (e && e.data && e.data.indexOf && e.data.indexOf('transaction') !== -1) {
            this.props[0].history.push(`/checkout?transaction=${e.data.replace('transaction=', '')}`)
        }
    }


    searchServicePoints = () => {
        fetch(API_ENDPOINT + '/cart/service-points', {
            method: 'POST',
            headers: {
'X-Brand': 'Vind',
                'Authorization': localStorage.getItem('authToken') ? `Bearer ${localStorage.getItem('authToken')}` : localStorage.getItem('guestToken') ? `Guest ${localStorage.getItem('guestToken')}` : null,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                postalCode: this.state.postalCode
            })
        }).then(res => res.json()).then((result) => {
            if (result.error) {
                this.setState({
                    servicePoints: result.items,
                    servicePointsError: result.error
                })

                return;
            }

            if (result.items) {
                this.setState({
                    servicePoints: result.items,
                    servicePointsError: ''
                })
            }
        })

    }


    initPayment = () => {
        this.setState({
            payment: true
        }, () => {
            fetch(API_ENDPOINT + '/cart/init-payment', {
                method: 'POST',
                headers: {
'X-Brand': 'Vind',
                    'Authorization': localStorage.getItem('authToken') ? `Bearer ${localStorage.getItem('authToken')}` : localStorage.getItem('guestToken') ? `Guest ${localStorage.getItem('guestToken')}` : null,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    billingData: this.state.billingData,
                    shippingData: this.state.shippingData,
                    deliveryDate: this.state.deliveryDate,
                    orderReference: this.state.orderReference,
                    deliverToDifferentAddress: this.state.deliverToDifferentAddress,
                    partialDelivery: this.state.partialDelivery,
                    freightMarks: this.state.freightMarks,
                    servicePoint: this.state.servicePoint,
                    registerForNewsletter: this.state.registerForNewsletter
                })
            }).then(res => res.json()).then((result) => {
                console.log(result)

                //if (detectMob()){
                window.location.href = API_ENDPOINT + '/cart/payment-gateway/' + result.order_id;

                /*}else{
                    this.iframe.src = `data:text/html;charset=utf-8,` + escape(result.html_snippet)

                }*/


            })
        })

    }


    render() {
        if (!this.props.wishlist)
            return null;

        return (
            <div className="cart-modal-wrapper">
                <div className="overlay" onClick={() => this.props.close()}></div>
                <div className="cart-modal">
                    <div className="head">
                        <h2>{'MINA FAVORITER'.translate(this.props.lang)}</h2>
                        <button onClick={() => this.props.close()}><Isvg src={xIcon} /></button>
                        
                    </div>
                    <div className="head-spacer">
                    <hr></hr>
                    </div>
                    <div className="scroll">
                    <div className="table">
                        {
                            this.props.wishlist?.length ?
                                this.props.wishlist.map((item, idx) => {
                                    if (item.vind) {
                                        item = {...item, ...item.vind};
                                    }

                                    return (
                                        <div>
                                                                                        <Link to={`/products/${Object.translate(item, 'alias', this.props.lang)}`}>

                                            <img src={item.thumbnailMood ?
                                                API_ENDPOINT + item.thumbnailMood.thumbnail : item.thumbnailExposed ?
                                                    API_ENDPOINT + item.thumbnailExposed.thumbnail : noImage} />
                                                    </Link>
                                            <div>
                                                <div className="horizontal">
                                                <Link to={`/products/${Object.translate(item, 'alias', this.props.lang)}`}>

                                                    <div>
                                                        <h3>{item.productFamily}</h3>
                                                        <h6>{Object.translate(item, 'name', this.props.lang)}</h6>
                                                    </div>
                                                    </Link>
                                                    { item.price ? 
                                                    <p className="price">
                                                        {parseFloat(this.props.uData && !this.props.uData.creditCardPayment ? item.price.value  : item.price.value * (1 + item.price.vat / 100)).toFixed(0)}&nbsp;<span>{item.price.currency}</span>
                                                    </p>
                                                    :
                                                    null }
                                                </div>
                                                <p className="prop">{'Färg:'.translate(this.props.lang)} {Object.get(item, 'attributes.main_colour_1') ? typeof Object.get(item, 'attributes.main_colour_1') == 'string' ? Object.get(item, 'attributes.main_colour_1') : Object.get(item, 'attributes.main_colour_1').join(', ') : 'N/A'}</p>
                                                <p className="prop">{'Art.Nr:'.translate(this.props.lang)} {item.sku}</p>
                                                <div className="horizontal" style={{ marginTop: 'auto' }}>
                                                    <div style={{height: 36}}>
                                                    </div>
                                                    <div>
                                                    <button className="remove" onClick={() => {
                                                            this.props.addToCart(item._id, 1, false, null, false, item)
                                                        }}><Isvg src={cartIcon} /></button>

                                                    <button className="remove" onClick={() => {
                                                        this.props.addToWishlist(item._id, true)
                                                    }}><Isvg src={deleteIcon} /></button>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    )
                                })
                                :
                                null
                        }
                    </div>


                    </div>
                </div>
            </div>
        )


    }
}

export default Cart;