import React, { Component } from 'react';
import Page from '../containers/page';
import Isvg from 'react-inlinesvg';
import { Link, Redirect } from 'react-router-dom';

import {
    Container,
    Row,
    Col,
    UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap';
import image from '../assets/images/account-cover.jpg';
import imageMobile from '../assets/images/account-cover.jpg';

import RegisterForm from '../components/forms/registerForm';
import headerImage from '../assets/images/home.jpg';
import { API_ENDPOINT } from '../constants';

var striptags = require('striptags');

class BecomeSeller extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData,
        };
    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

    }

    register = (data) => {
        console.log(data);
        this.setState({
            loading: true
        })
        fetch(API_ENDPOINT + '/register', {
            method: 'POST',
            headers: {
'X-Brand': 'Vind',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then((res) => res.json()).then((result) => {

            if (!result.error) {
                this.setState({
                    done: true,
                    registerError: null
                })
            } else {
                this.setState({
                    registerError: result.error
                })
            }
        })
    }


    render() {
        console.log(this.props)
        return (
            <div>

<section className="test">
                    <img src={typeof window !== 'undefined' && window.innerWidth < 768 ? imageMobile : image} />
                    <div className="test-content">
                        <h1>{'BLI ÅTERFÖRSÄLJARE'.translate(this.props.lang)}</h1>
                    </div>
                </section>

                <Container>
                    <Row style={{ justifyContent: 'center', padding: '30px 0' }}>
                        <Col lg="12">
                        <div className="account-container" style={{textAlign: 'center'}}>
                                    <h3 style={{fontSize: 24, marginBottom: 10}}>{'ANSÖK OM ATT BLI ÅTERFÖRSÄLJARE'.translate(this.props.lang)}</h3>
                                    <p style={{fontSize: 14}}>{'För att kunna handla i vår butik online krävs det att du är en registrerad och godkänd återförsäljare.'.translate(this.props.lang)}</p>
                                    <p style={{fontSize: 14}}>{'Du kan fylla i uppgifterna i formuläret nedan för att ansöka om att bli återförsäljare av våra produkter.'.translate(this.props.lang)}</p>
                                    <p style={{fontSize: 14}}>{'Efter godkänd ansökan får du inloggningsuppgifter av oss och kan då handla i butiken'.translate(this.props.lang)}</p>
</div>
                        </Col>
                        <Col lg="12">
                            <div className="account-container">
                                {/* <h2 style={{ fontSize: '1.6em', color: '#657071', fontWeight: 700, marginBottom: 15 }}>{'Registrera'.translate(this.props.lang)}</h2> */}
                                <RegisterForm  {...this.props} loading={this.state.loading} onSubmit={this.register}/>
                                {this.state.registerError ? <p>{this.state.registerError}</p> : null}
                                {
                                    this.state.done  ?
                                    <p style={{marginTop: 20}}>{'Tack för din ansökan! Vårt försäljningsteam kommer återkoppla till dig inom två arbetsdagar.'.translate(this.props.lang)}</p>
                                    :
                                    null
                                }
                            </div>
                        </Col>
                    </Row>
                </Container>

                <div className="about-footer">
                    </div>

            </div>
        );
    }
}

export default Page(BecomeSeller);