import React, { Component } from 'react';
import Page from '../containers/page';
import {Link} from 'react-router-dom'
import {
    Container,
    Row,
    Col,
    Accordion
} from 'reactstrap';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../helpers/linkHelper';


class RetrackPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData,
        };
    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }
        let params = getSearchParams(this.props[0].location.search, {});
        if (params.click){
            localStorage.removeItem('authToken');
            localStorage.setItem('guestToken', 'retrack-' + params.click);
            window.location.href = params.deeplink;
        }


    }

    render() {
        return (
            <div>
            </div>
        );
    }
}

export default Page(RetrackPage);