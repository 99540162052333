import React, { Component } from 'react';
import Page from '../containers/page';
import Isvg from 'react-inlinesvg';
import { Link, Redirect } from 'react-router-dom';

import {
    Container,
    Row,
    Col,
    UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap';

import StockConfigForm from '../components/forms/stockConfigForm';
import user from '../assets/images/user.png';
import AccountNav from '../components/accountNav';
import { API_ENDPOINT } from '../constants';
import image from '../assets/images/account-cover.jpg';
import imageMobile from '../assets/images/account-cover.jpg';

var striptags = require('striptags');

class AccountStockPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData,
            controlPanel: true,
        };
    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.get();

    }


    get = () => {
        fetch(API_ENDPOINT + '/account/stock-list', {
            method: 'GET',
            headers: {
'X-Brand': 'Vind',
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
                'Content-Type': 'application/json'
            },
        }).then(res => res.json()).then((result) => {
            this.setState({
                stockConfig: result
            })
        })

    }

    submit = (data) => {
        console.log(data)

        let obj = {};

        if (data.email){
            obj.email = {
                to: data.email.to
            }

            if (data.email.format == 'xlsx'){
                obj.email.xlsx = {
                    skus: data.email.skus,
                    filename: data.email.filename,
                    template: data.email.template,
                    schedule: data.email.schedule
                }
            }else if (data.email.format == 'csv'){
                obj.email.csv = {
                    skus: data.email.skus,
                    filename: data.email.filename,
                    template: data.email.template,
                    schedule: data.email.schedule
                }
            }
        }
        if (data.ftp){
            obj.ftp = {
                host: data.ftp.host,
                port: data.ftp.port,
                username: data.ftp.username,
                password: data.ftp.password,
                secure: data.ftp.secure ? true : false,
                secureOptions: data.ftp.secureOptions
            }

            if (data.ftp.format == 'xlsx'){
                obj.ftp.xlsx = {
                    skus: data.ftp.skus,
                    filename: data.ftp.filename,
                    template: data.ftp.template,
                    schedule: data.ftp.schedule
                }
            }else if (data.ftp.format == 'csv'){
                obj.ftp.csv = {
                    skus: data.ftp.skus,
                    filename: data.ftp.filename,
                    template: data.ftp.template,
                    schedule: data.ftp.schedule
                }
            }
        }

        if (data.sftp){
            obj.sftp = {
                host: data.sftp.host,
                port: data.sftp.port,
                username: data.sftp.username,
                password: data.sftp.password,
            }

            if (data.sftp.format == 'xlsx'){
                obj.sftp.xlsx = {
                    skus: data.sftp.skus,
                    filename: data.sftp.filename,
                    template: data.sftp.template,
                    schedule: data.sftp.schedule
                }
            }else if (data.sftp.format == 'csv'){
                obj.sftp.csv = {
                    skus: data.sftp.skus,
                    filename: data.sftp.filename,
                    template: data.sftp.template,
                    schedule: data.sftp.schedule
                }
            }
        }



        fetch(API_ENDPOINT + '/account/stock-list', {
            method: 'POST',
            headers: {
'X-Brand': 'Vind',
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(obj)
        }).then(res => res.json()).then((result) => {
            if (result.error) {
                this.setState({ error: result.error});

            } else {
                //this.props[0].history.push('/account')
            }
        })

    }

    render() {
        return (
            <div className="account-page">
                <section className="test">
                    <img src={typeof window !== 'undefined' && window.innerWidth < 768 ? imageMobile : image} />
                    <div className="test-content">
                        <h1>{'MINA SIDOR'.translate(this.props.lang)}</h1>
                    </div>
                </section>

                <Container>
                    <Row>
                        <Col lg="4">
                            <AccountNav {...this.props} />
                        </Col>
                        <Col lg="8">
                            <div className="account-container">
                                <h3>{'HUR FÅR JAG LAGERSTATUS?'.translate(this.props.lang)}</h3>
                                { this.state.stockConfig ? 
                                    <StockConfigForm lang={this.props.lang}  initialValues={this.state.stockConfig} onSubmit={this.submit}/>
                                    :
                                    null
                                }
                                {
                                    this.state.error ? <p>{this.state.error}</p> : null
                                }

                            </div>
                        </Col>
                    </Row>
                </Container>

            </div>
        );
    }
}

export default Page(AccountStockPage);