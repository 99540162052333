import React, { Component } from 'react';
import Page from '../containers/page';
import Isvg from 'react-inlinesvg';
import { Link, Redirect } from 'react-router-dom';

import {
    Container,
    Row,
    Col,
    UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap';

import AccountInfo from '../components/forms/accountInfo';
import user from '../assets/images/user.png';
import AccountNav from '../components/accountNav';
import image from '../assets/images/account-cover.jpg';
import imageMobile from '../assets/images/account-cover.jpg';

var striptags = require('striptags');

class AccountPageL extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData,
            controlPanel: true,
        };
    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

    }

    render() {
        return (
            <div className="account-page">
                <section className="test">
                    <img src={typeof window !== 'undefined' && window.innerWidth < 768 ? imageMobile : image} />
                    <div className="test-content">
                        <h1>{'MINA SIDOR'.translate(this.props.lang)}</h1>
                    </div>
                </section>

                <Container>
                    <Row>
                        <Col lg="4">
                            <AccountNav {...this.props} />
                        </Col>
                        <Col lg="8">
                            <div className="account-container">
                                <h5>{'MINA ADRESSER'.translate(this.props.lang)}</h5>
                                <div className="address-container">
                                    <div class="address-box">
                                        <header>
                                            <h3>{'Faktureringsadress'.translate(this.props.lang)}</h3>
                                        </header>
                                        <address>
                                            {this.props.uData.billingData.company}<br />{this.props.uData.billingData.address1} {this.props.uData.billingData.address2}<br />{this.props.uData.billingData.postalCode} {this.props.uData.billingData.city}
                                        </address>
                                        <Link to="/account/edit-address/billing" class="edit"><button className='button'>{'ÄNDRA DINA UPPGIFTER'.translate(this.props.lang)}</button></Link>

                                    </div>
                                    <div class="address-box">
                                        <header>
                                            <h3>{'Leveransadress'.translate(this.props.lang)}</h3>
                                        </header>
                                        <address>
                                            {this.props.uData.shippingData.company}<br />{this.props.uData.shippingData.address1} {this.props.uData.shippingData.address2}<br />{this.props.uData.shippingData.postalCode} {this.props.uData.shippingData.city}

                                        </address>

                                        <Link to="/account/edit-address/shipping" class="edit"><button className='button'>{'ÄNDRA DINA UPPGIFTER'.translate(this.props.lang)}</button></Link>

                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>

            </div>
        );
    }
}

export default Page(AccountPageL);