import React, { Component } from 'react';
import Page from '../containers/page';
import Isvg from 'react-inlinesvg';
import { Link, Redirect } from 'react-router-dom';

import {
    Container,
    Row,
    Col,
    UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap';

import AccountInfo from '../components/forms/accountInfo';
import user from '../assets/images/user.png';
import homeBackgroundImage from '../assets/images/about.jpg';
var striptags = require('striptags');
import AccountNav from '../components/accountNav';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../helpers/linkHelper';
import moment from 'moment';

class AboutPage extends Component {
    constructor(props) {
        super(props);
       console.log(props);
        this.state = {
            ...props.initialData,
            adds: []
        };
       
        
    }

    get = () => {

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data
                })
            })
        }

        let params = getSearchParams(this.props[0].location.search, {});


        if (this.props.loadDataWithQuery) {
            for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
                this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, params, this.props[0].location.pathname).then((data) => {

                    this.setState({
                        ...data,
                        loading: null
                    }, () => {
                        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                    })

                })
            }


        }


        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
       
     

    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }
        this.get();
        
       

    }
    handleClick = (e) => {
        e.preventDefault();
        console.log('The link was clicked.');
      };

    render() {
    
       
        return (
          
            <div>
              
                <section className="jobs-page">

                    <section className="test jobs-header">
                        <img src={homeBackgroundImage} />
                        <Container fluid>
                           
                            <Row>
                                <Col lg="4">
                                    <div className="jobs-header-box">
                                        <h6>Vi söker fler stjärnor – </h6>
                                        <h1>JOBBA HOS OSS!</h1>
                                        <p>Nu finns en fantastisk möjlighet att bli en del av oss i teamet på Venture Design i Växjö. Här kommer du få alla möjligheter att få ta dig an en utvecklande och rolig utmaning i början eller mitt i din karriär. Nyfiken? Tveka inte på att höra av dig till oss!</p>
                                        <Link to="/om-oss">Om oss</Link>
                                    </div>
                                </Col>
                            </Row>
                           
                        </Container>

                    </section>

                    <section className="jobs-container">
                        <Container fluid>
                            
                            <Row>
                            {
                                this.state.adds.map((item, idx) => {
                                   
                                    return(
                                        <Col lg={{size: 6, offset: 3}}>
                                        <div className="jobs-content">
                                            <h6>{Object.translate(item,'headertext',this.props.lang)}</h6>
                                            <p>{Object.translate(item,'shorttext',this.props.lang)}</p>
                                            {
                                            item.descriptionlong ? <Link to={`/job2/${item._id}`} className='jobs-content-link'>{`Läs mer här!`.translate(this.props.lang)}</Link> : null
                                            }
                                        </div>
                                    </Col>
                                    )

                                })
                            }
                                
                                
                            
                            </Row>
                        </Container>
                    </section>

                    <a href="mailto:jobb@venturedesign.se" className="jobs-main-button">SKICKA IN ANSÖKAN HÄR!</a>


                    <div className="cv-container">
                        <Container fluid>
                            <Row>
                                <Col lg="12" style={{ textAlign: 'center', marginTop: 25, marginBottom: 35 }}>
                                    <h5>Bifoga CV samt personligt brev i din ansökan.</h5>
                                </Col>
                                
                                <Col lg="12" style={{ textAlign: 'center'}}>
                                    <div className="cv-content">
                                        <h5>Vi fortsätter expandera med högt uppsatta mål i siktet!</h5>
                                        <p>Ingenting ledigt just nu? Tveka inte på att skicka in en spontanansökan till jobb@venturedesign.se</p>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                
                    <div className="about-footer">
                    </div>
                    
                </section>

            </div>
        );
    }
}

export default Page(AboutPage);