import React, { Component } from 'react';
import Page from '../containers/page';
import Isvg from 'react-inlinesvg';
import { Link, Redirect } from 'react-router-dom';

import {
    Container,
    Row,
    Col,
    UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap';

import AccountInfo from '../components/forms/accountInfo';
import user from '../assets/images/user.png';

var striptags = require('striptags');
import AccountNav from '../components/accountNav';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../helpers/linkHelper';
import moment from 'moment';

class OrderDetailPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData,
            items: []
        };
    }
    get = () => {

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data
                })
            })
        }

        let params = getSearchParams(this.props[0].location.search, {});


        if (this.props.loadDataWithQuery) {
            for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
                this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, params, this.props[0].location.pathname).then((data) => {

                    this.setState({
                        ...data,
                        loading: null
                    }, () => {
                        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                    })

                })
            }


        }


        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.get();
    }


    render() {
        return (
            <div className="profile-page">

                <Container fluid>
                    <Row>
                        <Col lg="3">
                            <AccountNav {...this.props} />
                        </Col>
                        {this.state.order ?
                            <Col lg="9">
                                <div className="order-completed-header">
                                    {/* <h6>{'Betalning sker via faktura efter godkand bestallning'.translate(this.props.lang)}</h6> */}
                                    <h2>{'Beställningsdetaljer'.translate(this.props.lang)}</h2>
                                </div>
                                <div className="order-completed-product">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th className="product-name">{'Produkt'.translate(this.props.lang)}</th>
                                                <th className="product-total">{'Suma'.translate(this.props.lang)}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.order.items.map((item, idx) => {
                                                    return (
                                                        <tr className="cart'item">
                                                            <td class="product-name">
                                                                {Object.translate(item, 'name', this.props.lang)} <br />
                                                                <strong class="product-quantity">{'unit:'.translate(this.props.lang)}</strong>
                                                                <br />
                                                                <p>{item.price ? item.price.salesUnit : ''}</p>
                                                            </td>
                                                            <td className="product-total">
                                                                <span className="amount"><bdi>{item.price.value * item.quantity}&nbsp;<span>{item.price.currency}</span></bdi></span>
                                                            </td>
                                                        </tr>

                                                    )
                                                })
                                            }
                                            <tr>
                                                <td>{'Delsumma:'.translate(this.props.lang)}</td>
                                                <td className="number">{this.state.order.subtotal} {this.state.order.currency}</td>
                                            </tr>
                                            <tr>
                                                <td>{'Frakt:'.translate(this.props.lang)}</td>
                                                <td>{'Berkanas pa fakturan'.translate(this.props.lang)}</td>
                                            </tr>
                                            <tr>
                                                <td>{'Moms:'.translate(this.props.lang)}</td>
                                                <td className="number">{this.state.order.vat} {this.state.order.currency}</td>
                                            </tr>
                                            <tr>
                                                <td>{'Betalningsmetod:'.translate(this.props.lang)}</td>
                                                <td>{'Faktura'.translate(this.props.lang)}</td>
                                            </tr>
                                            <tr>
                                                <td>{'Totalt:'.translate(this.props.lang)}</td>
                                                <td className="number">{this.state.order.total} {this.state.order.currency}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="order-completed-content-container">
                                    <ul className="order-details">
                                        <li>{'Asked delivery Date:'.translate(this.props.lang)} <span>{this.state.order.deliveryDate ? this.state.order.deliveryDate : 'As soon as possible'.translate(this.props.lang)}</span></li>
                                        <li>{'Shipping phone:'.translate(this.props.lang)} <span>{this.state.order.shippingData.phone}</span></li>
                                        <li>{'Shipping email:'.translate(this.props.lang)} <span>{this.state.order.shippingData.email}</span></li>
                                        <li>{'Accepts part delivery:'.translate(this.props.lang)} <span>{this.state.order.partialDelivery ? 'Ja'.translate(this.props.lang) : 'Nej'.translate(this.props.lang)}</span></li>
                                        <li>{'Order reference:'.translate(this.props.lang)} <span>{this.state.order.orderReference ? this.state.order.orderReference : '/'}</span></li>
                                    </ul>
                                </div>
                                <div className="address">
                                    <p>{'Följande adresser kommer att användas i kassan.'.translate(this.props.lang)}</p>
                                    <div className="address-container">
                                        <div class="address-box">
                                            <header>
                                                <h3>{'Faktureringsadress'.translate(this.props.lang)}</h3>
                                            </header>
                                            <address>
                                                {this.state.order.billingData.firstName} {this.state.order.billingData.lastName}<br />{this.state.order.billingData.company}<br />{this.state.order.billingData.address1} {this.state.order.billingData.address2}<br />{this.state.order.billingData.postalCode} {this.state.order.billingData.city}
                                            </address>
                                        </div>
                                        <div class="address-box">
                                            <header>
                                                <h3>{'Leveransadress'.translate(this.props.lang)}</h3>
                                            </header>
                                            <address>
                                                {this.state.order.shippingData.firstName} {this.state.order.shippingData.lastName}<br />{this.state.order.shippingData.company}<br />{this.state.order.shippingData.address1} {this.state.order.shippingData.address2}<br />{this.state.order.shippingData.postalCode} {this.state.order.shippingData.city}
                                            </address>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            :
                            null
                        }

                    </Row>
                </Container>

            </div>
        );
    }
}

export default Page(OrderDetailPage);