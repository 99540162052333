import React, { Component } from 'react';
import Page from '../containers/page';
import Isvg from 'react-inlinesvg';
import { Link, Redirect } from 'react-router-dom';

import {
    Container,
    Row,
    Col,
    UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap';

import AccountInfo from '../components/forms/returnGoodsForm';
import user from '../assets/images/user.png';
import AccountNav from '../components/accountNav';
import image from '../assets/images/account-cover.jpg';
import imageMobile from '../assets/images/account-cover.jpg';
import Article from '../components/article';

import { API_ENDPOINT } from '../constants';
var striptags = require('striptags');

class WishlistPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData,
            controlPanel: true,
        };
    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

    }

    submit = (data) => {
        fetch(API_ENDPOINT + '/account/update', {
            method: 'POST',
            headers: {
'X-Brand': 'Vind',
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(res => res.json()).then((result) => {
            if (result.error) {
                this.setState({ error: result.error});

            } else {
                this.props.verifyUser();
            }
        })

    }

    render() {
        return (
            <div className="account-page">
                <section className="test">
                    <img src={typeof window !== 'undefined' && window.innerWidth < 768 ? imageMobile : image} />
                    <div className="test-content">
                        <h1>{'MINA SIDOR'.translate(this.props.lang)}</h1>
                    </div>
                </section>

                <Container >
                    <Row>
                        <Col lg="4">
                            <AccountNav {...this.props} />
                        </Col>
                        <Col lg="8">
                            <div className="account-container">
                                <h5>{'MINA FAVORITER'.translate(this.props.lang)}</h5>
                                <Row>
                                    {
                                        this.props.wishlist.map((item, idx) => {
                                            
                                            return (
                                                <Col lg="4" key={idx}>
                                                    <Article wishlistDict={this.props.wishlistDict} addToWishlist={this.props.addToWishlist}  pathname={this.props[0].location.pathname} uData={this.props.uData} lang={this.props.lang} {...item} {...item.vind} />
                                                </Col>

                                            )

                                        })
                                    }
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>

            </div>
        );
    }
}

export default Page(WishlistPage);