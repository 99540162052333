import React, { Component } from 'react';
import Page from '../containers/page';
import {Link} from 'react-router-dom'
import {
    Container,
    Row,
    Col,
    Accordion
} from 'reactstrap';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../helpers/linkHelper';


class KlarnaConfirmationPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData,
        };
    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }
        let params = getSearchParams(this.props[0].location.search, {});
        if (params.transaction){

            setTimeout(() => {
                this.props[0].history.push(`/checkout?transaction=${params.transaction}`)
            }, 3000);

            if (window.top) {
                    window.top.postMessage('transaction='+params.transaction, '*')
            }
        }


    }

    render() {
        return (
            <div>
            </div>
        );
    }
}

export default Page(KlarnaConfirmationPage);