import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form'
// import { Link, Redirect } from 'react-router-dom';

import Check from './fields/check';
import Text from './fields/text';
import Textarea from './fields/textarea';
import Select from './fields/select';
import DatePicker from './fields/date';
import Images from './fields/images';

import Isvg from 'react-inlinesvg';

import {
    Container,
    Row,
    Col,
} from 'reactstrap';

const required = value => value ? undefined : "Required"
const renderDatePickerField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
}) => (

    <DatePicker
        placeholder={placeholder}
        label={label}
        errorText={touched && error}
        error={touched && error}
        {...input}
    />
)


const renderSelectField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
    children,
    additionalAction,
    scope,
    disabled
}) => (

    <Select
        placeholder={placeholder}
        label={label}
        errorText={touched && error}
        error={touched && error}
        additionalAction={additionalAction}
        scope={scope}
        disabled={disabled}
        {...input}
        children={children}
    />
)


const renderCheckField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },

}) => (

    <Check
        placeholder={placeholder}
        label={label}
        errorText={touched && error}
        error={touched && error}
        // multilang={multilang}
        // lang={lang}

        {...input}
    />
)

const renderTextField = ({
    input,
    label,
    placeholder,
    meta: { touched, error },
    type,
    disabled
}) => (

    <Text
        placeholder={placeholder}
        errorText={touched && error}
        disabled={disabled}
        label={label}
        error={touched && error}
        type={type}
        {...input}
    />
)
const renderImagesField = ({
    input,
    label,
    placeholder,
    meta: { touched, error },
    type,
    disabled
}) => (

    <Images
        placeholder={placeholder}
        errorText={touched && error}
        disabled={disabled}
        label={label}
        error={touched && error}
        type={type}
        {...input}
    />
)


const renderTextareaField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
}) => (

    <Textarea
        placeholder={placeholder}
        label={label}
        errorText={touched && error}
        error={touched && error}
        {...input}
    />
)


class form extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            seePassword: false,
            count: 1
        }
    }



    render() {

        const { handleSubmit, pristine, reset, submitting } = this.props;

        return (
            <form onSubmit={handleSubmit} className="login-form accoutn-info-form">
                <Row>
                    <Col lg="12">
                        <h3>{'GÖR REKLAMATION'.translate(this.props.lang)}</h3>
                        <h3>{'Kära kund'.translate(this.props.lang)}</h3>
                        <h6>{'I formuläret nedan finner du allt vi behöver för att kunna hjälpa dig vidare med din reklamation. Vänligen fyll i formuläret så kommer du inom 24 timmar få svar på din reklamation.'.translate(this.props.lang)}</h6>
                        <h6>{'Tack på förhand och ha en fortsatt fin dag!'.translate(this.props.lang)}</h6>
                    </Col>

                    <Col lg="12">
                        <div className="field-wrap">
                            <Field
                                name="orderNumber"
                                component={renderTextField}
                                label={"*Ordernummer".translate(this.props.lang)}
                                validate={[required]}
                            ></Field>
                        </div>
                    </Col>
                    <Col lg="12">
                        <div className="field-wrap">
                            <Field
                                name="date"
                                component={renderDatePickerField}
                                label={"*När upptäcktes defekten (ange datumet)".translate(this.props.lang)}
                                validate={[required]}
                            ></Field>
                        </div>
                    </Col>

            {
                Array.from(Array(this.state.count).keys()).map((item, idx) => {
                    return (
                        <>
                                            <Col lg="12">
                    <h3>{'REKLAMATION'.translate(this.props.lang)} {idx + 1}</h3>

                    </Col>

                    <Col lg="12">
                        <div className="field-wrap">
                            <Field
                                name={`complaints[${idx}].articleNumber`}
                                component={renderTextField}
                                label={"*Artikelnummer (ange ETT art.nr som din reklamation avser)".translate(this.props.lang)}
                                validate={[required]}
                            ></Field>
                        </div>
                    </Col>
                    <Col lg="12">
                        <div className="field-wrap">
                            <Field
                                name={`complaints[${idx}].batchNumber`}
                                component={renderTextField}
                                label={"Batchnummer (det hittar du på kartongen)".translate(this.props.lang)}
                            ></Field>
                        </div>
                    </Col>
                    <Col lg="12">
                        <div className="field-wrap">
                            <Field
                                name={`complaints[${idx}].type`}
                                component={renderSelectField}
                                label={"*Välj problemet… ".translate(this.props.lang)}
                                validate={[required]}
                            > 
                                <option value="Saknar del">{''.translate(this.props.lang)}</option>
                                <option value="Fick fel artikel">{'Fick fel artikel'.translate(this.props.lang)}</option>
                                <option value="Monteringsproblem">{'Monteringsproblem'.translate(this.props.lang)}</option>
                                <option value="Transportskada">{'Transportskada'.translate(this.props.lang)}</option>
                                <option value="Skadad del">{'Skadad del'.translate(this.props.lang)}</option>

                            </Field>
                        </div>
                    </Col>

                    <Col lg="12">
                        <div className="field-wrap">
                            <Field
                                name={`complaints[${idx}].description`}
                                component={renderTextareaField}
                                label={"*… eller beskriv problemet / defekten (om det saknas i listan ovan)".translate(this.props.lang)}
                                validate={[required]}

                          ></Field>
                        </div>
                    </Col>
                    <Col lg="12">
                        <div className="field-wrap">
                            <Field
                                name={`complaints[${idx}].images`}
                                component={renderImagesField}
                                label={"*Bifoga minst EN bild på skadan (behövs inte om problemet är: “Saknar del”) Ju fler bilder du bifogar desto snabbare går hanteringen av ärendet.".translate(this.props.lang)}
                                validate={[required]}

                           ></Field>
                        </div>
                    </Col>

                        { idx == this.state.count -1 ?
                    <Col lg="12">
                    <h3 className="complaint-header">{'REKLAMATION'.translate(this.props.lang)} {idx + 2} <button type="button" onClick={() => this.setState({count: this.state.count+1})}>{'LÄGG TILL'.translate(this.props.lang)}</button></h3>

                    </Col>
                    :
                    null
                        }

</>
                    )
                })

            }



                    <Col lg="12" className="btn-wrap" style={{ marginTop: 30, display: 'flex', justifyContent: 'flex-end' }}>

                        {this.props.loading ?
                            null
                            :
                            <button className="button">{'SKICKA'.translate(this.props.lang)}</button>
                        }
                    </Col>


                    <Col lg="12" style={{marginTop: 45}}>
                        <p>{'Efter att kundtjänst har fått in all information dem behöver för att kunna hjälpa er i ärendet kommer dem ge er olika lösningsförslag beroende på vad det är för fel/defekt. Du avsäger dig framtida reklamations- och returrätt för det specifika felet om du godkänner ett prisavdrag/kompensation. Om du väljer att returnera varor i alla fall, kommer du inte debiteras för några avgifter eller returfrakt.'.translate(this.props.lang)}</p>
                            <br></br>
                        {/* <p>{'Om din hantering av varan inneburit en värdeminskning av densamma eller vid saknad eller förstörd originalkartong kan ett avdrag för värdeminskning göras, avdraget bedöms från fall till fall.'.translate(this.props.lang)}</p>
                        <br></br> */}

                        <p>{'Återbetalning görs så snart vi har mottagit returen och behandlat den, normalt tar detta ca 2-3 veckor från det datum du skickade returen. Återbetalning sker via samma betalningssätt som du använde vid beställningen. Vid återbetalning via bankkort tar det några dagar extra innan du ser pengarna på ditt konto p.g.a.bankens hanteringstid.'.translate(this.props.lang)}</p>
                    </Col>

                </Row>


            </form>

        )
    }
}

export default reduxForm({
    form: 'accountForm'  // a unique identifier for this form
})(form)