
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Isvg from 'react-inlinesvg';



class Images extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }


    getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.onload = (event) => {
                resolve(event.target.result);
            };

            fileReader.readAsDataURL(file);
        });
    }

    selectFiles = async (e) => {
        let input = e.target;
        console.log(input.files);

        let value = this.props.value ? this.props.value : [];

        for(let i=0;i<input.files.length;i++){
            let obj = {
                base64: await this.getBase64(input.files[i]),
                name: input.files[i].name
            }

            value.push(obj);
        }


        this.props.onChange(value);
    }



    render() {
        return (
                <div className="images-field">
                { this.props.label ? <label className={this.props.error ? "required" : ""}>{this.props.label}</label> : null } 
                <div>
                    <div className="file-wrap">
                        <div className="list">
                            {
                                this.props.value ?
                                this.props.value.map((item) => item.name).join(',')
                                :
                                null
                            }
                        </div>
                        <div className="button">
                            <input multiple onChange={this.selectFiles} type="file"></input>
                            <button type="button">{'LADDA UPP'.translate(this.props.lang)}</button>
                        </div>
                    </div>
                    <div className="preview">
                        {
                            this.props.value ? this.props.value.map((item, idx) => {
                                return (
                                    <div><img src={item.base64} /> {item.name} <button type="button" onClick={() => {
                                        let value = this.props.value;
                                        value.splice(idx, 1);
                                        this.props.onChange(value);
                                        this.forceUpdate();
                                    }}>&times;</button></div>
                                )
                            })
                            :
                            null
                        }
                    </div>
                </div>
                </div>
        );
    }
}

export default Images;