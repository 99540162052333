

import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import Routes from './routes'
import { Provider } from 'react-redux'
import { createStore, combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { withRouter } from 'react-router-dom';
import DocumentMeta from 'react-document-meta';
import ReactGA from 'react-ga';
import socketIO from 'socket.io-client';

import moment from 'moment';
import { API_ENDPOINT } from './constants';
import { v4 as uuidv4 } from 'uuid';

const rootReducer = combineReducers({
  form: formReducer
});

const store = createStore(rootReducer)

function generateAlias(str) {
  str = str.toLowerCase();
  str = str.replace(/ä/g, 'a');
  str = str.replace(/ö/g, 'o');
  str = str.replace(/ü/g, 'u');
  str = str.replace(/ß/g, 'b');

  str = str.replace(/[^a-zA-Z0-9]/gi, '-').toLowerCase()
  str = str.replace(/-+/g, '-');

  return str;
}

if (String.prototype.generateAlias == null) {
  String.prototype.generateAlias = function () {
    return generateAlias(this);
  }
}

Object.translate = function (o, s, lang) {
  try {
    if (!o) {
      return '';
    }

    s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    s = s.replace(/^\./, '');           // strip a leading dot
    var a = s.split('.');
    for (var i = 0, n = a.length; i < n; ++i) {
      var k = a[i];
      if (k in o) {
        o = o[k];
      } else {
        return;
      }
    }
    return o[lang];
  } catch (e) {
    return '';
  }
}


Object.get = function (o, s) {
  if (!o) {
    return null;
  }

  s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  s = s.replace(/^\./, '');           // strip a leading dot
  var a = s.split('.');
  for (var i = 0, n = a.length; i < n; ++i) {
    var k = a[i];
    if (k in o) {
      o = o[k];
    } else {
      return;
    }
  }
  return o;
}



if (String.prototype.translate == null) {
  String.prototype.translate = function (lang) {
    let langs = typeof window !== 'undefined' ? window.langs : null;

    if (!langs) {
      return this;
    }

    //console.log(Object.keys(langs), lang)
    if (langs[lang] && langs[lang][this]) {
      return langs[lang][this];
    }
    else return this;
  }
}


function parseJSON(response) {
  return response.json().then(json => {
    return {
      result: json,
      status: response.status
    }
  })
}



class App extends Component {
  constructor(props) {
    super(props);
    this.googleMapsCallback = this.googleMapsCallback.bind(this);
    this.translate = this.translate.bind(this);
    this.setLang = this.setLang.bind(this);
    this.setLightMode = this.setLightMode.bind(this);


    let lang = 'sv';
    if (typeof window !== 'undefined') {

      window.googleMapsCallback = this.googleMapsCallback;

      if (props.location.pathname.indexOf('/en') !== -1) {
        lang = 'en';
      }

      if (props.location.search.indexOf('lang=en') !== -1){
        lang = 'en'
      }


    } else {
      lang = this.props.lang;
    }
    this.state = {
      _googleMapsLoaded: false,
      lang: lang,
      rootCategories: [],
      wishlist: [],
      wishlistDict: {},
      cookies: true,
      ...props.appInitialData

    };

  }

  setLang(lang) {
    this.setState({
      lang: lang
    });
  }
  setLightMode(val) {
    this.setState({
      lightMode: val
    });
  }
  updateMeta = (data) => {
    this.setState({
      metaTags: data
    })
  }

  translate(text) {
    return text;
  }

  addToCart = (product, quantity, reset = false, callback, displayMiniCart, gtagDetail) => {
    

    fetch(API_ENDPOINT + '/cart/add', {
      method: 'POST',
      headers: {
'X-Brand': 'Vind',
        'content-type': 'application/json',
        'Authorization': localStorage.getItem('authToken') ? `Bearer ${localStorage.getItem('authToken')}` : localStorage.getItem('guestToken') ?  `Guest ${localStorage.getItem('guestToken')}` : null
      },
      body: JSON.stringify({
        product: product,
        quantity: parseInt(quantity),
        resetQuantity: reset
      })
    }).then(res => res.json()).then((result) => {


      if (window.gtag){
        if (quantity >= 0){
          window.gtag('event', 'add_to_cart', {
            "items": [
              {
                "id": gtagDetail.sku,
                "name": Object.translate(gtagDetail, 'sortName', this.props.lang),
                "brand": gtagDetail.brand,
                "category": gtagDetail.categoryChain && gtagDetail.categoryChain.length && gtagDetail.categoryChain[gtagDetail.categoryChain.length -1] ? Object.translate(gtagDetail.categoryChain[gtagDetail.categoryChain.length -1], 'name', this.props.lang) : '',
                quantity: quantity,
                "price": gtagDetail.price ? gtagDetail.price.value : null
          }
            ]
          });
        }else{
          window.gtag('event', 'remove_from_cart', {
            "items": [
              {
                "id": gtagDetail.sku,
                "name": Object.translate(gtagDetail, 'sortName', this.props.lang),
                "brand": gtagDetail.brand,
                "category": gtagDetail.categoryChain && gtagDetail.categoryChain.length && gtagDetail.categoryChain[gtagDetail.categoryChain.length -1] ? Object.translate(gtagDetail.categoryChain[gtagDetail.categoryChain.length -1], 'name', this.props.lang) : '',
                quantity: quantity,
                "price": gtagDetail.price ? gtagDetail.price.value : null
          }
            ]
          });

        }
        
      }

      this.fetchCartInfo(displayMiniCart ? displayMiniCart : false);

      if (callback) {
        callback();
      }
    }).catch(() => {

    })

  }

  addToWishlist = (product, remove=false) => {
    

    fetch(API_ENDPOINT + (remove ? '/wishlist/remove' : '/wishlist/add'), {
      method: 'POST',
      headers: {
'X-Brand': 'Vind',
        'content-type': 'application/json',
        'Authorization': localStorage.getItem('authToken') ? `Bearer ${localStorage.getItem('authToken')}` : localStorage.getItem('guestToken') ?  `Guest ${localStorage.getItem('guestToken')}` : null
      },
      body: JSON.stringify({
        product: product,
      })
    }).then(res => res.json()).then((result) => {
      if (!remove){
        this.setState({
          infoMessage: 'Product successfully added to my favorites'
        }, () => {
          setTimeout(() => {
            this.setState({
              infoMessage: null
            })
          }, 3000);
        })
      }
      this.fetchWishlistInfo();
      
    }).catch(() => {

    })

  }


  fetchWishlistInfo = (displayMiniCart = false) => {
    let authToken = null;

    if (typeof window !== 'undefined') {
      authToken = localStorage.getItem('authToken') ? `Bearer ${localStorage.getItem('authToken')}` : localStorage.getItem('guestToken') ?  `Guest ${localStorage.getItem('guestToken')}` : null;
    }

    fetch(API_ENDPOINT + '/wishlist', {
      method: 'GET',
      headers: {
'X-Brand': 'Vind',
        'Content-Type': 'application/json',
        'Authorization': authToken

      },
    }).then(parseJSON).then(({ result, status }) => {
      console.log(result)
      if (status >= 200 && status < 300 && result && result.items) {
        let dict = {};
        for(let i=0;i<result.items.length;i++){
          dict[result.items[i]._id] = true;
        }

        this.setState({
          wishlist: result.items,
          wishlistDict: dict
        })
      } else {
        this.setState({
          wishlist: [],
        })

      }

    }).catch(() => {
      this.setState({
        wishlist: [],
      })
  })

  }


  fetchCartInfo = (displayMiniCart = false) => {
    let authToken = null;

    if (typeof window !== 'undefined') {
      authToken = localStorage.getItem('authToken') ? `Bearer ${localStorage.getItem('authToken')}` : localStorage.getItem('guestToken') ?  `Guest ${localStorage.getItem('guestToken')}` : null;
    }

    fetch(API_ENDPOINT + '/cart', {
      method: 'GET',
      headers: {
'X-Brand': 'Vind',
        'Content-Type': 'application/json',
        'Authorization': authToken

      },
    }).then(parseJSON).then(({ result, status }) => {
      console.log(result)
      if (status >= 200 && status < 300 && result && result.total) {
        this.setState({
          cartInfo: {
            items: result.items,
            total: result.total,
            subtotal: result.subtotal,
            vat: result.vat,
            currency: result.currency,
            shippingCost: result.shippingCost,
            servicePointRequired: result.servicePointRequired,
            totalWithoutDiscount: result.totalWithoutDiscount,
            subtotalWithoutDiscount: result.subtotalWithoutDiscount,

}
        })
      } else {
        this.setState({
          cartInfo: null,
        })

      }

      if (displayMiniCart) {
        this.setState({
          displayMiniCart: displayMiniCart
        }, () => {
          setTimeout(() => {
            this.setState({
              displayMiniCart: false
            })
          }, 2500)
        })
      }

    }).catch(() => {
      this.setState({
        cartInfo: null,
      })
    })

  }

  render() {
    let meta;

    if (this.state.metaTags) {
      meta = {
        title: this.state.metaTags.title,
        description: this.state.metaTags.description ? this.state.metaTags.description : null,
        meta: {
          charset: 'utf-8',
          name: {
            'og:title': this.state.metaTags.title,
            'og:image': this.state.metaTags['og:image'] ? this.state.metaTags['og:image'] : null,
            'og:description': this.state.metaTags.description ? this.state.metaTags.description : null
          }
        }
      };
    }

    return (
      <Provider store={store}>
        {this.state.metaTags ? <DocumentMeta {...meta} /> : null}
        <Routes
          {...this.state}
          translate={this.translate}
          setLang={this.setLang}
          setLightMode={this.setLightMode}
          serverFetch={this.props.serverFetch}
          initialData={this.props.initialData ? this.props.initialData : {}}
          updateMeta={this.updateMeta}
          translateMonthBlog={this.translateMonthBlog}
          logOut={this.logOut}
          addToCart={this.addToCart}
          fetchCartInfo={this.fetchCartInfo}
          addToWishlist={this.addToWishlist}
          hideInfoMessage={() => this.setState({infoMessage: null})}
          fetchWishlistInfo={this.fetchWishlistInfo}
          handleMenuCategory={(val) => {this.setState({menuCategory: val})}}
          setCacheState={(val) => this.setState({cacheState: val})}
          allowCookies={() => {
            localStorage.allowCookies = true;
            this.setState({
              cookies: true
            }, () => {
              window.location.reload();
            });
          }}
          disallowCookies={() => {
            localStorage.removeItem('allowCookies');
            this.setState({
              cookies: true
            }, () => {
              window.location.reload();
            });
          }}

          verifyUser={this.verifyUser}
        />
      </Provider>

    );

  }

  logOut = () => {
    localStorage.removeItem('authToken');
    this.setState({
      uData: null
    }, () => {
      fetch(API_ENDPOINT + '/categories/sv/get', {
        method: 'POST',
        headers: {
'X-Brand': 'Vind',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        },
        body: JSON.stringify({
          breadcrumb: "/vind-collection"
        })
      }).then((res) => res.json()).then((result) => {
        this.setState({
          rootCategories: result && result.children ? result.children : []
        })
      })
    })
  }

  verifyUser = (callback) => {
    fetch(API_ENDPOINT + '/verify', {
      method: 'GET',
      headers: {
'X-Brand': 'Vind',
        'content-type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      }
    }).then(res => res.json()).then((result) => {
      this.fetchCartInfo();
      this.fetchWishlistInfo();

      if (!result.error) {
        this.setState({
          uData: result
        }, () => {
          if (callback) {
            callback();
          }

          fetch(API_ENDPOINT + '/categories/sv/get', {
            method: 'POST',
            headers: {
'X-Brand': 'Vind',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            },
            body: JSON.stringify({
              breadcrumb: "/vind-collection"
            })
          }).then((res) => res.json()).then((result) => {
            this.setState({
              rootCategories: result && result.children ? result.children : []
            })
          })

        })
      } else {
        this.setState({
          uData: null
        })
        localStorage.removeItem('authToken');
        
        fetch(API_ENDPOINT + '/categories/sv/get', {
          method: 'POST',
          headers: {
'X-Brand': 'Vind',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`
          },
          body: JSON.stringify({
            breadcrumb: "/vind-collection"
          })
        }).then((res) => res.json()).then((result) => {
          this.setState({
            rootCategories: result && result.children ? result.children : []
          })
        })
      }
    }).catch(() => {
      this.setState({
        uData: null
      })
      localStorage.removeItem('authToken');

    })
  }




  componentDidMount() {

    if (!localStorage.getItem('guestToken')){
      localStorage.setItem('guestToken', 'vind-' + uuidv4());
    }


    fetch(API_ENDPOINT + '/home/sections', {
      method: 'GET',
      headers: {
'X-Brand': 'Vind',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`

      },
  }).then(parseJSON).then(({ result, status }) => {
      if (status >= 200 && status < 300) {
          this.setState({
            homeData: result
          })
      }

  })

    fetch(API_ENDPOINT + '/langs', {
      method: 'GET',
      headers: {
'X-Brand': 'Vind',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      },
    }).then(res => {
      return res.json()
    }).then((result) => {
      window.langs = result;
      this.forceUpdate();
    });


    this.verifyUser();

    if (localStorage.allowCookies) {
      this.setState({
        cookies: true
      });
    }else {
      this.setState({cookies: false})
    }

    fetch(API_ENDPOINT + '/categories/sv/get', {
      method: 'POST',
      headers: {
'X-Brand': 'Vind',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      },
      body: JSON.stringify({
        breadcrumb: "/vind-collection"
      })
    }).then((res) => res.json()).then((result) => {
      this.setState({
        rootCategories: result && result.children ? result.children : []
      })
    })

    //ReactGA.initialize('UA-75168242-1');
    /*
        this.props.history.listen((location, action) => {
          let lang = 'ba';
          if (location.pathname.indexOf('/en') !== -1) {
            lang = 'en';
          } else if (location.pathname.indexOf('/sr') !== -1) {
            lang = 'sr';
          }
    
          if (this.state.lang != lang) {
            this.setState({ lang: lang }, () => {
              fetch('http://localhost:4000/seo/' + this.state.lang, {
                method: 'POST',
                headers: {
'X-Brand': 'Vind',
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({ url: location.pathname })
              }).then((res) => res.json()).then((result) => {
    
                this.setState({
                  metaTags: result
                })
              });
    
            });
          } else {
            fetch('http://localhost:4000/seo/' + this.state.lang, {
              method: 'POST',
              headers: {
'X-Brand': 'Vind',
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({ url: location.pathname })
            }).then((res) => res.json()).then((result) => {
    
              this.setState({
                metaTags: result
              })
            });
    
          }
    
    
    
    
    
        });
    
    
    
    */

  }



  googleMapsCallback() {
    this.setState({ _googleMapsLoaded: true });
  }

}

export default withRouter(App);
