import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Header from '../components/header';
import Nav from '../components/nav';
import Footer from '../components/footer';


export const DefaultLayout = (Wrapped) => (props) => {
    return (
        <div>


            <Header {...props} />
            <Nav {...props} />
            <Wrapped {...props} />
            <Footer {...props} />

            { props.infoMessage ?
                <div className="info-message">
                    <button onClick={() => props.hideInfoMessage()}>&times;</button>
                    {props.infoMessage.translate(props.lang)}
                </div>
                :
                null
            }

            {false && !props.cookies  ? <div className="cookies">
                <h3>{'Vi använder cookies'.translate(props.lang)}</h3>
                <p>{'Venture Design använder nödvändiga cookies för att webbplatsens funktioner ska fungera samt cookies för analys, anpassat innehåll och för att ge dig en bättre upplevelse på vår webbplats. Genom att klicka på ”Acceptera” samtycker du till vår användning av cookies. Läs mer i vår'.translate(props.lang)}                 <Link to='/cookies' >{'Cookie-policy.'.translate(props.lang)}</Link></p>
                <button onClick={() => { props.allowCookies() }}>{'Acceptera'.translate(props.lang)}</button>
                <button onClick={() => { props.disallowCookies() }}>{'Nedgång'.translate(props.lang)}</button>
            </div> :
                null
            }




        </div>
    );
};

export default DefaultLayout;
